import { useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'

import {
  BLANK_AGREEMENT_TO_ENTER,
  CHECKBOX_CODES_FOR_ALLOW_DOWNLOAD_APPLICATION,
  TRAJECTORY_INO_ID,
} from '@common/constants'
import useAllowDownloadApplication from '@hooks/useAllowDownloadApplication'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { Checkbox, type ViewerElementStatements } from '@models'
import { setCanSend } from '@redux/action-types'
import { selectBlankStatement, selectCanSend } from '@redux/actions/selectors'

type IFiledForm = {
  checkbox: Checkbox[]
}

const useDownloadFilesForm = (statement?: ViewerElementStatements | null) => {
  const {
    register,
    control,
    formState: { errors },
  } = useForm<IFiledForm>()

  const { fields, append, remove } = useFieldArray({
    name: 'checkbox',
    control,
  })
  const dispatch = useAppDispatch()

  const { isNotAllow } = useAllowDownloadApplication()

  const blankStatements = useAppSelector(selectBlankStatement)
  const flag = useAppSelector(selectCanSend)

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([])

  const formValues = useWatch({
    name: 'checkbox',
    control,
  })

  useEffect(() => {
    if (formValues && blankStatements) {
      const checkboxAccept = formValues.findIndex((el) =>
        statement?.trajectoryId === TRAJECTORY_INO_ID
          ? el.accept === false &&
            el.statementCheckboxId !== 6 &&
            isNotAllow(el.statementCheckbox.code.toString(), statement?.trajectoryId)
          : el.accept === false && el.statementCheckboxId !== 6
      )
      const blankAccept = blankStatements?.blank?.findIndex((blankEl) => {
        if (
          !(blankEl.blankId === BLANK_AGREEMENT_TO_ENTER && !blankStatements.accept)
        ) {
          return blankEl.acceptBlank === false
        }
      })

      setSelectedCheckboxes(
        formValues
          .filter((checkbox) => checkbox.accept)
          .map((checkbox) => checkbox.id)
      )
      dispatch(setCanSend(checkboxAccept === -1 && blankAccept === -1))
    }
  }, [formValues, blankStatements])
  return {
    fields,
    formValues,
    register,
    append,
    remove,
    errors,
    flag,
    selectedCheckboxes,
  }
}

export default useDownloadFilesForm
