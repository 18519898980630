import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppSelector from 'hooks/useAppSelector'
import { selectToken } from 'redux/actions/selectors'

type TemplatePageProps = {
  children?: React.ReactNode
  moderator?: React.ReactNode
}

const TemplateAuthPage = ({ children, moderator }: TemplatePageProps) => {
  const token = useAppSelector(selectToken)
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      navigate('/')
    }
    if (token && moderator) {
      navigate('/admin')
    }
  }, [token, navigate])

  return <div className="template-auth-page">{children}</div>
}

export default TemplateAuthPage
