import React, { useMemo } from 'react'

import useBlockSendRequest from '../../hooks/useBlockSendRequest'
import TrashIcon from '@assets/images/Trash.svg'
import {
  STATUS_APPROVED,
  STATUS_CONSIDERATION,
  STATUS_DRAFT,
  STATUS_EDIT_DIRECTION,
  STATUS_SEND_EDITING,
} from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { ViewerElementStatements } from '@models'
import { showModal } from '@redux/action-types'
import Button from '@ui/Button'

type Props = {
  index: number
  el: ViewerElementStatements
  goStatements: (id: number) => void
  goSendStatements: (id: number) => void
  fixStatements: (id: any) => void
  deleteStatements: (id: any) => void
  isHiddenWithdrawApplicationButton: (statement: any) => boolean
  isBlockSendBudgetApp: (statement: ViewerElementStatements | null) => boolean
  revoke: (id: any) => Promise<void>
  scenarioKeys?: any
}

const StatementsListItem = (props: Props) => {
  const {
    index,
    el,
    goStatements,
    goSendStatements,
    fixStatements,
    deleteStatements,
    isHiddenWithdrawApplicationButton,
    isBlockSendBudgetApp,
    revoke,
    scenarioKeys,
  } = props

  const dispatch = useAppDispatch()

  const { isBlockSendRequestBakRusPay, isBlockSendRequestMagRusPay } =
    useBlockSendRequest({
      scenarioKeys,
      statement: el,
    })

  const isDisabledForce = useMemo(() => {
    if (isBlockSendRequestBakRusPay) return true
    if (isBlockSendRequestMagRusPay) return true
    return false
  }, [isBlockSendRequestBakRusPay, isBlockSendRequestMagRusPay])

  return (
    <div
      key={index}
      className="statements-container__element"
      onClick={() => goStatements(el.id)}
    >
      <h2>
        {terms.STATEMENT} {el.numberStatement ? '№' + el.numberStatement : null}
      </h2>
      <div className={'statements-container__element-text'}>
        <p>
          {terms.LEVEL_OF_TRAINING}: <b>{el.scenario?.scenarioTranslate[0]?.name}</b>
        </p>
        {el.paymentType == null ? (
          <p>
            {terms.PAYMENT_TYPE_FORM}: <b>{terms.BUDGET_CONTRACT} </b>
          </p>
        ) : (
          <p>
            {terms.PAYMENT_TYPE_FORM}:{' '}
            <b>{el.paymentType == 1 ? terms.FREE_EDU : terms.PAYMENT_EDU} </b>
          </p>
        )}
        <p>
          {terms.DATE_AND_TIME}: <b>{el.dateSendToReview}</b>
        </p>
        <p>
          {terms.STATUS}:{' '}
          <b className={'success'}>{el.statusStatement?.translate[0]?.name}</b>
        </p>
      </div>
      {Number(el.statusStatement.code) === STATUS_DRAFT && (
        <div className={'statements-container__element-btn-panel'}>
          <Button
            type={'button'}
            theme={'success'}
            disabled={isDisabledForce}
            onClick={(event) => {
              event.stopPropagation()
              goSendStatements(el.id)
            }}
          >
            Отправить заявление
          </Button>
          <Button
            type={'button'}
            className="statements-container__element-btn-panel_icon"
            onClick={(event) => {
              event.stopPropagation()
              dispatch(
                showModal({
                  name: 'IS_DELETE_APPLICATION_ADMISSION',
                  data: {
                    statementId: el.id,
                    content: terms.STATEMENT + ' №' + el.numberStatement,
                  },
                })
              )
            }}
            disabled={false}
          >
            <TrashIcon />
          </Button>
        </div>
      )}
      {Number(el.statusStatement.code) === STATUS_CONSIDERATION && (
        <div className={'statements-container__element-btn-panel'}>
          <Button
            type={'button'}
            theme={'success'}
            onClick={(event) => {
              event.stopPropagation()
              fixStatements(el.id)
            }}
          >
            Забрать на редактирование
          </Button>
        </div>
      )}
      {Number(el.statusStatement.code) === STATUS_SEND_EDITING && (
        <div className={'statements-container__element-btn-panel'}>
          <Button
            type={'button'}
            theme={'success'}
            disabled={isDisabledForce}
            onClick={(event) => {
              event.stopPropagation()
              goSendStatements(el.id)
            }}
          >
            Отправить заявление
          </Button>
          <Button
            type={'button'}
            className="statements-container__element-btn-panel_icon"
            disabled={isDisabledForce}
            onClick={(event) => {
              event.stopPropagation()
              deleteStatements(el.id)
            }}
          >
            <TrashIcon />
          </Button>
        </div>
      )}

      {Number(el.statusStatement.code) === STATUS_APPROVED &&
        isHiddenWithdrawApplicationButton(el) && (
          <div className={'statements-container__element-btn-panel'}>
            <Button
              type={'button'}
              className="statements-container__element-btn-panel_icon"
              disabled={
                isDisabledForce
                  ? true
                  : Number(el.trajectoryId) === 2 || isBlockSendBudgetApp(el)
              }
              onClick={(event) => {
                event.stopPropagation()
                dispatch(
                  showModal({
                    name: 'WITHDRAW_MODAL',
                    data: {
                      revoke: () => revoke(el.id),
                    },
                  })
                )
              }}
            >
              Отозвать заявление
            </Button>
          </div>
        )}
      {Number(el.statusStatement.code) === STATUS_EDIT_DIRECTION && (
        <div className={'statements-container__element-btn-panel'}>
          <Button
            type={'button'}
            theme={'success'}
            disabled={isDisabledForce}
            onClick={(event) => {
              event.stopPropagation()
              goSendStatements(el.id)
            }}
          >
            Отправить заявление
          </Button>
        </div>
      )}
    </div>
  )
}

export default StatementsListItem
