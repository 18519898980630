import React, { useEffect, useState } from 'react'

import cn from 'classnames'

import TableIncomingStudents from '@components/TableIncomingStudents'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDebouncedFunction from '@hooks/useDebouncedFunction'
import { getIncomingList, getListGroups } from '@redux/action-types'
import { selectIncomingList, selectListGroups } from '@redux/actions/selectors'
import H1 from '@ui/H1'
import Input from '@ui/Input'
import Link from '@ui/Link'

const IncomingStudents = () => {
  const dispatch = useAppDispatch()
  const [currentGroup, setCurrentGroup] = useState<number[]>([])
  const [inputValue, setInputValue] = useState<string>()
  const debouncedDispatch = useDebouncedFunction(
    () => dispatch(getIncomingList({ groups: currentGroup, filters: inputValue })),
    500
  )

  const listGroups = useAppSelector(selectListGroups)
  const incomingList = useAppSelector(selectIncomingList)

  useEffect(() => {
    !listGroups?.length && dispatch(getListGroups())
  }, [])

  useEffect(() => {
    dispatch(getIncomingList({ groups: currentGroup, filters: inputValue }))
  }, [currentGroup])

  useEffect(() => {
    if (inputValue || inputValue === '') {
      debouncedDispatch()
    }
  }, [inputValue])

  return (
    <div className={'viewer-layout__content'}>
      <div className="students-page">
        <H1>Списки поступающих</H1>
        <div className="students-page__group">
          {listGroups?.map((group, index) => (
            <div
              onClick={() => {
                currentGroup?.includes(group.id)
                  ? setCurrentGroup(currentGroup.filter((el) => el !== group.id))
                  : setCurrentGroup((prev) => [...prev, group.id])
              }}
              className={cn('statements-container__element', {
                active: currentGroup?.includes(group.id),
              })}
              key={index}
            >
              <h2>{group.name}</h2>
            </div>
          ))}
        </div>
        <Input
          placeholder="Поиск"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <div className="student-page__list">
          {incomingList?.map((item, index) => (
            <div className="list__item" key={index}>
              <Link href={`enrollee_lists/${item.url}`} target="_blank">
                {item.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default IncomingStudents
