import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getAdminVisa } from '@redux/action-types/admin'
import {
  selectAdminVisa,
  selectModeratorStatementsPersonalData,
  selectPassportData,
} from '@redux/actions/selectors'
import terms from '@common/terms'
import RadioButton from '@ui/RadioButton'
import { getModeratorStatementsPersonalData } from '@redux/action-types'

const VisaTab = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id?: string; userId?: string }>()
  const statement = useAppSelector(selectModeratorStatementsPersonalData)
  const visa = useAppSelector(selectAdminVisa)
  const qa = visa?.qa ? JSON.parse(visa.qa) : {}

  useEffect(() => {
    if (!visa && id) {
      dispatch(getAdminVisa(id))
      dispatch(getModeratorStatementsPersonalData(id))
    }
  }, [visa, id])

  const listVisa: DataList = [
    {
      title: 'Страна получения визы',
      label: visa?.countryVisa?.countryTranslate[0].name ?? '-',
    },
    {
      title: 'Город получения визы',
      label: visa?.cityVisa?.cityTranslate[0].name ?? '-',
    },
    {
      title: 'Предполагаемый срок въезда в Россию',
      label: visa?.estimatedDate ?? '-',
    },
  ]

  const listQA: DataList = [
    {
      title: 'Болели COVID-19?',
      label: qa?.illCovid ? qa.illCovid.label : '-',
    },
    {
      title: 'Делали прививку от COVID-19?',
      label: qa?.vaccinatedCovid ? qa.vaccinatedCovid.label : '-',
    },
    {
      title: 'Собираетесь делать прививку от COVID-19 в России ?',
      label: qa?.goingVaccinatedCovid ? qa.goingVaccinatedCovid.label : '-',
    },
  ]

  return (
    <div>
      <RadioButton>
        <div className="radio-button--title">
          <b>{terms.VISA}</b>
        </div>
        <RadioButton.Radio
          text={terms.I_NEED_VISA}
          value="1"
          checked={statement?.users?.needVisa}
        />
        <RadioButton.Radio
          text={terms.I_NOT_NEED_VISA}
          value="0"
          checked={!statement?.users?.needVisa}
        />
      </RadioButton>
      <h2 className={'visa-title'}>Место получения визы</h2>

      <ModeratorDataList list={listVisa} />

      <h2 className={'visa-title'}>Ответьте на вопросы</h2>

      <ModeratorDataList list={listQA} />

      <h2 className={'visa-title'}>Анкета для приглашения</h2>

      <ModeratorDocumentsPage
        params={[
          {
            typeDoc: 'visa_application_form_invitation',
            title: '',
          },
        ]}
      />
    </div>
  )
}

export default VisaTab
