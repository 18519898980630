import {
  TRAJECTORY_INO_ID,
  CHECKBOX_CODES_FOR_ALLOW_DOWNLOAD_APPLICATION,
} from './../common/constants'

const useAllowDownloadApplication = () => {
  const checkTrajectory = (trajectoryId?: number) => {
    if (trajectoryId === TRAJECTORY_INO_ID) {
      return true
    }
    return false
  }

  const isAllow = (code: string, trajectoryId?: number) => {
    if (!checkTrajectory(trajectoryId)) {
      return false
    }

    if (CHECKBOX_CODES_FOR_ALLOW_DOWNLOAD_APPLICATION.includes(code)) {
      return true
    }

    return false
  }

  const isNotAllow = (code: string, trajectoryId?: number) => {
    if (!checkTrajectory(trajectoryId)) {
      return false
    }

    if (!CHECKBOX_CODES_FOR_ALLOW_DOWNLOAD_APPLICATION.includes(code)) {
      return true
    }

    return false
  }

  return { isAllow, isNotAllow }
}

export default useAllowDownloadApplication
