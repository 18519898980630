import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Table from './OlympiadTable'
import useAppSelector from '@hooks/useAppSelector'
import useOlympiads from '@hooks/useOlympiads'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import { showModal } from '@redux/action-types'
import { selectLevelValue, selectTrajectoryValue } from '@redux/actions/selectors'
import Button from '@ui/Button'

const OlympiadsForm = (scenarioKeys) => {
  const dispatch = useDispatch()

  const { statementId } = useParams()
  const { readOnly } = useReadOnlyFillingStatements()
  const scenarioId = useAppSelector(selectLevelValue)

  const { olympiads, onDelete, onDownloadFile, onEdit } = useOlympiads(
    String(statementId)
  )

  const onOpenOlympiadssModal = () => {
    dispatch(
      showModal({ name: 'ADD_OLYMPIAD_MODAL', data: { statementId, scenarioId } })
    )
  }

  return (
    <>
      <h4 className="achivements__title">Олимпиады</h4>
      {(scenarioId.id === scenarioKeys.level_training_bachelor_ino.tdId ||
        scenarioId.id === scenarioKeys.level_training_bachelor.tdId) && (
        <div className={'achievements__subtitle'}>
          <p>
            Правом на прием без вступительных испытаний можно воспользоваться только
            в одной организации высшего образования только на одной образовательной
            программе
          </p>
          <a
            href={
              'https://www.herzen.spb.ru/abiturients/informatsiya-o-prieme/postuplenie-dlya-grazhdan-rf/bakalavriat/pobeditelyam-i-prizeram-olimpiad/'
            }
            target={'_blank'}
          >
            «Победителям и призерам олимпиад»
          </a>
        </div>
      )}

      {(scenarioId.id === scenarioKeys.magistra_rf.tdId ||
        scenarioId.id === scenarioKeys.magistra_ino.tdId) && (
        <div className={'achievements__subtitle'}>
          <p>
            Олимпиады дающие право зачета максимального количества баллов по
            вступительному испытанию:
          </p>
          <a
            href={'/documents/Приложение 5 к Правилам приема в 2024 году.pdf'}
            target={'_blank'}
          >
            Приложение 5 к Правилам приема в 2024 году
          </a>
        </div>
      )}

      <Button
        type={'button'}
        className="achivements__button"
        onClick={onOpenOlympiadssModal}
        disabled={readOnly}
      >
        Добавить
      </Button>
      {olympiads.length > 0 ? (
        <Table
          items={olympiads}
          onDelete={(userOlympiadId) =>
            onDelete({ statementId: Number(statementId), userOlympiadId })
          }
          onEdit={onEdit}
          onDownload={onDownloadFile}
          readOnly={readOnly}
        />
      ) : null}
    </>
  )
}

export default OlympiadsForm
