import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import ModeratorFile from '@components/ModeratorFile'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useUploadDocuments from '@hooks/useUploadDocuments'
import {
  getConclusionBaseDepartment,
  getModeratorStatementsPersonalData,
} from '@redux/action-types'
import {
  selectConclusionBaseDepartment,
  selectModeratorStatementsPersonalData,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import { base64toFile, downloadFile } from '@utils/common'
import { fileToBase64 } from '@utils/fileToBase64'

const ModeratorConclusionBaseDepartmentPage = () => {
  const { id } = useParams()

  const [base64File, setBase64File] = useState<null | string>(null)
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useAppDispatch()

  const conclusionBaseDepartment = useAppSelector(selectConclusionBaseDepartment)
  const moderatorStatementsPersonalData = useAppSelector(
    selectModeratorStatementsPersonalData
  )

  const { docs, getUploadedDoc } = useUploadDocuments(
    'CONCLUSION_FROM_BASE_DEPARTMENT',
    terms.CONCLUSION_BASE_DEPARTMENT
  )

  const transformFile = async () => {
    setIsLoading(true)
    const file = await fileToBase64(docs[0])
    setBase64File(file)
    setIsLoading(false)
  }

  useEffect(() => {
    if (docs.length > 0) {
      transformFile()
    } else {
      setIsLoading(false)
    }
  }, [docs])

  useEffect(() => {
    if (
      conclusionBaseDepartment &&
      conclusionBaseDepartment.status &&
      conclusionBaseDepartment.status.id &&
      moderatorStatementsPersonalData &&
      moderatorStatementsPersonalData.userId
    ) {
      getUploadedDoc(
        conclusionBaseDepartment.status.id,
        moderatorStatementsPersonalData.userId
      )
    }
  }, [conclusionBaseDepartment, moderatorStatementsPersonalData])

  useEffect(() => {
    if (id) {
      dispatch(getConclusionBaseDepartment(Number(id)))
      dispatch(getModeratorStatementsPersonalData(id))
    }
  }, [])

  return (
    <ProfileSection title="Заключение базовой кафедры">
      {isLoading && <Loader />}
      {!isLoading && docs.length > 0 && base64File && (
        <ModeratorFile
          title={docs[0].name}
          content={new Date(docs[0].lastModified).toString()}
          upload={() => {
            base64toFile(
              base64File || '',
              'application/pdf',
              'pdf',
              docs[0].name
            ).then(downloadFile)
          }}
        />
      )}
    </ProfileSection>
  )
}

export default ModeratorConclusionBaseDepartmentPage
