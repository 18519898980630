import React, { useEffect, useState } from 'react'
import { Controller, FieldError } from 'react-hook-form'

import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import useAddOlympiadForm from '@hooks/useAddOlympiadForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useOlympiadFormInitialization from '@hooks/useOlympiadFormInitialization'
import { saveData } from '@hooks/useViewData'
import { useCode } from '@hooks/useСode'
import TemplateModal from '@modals/TemplateModal'
import { DisciplineEgeOptions, Olympiad, PageDataView, SelectOption } from '@models'
import { getPersonalData } from '@redux/action-types'
import { modalDataSelector, selectPersonalFormData } from '@redux/actions/selectors'
import api from '@services/api'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import RadioButton from '@ui/RadioButton'
import ReactSelect from '@ui/Select'

const AddOlympiadModal = (scenarioKeys) => {
  // const yearOptionsDefault = [
  //   { label: '2018', value: 1 },
  //   { label: '2019', value: 2 },
  //   { label: '2020', value: 3 },
  // ]
  // const yearOptionsBachelor = [
  //   { label: '2018', value: 1 },
  //   { label: '2019', value: 2 },
  //   { label: '2020', value: 3 },
  //   { label: '2021', value: 4 },
  //   { label: '2022', value: 5 },
  // ]
  // const yearOptionsMaster = [
  //   { label: '2021', value: 1 },
  //   { label: '2022', value: 2 },
  // ]

  const { statementId, olympiad, scenarioId } = useAppSelector(
    modalDataSelector
  ) as {
    statementId: number
    olympiad?: Olympiad
    scenarioId: { id: number; value: string }
  }

  const {
    control,
    fields,
    watch,
    onSubmit,
    setValue,
    loading,
    clearErrors,
    disciplineEgeOptions,
    formState: { errors },
  } = useAddOlympiadForm(scenarioKeys)

  const year = watch('year') as unknown as SelectOption | undefined

  const { olympiadDoc, olympiadFile, olympiads } = useOlympiadFormInitialization({
    setValue,
    olympiad,
    statementId,
    year,
  })

  const isSubject = watch('olympiadResults') === 'subject'

  const [yearOption, setYearOption] = useState<any | null | undefined>(null)

  useEffect(() => {
    api.client.getOlympiadYearOptions(scenarioId['id']).then((response) => {
      const { data } = response as unknown as any
      if (data.data) {
        setYearOption(data)
      }
    })
  }, [setYearOption])

  let years = []
  if (yearOption?.data[0]) {
    years = yearOption.data
  }

  useEffect(() => {
    if (olympiadFile.docs.length) {
      clearErrors('documents')
    }
    setValue('documents', olympiadFile.docs)
  }, [olympiadFile.docs])

  return (
    <TemplateModal
      title={olympiad ? 'Редактировать олимпиаду' : 'Добавить олимпиаду'}
      clearTypeDoc="ACHIVMENT"
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="year"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={years}
                placeholder={'Год проведения'}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.year?.message}
              />
            )}
          />

          <Controller
            name="olympiadId"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={year && olympiads}
                placeholder={'Название олимпиады'}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.olympiadId?.message}
              />
            )}
          />
          <Controller
            name="olympiadDocId"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={year && olympiadDoc}
                placeholder={'Подтверждающий документ'}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.olympiadDocId?.message}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder={'Серия (если есть) и номер документа'}
            markRequired={true}
            {...fields.number}
            error={errors.number?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={'Дата выдачи'}
            markRequired={true}
            type="date"
            {...fields.dateIssue}
            error={errors.dateIssue?.message}
          />
          <Input
            className={`profile-form__field`}
            {...fields.comment}
            placeholder={'Дополнительная информация'}
            hint={''}
            error={errors.comment?.message}
          />
        </div>

        {(scenarioId.id === scenarioKeys.level_training_bachelor_ino.tdId ||
          scenarioId.id === scenarioKeys.level_training_bachelor.tdId) && (
          <div className={'profile-form__field--mb20 profile-form__checkbox--mt-24'}>
            <p className={'profile-section__hint profile-form__field--mb-12'}>
              Укажите, как Вы хотите засчитать результаты олимпиады: прием без
              вступительных испытаний или 100 баллов с указанием профильного предмета
            </p>
            <RadioButton
              className={`profile-form__entrance-test ${
                isSubject ? 'profile-form__entrance-test--full-width' : ''
              }`}
            >
              <div className="">
                <RadioButton.Radio
                  {...fields.olympiadResults}
                  value="withoutEntranceTest"
                  text={terms.WITHOUT_ENTRANCE_TEST}
                />
                <RadioButton.Radio
                  {...fields.olympiadResults}
                  value="subject"
                  text={terms.PROFILE_SUBJECT}
                />
              </div>
              {isSubject && (
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect<DisciplineEgeOptions>
                      options={disciplineEgeOptions}
                      placeholder={terms.SUBJECT}
                      className="result-exam__element_field profile-form__select"
                      markRequired={true}
                      styles={{ menu: (base) => ({ ...base, minWidth: '200px' }) }}
                      {...(field as unknown)}
                      onChange={(e) => {
                        if (e) {
                          setValue('subject', e.score)
                          field.onChange(e)
                        }
                      }}
                    />
                  )}
                />
              )}
            </RadioButton>
            {errors.olympiadResults?.message && (
              <div className="profile-form__error">
                {errors.olympiadResults?.message}
              </div>
            )}
          </div>
        )}

        <ProfileSection title={'Документ'} markRequired={true}>
          {(scenarioId?.id === scenarioKeys.level_training_bachelor_ino.tdId ||
            scenarioId?.id === scenarioKeys.level_training_bachelor.tdId) && (
            <p className={'profile-form__subtitle'}>
              Электронная версия диплома с сайта&nbsp;
              <a target={'_blank'} href={'https://rsr-olymp.ru/diploma'}>
                https://rsr-olymp.ru/diploma
              </a>
              &nbsp;или скан-копия диплома
            </p>
          )}
          <FileUploader
            className="profile-form__file-uploader"
            {...olympiadFile}
            files={olympiadFile.docs}
          />
          {errors.documents && (
            <div className="profile-form__error">
              {(errors.documents as unknown as FieldError | undefined)?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          Сохранить
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddOlympiadModal
