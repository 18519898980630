import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useJuridicalContractFormInitialization from '@hooks/useJuridicalContractFormInitialization'
import {
  getBankIdentifierCode,
  getCheckingAccount,
  getCorrespondentAccount,
} from '@lib/validators'
import { ContractJuridical } from '@models'
import { createToast, getContract, getContractsList } from '@redux/action-types'
import api, { ResponseOk } from '@services/api'
import { AddContractJuridicalResponse } from '@services/api/statements-api'

const useJuridicalContractForm = (
  contract,
  trajectoryId?: number | string,
  scenarioId?: number | string
) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<ContractJuridical>({ mode: 'all' })

  const dispatch = useAppDispatch()
  const forIno =
    trajectoryId === 1 ||
    (trajectoryId === 2 &&
      scenarioId !== 5 &&
      scenarioId !== 6 &&
      scenarioId !== 1 &&
      scenarioId !== 3)

  const fields = {
    statementId: register('statementId'),
    payerTypeId: register('payerTypeId'),
    competitiveGroupStatementId: register('competitiveGroupStatementId'),
    directionTrainingId: register('directionTrainingId', {
      required: terms.REQUIRED_FIELD,
      onChange: (e) => setValue('competitiveGroupStatementId', e.target.value.id),
    }),
    contractId: register('contractId'),
    organization: register('organization'),
    managementBases: register('managementBases'),
    nameBank: register('nameBank'),
    paymentAccount: register('paymentAccount', {
      ...getCheckingAccount(),
    }),
    bankIdentifierCode: register('bankIdentifierCode', {
      ...getBankIdentifierCode(),
    }),
    correspondentAccount: register('correspondentAccount', {
      ...getCorrespondentAccount(),
    }),
    checkingAccount: register('checkingAccount'),
    cityBank: register('cityBank'),
    phone: register('phone'),
    email: register('email'),
    organizationAddress: register('organizationAddress'),
    eduCredit: register('eduCredit'),

    fullNameLeader: register('fullNameLeader'),
    iin: register('iin'),
    kpp: register('kpp'),
    legalAddress: register('legalAddress'),
  }

  useJuridicalContractFormInitialization(setValue, contract)

  const isEditing = Boolean(contract)
  const { statementId } = useParams()
  const navigate = useNavigate()

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData = {
      ...data,
      statementId: data.statementId,
      directionTrainingId: data.directionTrainingId.value,
      bicBank: data.bankIdentifierCode,
    }

    if (isEditing) {
      api.client
        .editContractJuridical(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch((response: Exclude<AddContractJuridicalResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ContractJuridical, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          dispatch(getContract(Number(contract.id)))

          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    } else {
      api.client
        .addContractJuridical(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch((response: Exclude<AddContractJuridicalResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ContractJuridical, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    }
  })

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    forIno,
    ...rest,
  }
}

export default useJuridicalContractForm
