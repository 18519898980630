import React, { useMemo } from 'react'

import classnames from 'classnames'

/** ID "Поступаю как иностранный гражданин" */
const admissionOfForeignCitizensID = 2

const TrajectoryElement = ({ id, scenarioId, name, content, active, setActive }) => {
  let normArr: string[] = content?.split('\n') || []

  const changeTrajectory = () => {
    setActive({
      id: id,
      value: name,
      scenarioId: scenarioId,
    })
  }

  /** Ссылка на "Информация о поступлении" */
  const admissionInfoLink = useMemo(() => {
    return id === admissionOfForeignCitizensID
      ? 'https://www.herzen.spb.ru/abiturients/priem-inostrannykh-grazhdan/'
      : 'https://www.herzen.spb.ru/'
  }, [id])

  return (
    <div
      className={classnames('trajectory-list__trajectory-element', { active })}
      onClick={changeTrajectory}
    >
      <h2>{name}</h2>
      <ul>
        {normArr?.map(
          (el, index) =>
            el.length !== 0 && (
              <li
                key={index}
                className={
                  el[0] === '1' || el[0] === '2' || el[0] === '3' || el[0] === '4'
                    ? 'number'
                    : 'first'
                }
              >
                {el.slice(-6) === 'здесь.' ? (
                  <p>
                    {el.slice(0, -6)}{' '}
                    <a href={admissionInfoLink} className={'link'} target="_blank">
                      {el.slice(-6)}
                    </a>
                  </p>
                ) : (
                  <p>{el}</p>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  )
}

export default TrajectoryElement
