import React, { useEffect, useMemo, useState } from 'react'

import cn from 'classnames'

import terms from '@common/terms'
import useModeratorDirectionsElement from '@hooks/useModeratorDirectionsElement'
import { ModeratorDirectionTraining } from '@models'
import Button from '@ui/Button'
import { formatDateTimeToRuFormat } from '@utils/date'

const headers = [terms.NAME_CONSENT, terms.STATUS, terms.REVOCATION_DATE, '']

type ModeratorDirectionsElementProps = ModeratorDirectionTraining & {
  newDate: string
  isApprovedStatement: boolean
}

const ModeratorDirectionsElement = ({
  benefits,
  specialQuota,
  formPayment,
  competitiveGroupId,
  competitiveGroupStatementId,
  education,
  directionTraining,
  faculty,
  educationProfile,
  trainingLevel,
  disciplines,
  enrollmentGeneratedAt,
  enrollmentAccepted,
  enrollmentUpdatedAt,
  date,
  newDate,
  deleted,
  accessConclusion,
  isApprovedStatement,
}: ModeratorDirectionsElementProps) => {
  const {
    handleClick,
    approveContact,
    formState: { errors },
  } = useModeratorDirectionsElement(
    competitiveGroupId,
    competitiveGroupStatementId,
    trainingLevel,
    accessConclusion
  )
  const [open, setOpen] = useState(false)
  const [isApproveContract, setIsApproveContract] = useState(accessConclusion)

  useEffect(() => {
    setIsApproveContract(accessConclusion)
  }, [accessConclusion])

  /**
   * Формирует список дисциплин.
   *
   * Если `disciplines` не задано, возвращает пустой массив.
   * Если `disciplines` является массивом, возвращает его.
   * Если `disciplines` является объектом, возвращает массив значений объекта.
   */
  const disciplinesList = useMemo<string[]>(() => {
    if (!disciplines) {
      return []
    }

    if (Array.isArray(disciplines)) {
      return disciplines
    }

    return Object.values(disciplines)
  }, [disciplines])

  return (
    <div className={'moderator-directions-element'}>
      <div
        className={'moderator-directions-element__header'}
        onClick={() => setOpen(!open)}
      >
        <h2>{directionTraining}</h2>
        <div
          className={cn('moderator-directions-element__header-status', {
            'moderator-directions-element__header-status-deleted': deleted,
          })}
        >
          {deleted ? terms.REMOVED : newDate === date ? terms.RECENTLY_ADDED : null}
        </div>
      </div>
      {open && (
        <div className={'moderator-directions-element__body'}>
          <div className={'moderator-directions-element__info'}>
            <div className={'moderator-data-list'}>
              <div className="moderator-data-list__element">
                <p>{terms.DISCIPLINES}:</p>
                <ul>
                  {disciplinesList.map((item, index) => (
                    <li key={index}>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {(benefits || specialQuota) && (
                <div className="moderator-data-list__element">
                  <p>{terms.LGOT}</p>
                  {specialQuota && (
                    <span className="moderator-special-quota">
                      {terms.SEPARATE_QUOTA}
                    </span>
                  )}
                  {benefits && <span>{terms.SPECIAL_QUOTA}</span>}
                </div>
              )}
              <div className="moderator-data-list__element">
                <p>{terms.SOURCE_OF_FINANCING}:</p>
                <span>{formPayment || '-'}</span>
              </div>
              <div className="moderator-data-list__element">
                <p>{terms.FORM_OF_STUDY}:</p>
                <span>{education || '-'}</span>
              </div>
              <div className="moderator-data-list__element">
                <p>{terms.THE_LEVEL_OF_EDUCTION}:</p>
                <span>{trainingLevel || '-'}</span>
              </div>
              <div className="moderator-data-list__element">
                <p>Образовательная программа:</p>
                <span>{educationProfile || '-'}</span>
              </div>
              <div className="moderator-data-list__element">
                <p>{terms.SUBDIVISION}:</p>
                <span>{faculty}</span>
              </div>
            </div>
            {isApprovedStatement && (
              <Button
                theme={isApproveContract ? 'success' : undefined}
                onClick={() => approveContact(competitiveGroupStatementId)}
              >
                {isApproveContract ? 'Запретить договор' : 'Разрешить договор'}
              </Button>
            )}
          </div>
          {enrollmentGeneratedAt && (
            <table className="moderator-directions-element__body-table">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`${terms.CONSENT_TO_ENROLLMENT} ${
                    enrollmentGeneratedAt
                      ? formatDateTimeToRuFormat(enrollmentGeneratedAt)
                      : ''
                  }`}</td>
                  <td>{enrollmentAccepted ? 'Подано' : 'Отозвано'}</td>
                  <td>{!enrollmentAccepted ? enrollmentUpdatedAt : '-'}</td>
                  <td>
                    <Button onClick={handleClick}>{terms.DELETE}</Button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  )
}

export default ModeratorDirectionsElement
