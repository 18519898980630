import axios, { AxiosResponse } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import { Modify } from 'types/types'

import HttpStatusCode from '@common/httpStatusCodes'
import uploadDocsTypes, { UploadDocKey } from '@common/uploadDocsTypes'
import { UserDocument, UserOtherDocument } from '@models'

/** @name PROFILE */

export default {
  /** @method GET */
  getDocs: (typeDoc: UploadDocKey, docId?: number, userId?: any) =>
    axios.get<DownloadDocdResponse>(
      `/docs?typeDoc=${uploadDocsTypes[typeDoc]}${docId ? `&doc_id=${docId}` : ''}${
        userId ? `&user_id=${userId}` : ''
      }`
    ),

  /** @method GET */
  getModeratorDocs: (
    typeDoc: UploadDocKey,
    docId?: number,
    statementId?: number,
    user_id?: string | number
  ) =>
    axios.get<DownloadDocdResponse>(
      `/admin/docs?typeDoc=${uploadDocsTypes[typeDoc] || typeDoc}${
        docId ? `&doc_id=${docId}` : ''
      }${statementId ? `&statement_id=${statementId}` : ''}${
        user_id ? `&user_id=${user_id}` : ''
      }`
    ),

  getStatementsCSV: (data: any) => {
    let params = new URLSearchParams()

    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          if (Array.isArray(snakeData[key])) {
            snakeData[key].forEach((id) => {
              params.append(key + '[]', id)
            })
          } else {
            params.append(key, snakeData[key])
          }
        }
      })
    }

    return axios.get<any>('/moderator/statements/export', {
      params: params,
    })
  },

  /** @method GET */
  getAdditionalDocs: () => axios.get<UserDocument[]>('/dopFiles'),

  /** @method GET */
  getOtherDocs: (statementId: any) =>
    axios.get<UserOtherDocument[]>(
      `/statements/additional-files?statement_id=${statementId}`
    ),

  /** @method POST */
  addContractUser: (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (const doc of data[key]) {
          formData.append(`${key}[]`, doc)
        }
      } else {
        formData.append(key, data[key])
      }
    })
    return axios.post('/contract/upload', formData)
  },

  /** @method GET */
  getOtherDocsModerator: (statementId: any, userId: any) =>
    axios.get<UserOtherDocument[]>(
      `/statements/additional-files?statement_id=${statementId}&user_id=${userId}`
    ),

  /** @method GET */
  getModeratorDocToDownload: (id: string | number) =>
    axios.get<any>(`/admin/docs?typeDoc=dop_files&doc_id=${id}`),

  /** @method DELETE */
  deleteModeratorDoc: (id: string | number) =>
    axios.delete<any>(`/moderator/statements/forms/docs?doc_id=${id}`),

  /** @method GET */
  downloadTemplateDoc: (typeDoc: UploadDocKey) =>
    axios.get<{ document: string }>(
      `/downloadTemplateDoc?typeDoc=${uploadDocsTypes[typeDoc]}`
    ),

  /** @method GET */
  downloadBlank: (blankId: number, statement: number, userId?) => {
    return axios.get<{ document: string }>(
      `/downloadBlank?blank_id=${blankId}&statement=${statement}${
        userId ? `&user_id=${userId}` : ''
      }
      `
    )
  },

  /** @method GET */
  downloadBlankPriority: (blankId: number, statement: number, userId?) => {
    return axios.get<{ document: string }>(
      `/downloadBlankPriority?blank_id=${blankId}&statement=${statement}${
        userId ? `&user_id=${userId}` : ''
      }
      `
    )
  },

  /** @method DELETE */
  deleteDoc: async ({
    typeDoc,
    docId,
  }: {
    typeDoc: UploadDocKey
    docId?: number
  }) => {
    return axios.delete(`/docs?typeDoc=${uploadDocsTypes[typeDoc]}&doc_id=${docId}`)
  },

  deleteConclusionBaseDepartmentDoc: async ({
    statementId,
    docId,
  }: {
    statementId: number
    docId: number
  }) => {
    return axios.delete(
      `/statements/upload-doc?statement_id=${statementId}&doc_id=${docId}`
    )
  },

  /** @method POST */
  uploadDocs: async ({
    documents,
    typeDoc,
    name,
    docId,
    id,
    date,
  }: {
    documents: File[]
    typeDoc: UploadDocKey
    name?: string
    docId?: number
    id?: number | string
    date?: string
  }) => {
    const formData = new FormData()

    formData.append('typeDoc', uploadDocsTypes[typeDoc])
    if (name) {
      formData.append('name', name)
    }
    if (docId) {
      formData.append('doc_id', String(docId))
    }
    if (date) {
      formData.append('create_old_file', date)
    }
    if (id) {
      formData.append('user_id', String(id))
    }
    for (const doc of documents) {
      formData.append('documents[]', doc)
    }

    if (typeDoc === 'ADDITIONAL_FILES' && id)
      return axios.post<
        { documents: File[]; typeDoc: UploadDocKey },
        UploadDocsResponse
      >('/moderator/statements/forms', formData)

    return axios.post<
      { documents: File[]; typeDoc: UploadDocKey },
      UploadDocsResponse
    >('/docs', formData)
  },
}

// upload docs
export type UploadDocsRespOk = Modify<
  AxiosResponse<{
    success: true
    message: string
    id: number
  }>,
  { status: HttpStatusCode.OK }
>

export type UploadDocsRespUnprocessableEntity = Modify<
  AxiosResponse<{
    message: string
    errors: Record<'documents' | 'typeDoc', string[]>
  }>,
  { status: HttpStatusCode.UNPROCESSABLE_ENTITY }
>

export type DownloadDocdResponse = { document: string }

export type UploadDocsResponse = UploadDocsRespOk | UploadDocsRespUnprocessableEntity
