import React, { useMemo } from 'react'

import EditIcon from '@assets/images/Edit.svg'
import FileIcon from '@assets/images/File.svg'
import terms from '@common/terms'
import useAdditionalFiles from '@hooks/useAdditionalFIles'
import useAppSelector from '@hooks/useAppSelector'
import {
  // selectIsQuestionnaireBlockProfile,
  selectLocalizedViewerProfile,
  selectStatementsList,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import { trimFileExtension } from '@utils/common'
import { formatDateTimeToRuFormat } from '@utils/date'

/** Код статуса заявления "В работе" */
const STATUS_REQUEST_IN_WORK_CODE = '4'

const AdditionalFilesPage = () => {
  const { onOpenModal, files, onEditFile, onDownload } = useAdditionalFiles()
  // const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const profile = useAppSelector(selectLocalizedViewerProfile)
  const statements = useAppSelector(selectStatementsList)

  /**
   * Есть ли у абитуриента заявления в статусе "В работе"
   */
  const haveRequestStatusInWork = useMemo<boolean>(() => {
    if (!statements) {
      return false
    }

    const findRequestStatusInWork = statements.find(
      (el) => el.statusStatement.code === STATUS_REQUEST_IN_WORK_CODE
    )

    return !!findRequestStatusInWork
  }, [statements])

  return (
    <section className="add-files-page">
      <div className="add-files-page__wrapper">
        <h1 className="add-files-page__title">{terms.ADDITIONAL_FILES}</h1>
        <h2>
          После загрузки документа на вкладку «Дополнительные файлы» обязательно
          напишите об этом в чате модератору.
          <br />
          Если Вы загрузили документ после одобрения заявления (статус заявления
          "Одобрен") и не написали об этом в чате модератору, то Ваш документ не
          будет учтен.
        </h2>
        <br />
        <div className="add-files-page__body">
          <Button
            disabled={haveRequestStatusInWork}
            theme="success"
            className="add-files__button"
            onClick={onOpenModal}
          >
            {terms.ADD_FILE}
          </Button>
          <ul className="add-files-page__items">
            {files.map((file) => (
              <li key={file.id} className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(file)
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                  {file.userDoc.uploaderId === profile?.id ? (
                    <button
                      className="additional-file__del-button"
                      onClick={() => {
                        onEditFile(
                          trimFileExtension(file.userDoc.name),
                          file.userDoc.id,
                          file.userDoc.getTypeDocs.name
                        )
                      }}
                    >
                      <EditIcon
                        width={23}
                        viewBox="-5 0 20 25"
                        height={20}
                        className="additional-file__cross"
                      />
                    </button>
                  ) : null}
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">
                    <p>{trimFileExtension(file.userDoc.name)}</p>
                  </div>
                  <div className="additional-file__date">
                    <div>
                      {terms.CREATED}: {formatDateTimeToRuFormat(file.createdAt)}
                    </div>
                    {file.createdAt !== file.updatedAt && (
                      <div>
                        Редактирован:{' '}
                        {formatDateTimeToRuFormat(new Date(file.updatedAt))}
                      </div>
                    )}
                    {file.userDoc.uploaderId === profile?.id && (
                      <span className="additional-file__user">
                        {profile?.usersTranslate?.surname || ' '}{' '}
                        {profile?.usersTranslate?.name || ' '}{' '}
                        {profile?.usersTranslate?.patronymic || ' '}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default AdditionalFilesPage
