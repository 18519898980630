import React from 'react'
import { Controller, FieldError } from 'react-hook-form'

import useChangePayerDetails from '../../hooks/useChangePayerDetails'
import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import { SelectOption } from '@models'
import Button from '@ui/Button/view'
import Input from '@ui/Input/view'
import Select from '@ui/Select/view'

const ModeratorEditContractForm = ({
  competitiveGroupStatementId,
  payerTypeId,
  contractNotEntrants = undefined,
  passportIds,
  payerEmail,
}) => {
  const {
    fields,
    formState: { errors },
    control,
    onSubmit,
    typeIdentityDocumentOptions,
  } = useChangePayerDetails(
    competitiveGroupStatementId,
    payerTypeId,
    contractNotEntrants,
    passportIds,
    payerEmail
  )

  return (
    <form
      className="moderator-edit-contract-form"
      onSubmit={(e) => {
        onSubmit(e)
        e.preventDefault()
      }}
    >
      <>
        <ProfileSection title="Основные данные о плательщике">
          <div className="profile-form__fields">
            <Input
              className="profile-form__field"
              placeholder={terms.SURNAME_IN_RUSSIAN}
              markRequired={true}
              {...fields?.surname}
              error={errors.surname?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.NAME_IN_RUSSIAN}
              markRequired={true}
              {...fields.name}
              error={errors.name?.message}
            />
            <Input
              placeholder={terms.PATRONYMIC}
              hint={terms.CYRILLIC_CHARACTERS_ONLY}
              className="profile-form__field"
              {...fields.patronymic}
              error={errors.patronymic?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_BIRTH}
              type="date"
              max="100"
              markRequired={true}
              {...fields.birthDay}
              error={errors.birthDate?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.MOBILE_PHONE}
              type="tel"
              markRequired={true}
              {...fields.phone}
              error={errors.phone?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.EMAIL}
              type="tel"
              markRequired={true}
              {...fields.payerEmail}
              error={errors.email?.message}
            />
            <Input
              className="profile-form__field profile-form__field--fb-100"
              placeholder="Адрес по прописке"
              markRequired={true}
              {...fields.addressResidential}
              error={errors.addressResidential?.message}
            />
            <Input
              className="profile-form__field profile-form__field--fb-100"
              placeholder="Адрес фактического проживания"
              markRequired={true}
              {...fields.address}
              error={errors.address?.message}
            />
          </div>
        </ProfileSection>
        <ProfileSection title="Паспортные данные плательщика">
          <div className="profile-form__fields">
            <Controller
              name="typeIdentityDocumentId"
              control={control}
              render={({ field }) => (
                <Select<SelectOption>
                  className="profile-form__field"
                  {...(field as unknown)}
                  options={typeIdentityDocumentOptions}
                  placeholder={terms.DOCUMENT_TYPE}
                  error={
                    (
                      errors.typeIdentityDocumentId as unknown as
                        | FieldError
                        | undefined
                    )?.message
                  }
                  markRequired={true}
                />
              )}
            />
            <div className="profile-form__field profile-form__field--container">
              <Input
                className="profile-form__input profile-form__input--fb-50"
                placeholder={terms.SERIES}
                {...fields.series}
                error={errors.series?.message}
              />
              <Input
                className="profile-form__input profile-form__input--fb-50"
                placeholder={terms.NUMBER}
                markRequired={true}
                {...fields.number}
                error={errors.number?.message}
              />
            </div>
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_ISSUE}
              type="date"
              markRequired={true}
              {...fields.whenIssued}
              error={errors.whenIssued?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.ISSUING_AUTHORITY}
              markRequired={true}
              {...fields.issuedByWhom}
              error={errors.issuedByWhom?.message}
            />
            <Input
              className="profile-form__field profile-form__field--fb-100"
              placeholder={terms.PLACE_OF_BIRTH}
              markRequired={true}
              {...fields.placeOfBirth}
              error={errors.placeOfBirth?.message}
            />
          </div>
        </ProfileSection>
        <Button type="submit">Сохранить</Button>
      </>
    </form>
  )
}

export default ModeratorEditContractForm
