import {
  Achivement,
  AchivementAnketa,
  AchivementResponseFrom,
  DisciplineCentralExam,
  disciplineCentralExamOptions,
  DisciplineEge,
  DisciplineEgeOptions,
  SelectOption,
  ViewerTargetContractForm,
} from '@models'
import { RootState } from '../../types'

export const selectDirectionTrainingSort = (state: RootState) =>
  state.statements.directionTrainingFilter

export const selectStatementsList = (state: RootState) =>
  state.statements.entities.statementsList.data

export const selectStatementsElement = (state: RootState) =>
  state.statements.entities.statementsElement.data

export const selectConclusionBaseDepartment = (state: RootState) =>
  state.statements.entities.conclusionBaseDepartment.data

export const selectBlankStatement = (state: RootState) =>
  state.statements.entities.blankStatement.data

export const selectTargetContract = (
  state: RootState
): ViewerTargetContractForm | null => {
  const result = state.statements.entities.targetContract.data
  if (!result || !result?.data) return null
  return result.data
}

export const selectBlankStatementCheckbox = (state: RootState) =>
  state.statements.checkboxStatement

export const selectTrajectoryList = (state: RootState) =>
  state.statements.entities.trajectoryList.data

export const selectCanSend = (state: RootState) => state.statements.canSend

export const selectLevelList = (state: RootState) => {
  const { data } = state.statements.entities.levelList
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.scenarioTranslate
    return {
      id: translate.scenarioId,
      name: translate.name,
    }
  })
}

export const selectDirectionsList = (state: RootState) =>
  state.statements.entities.directionsList.data

export const selectStudentsList = (state: RootState) =>
  state.statements.entities.studentsList.data

export const selectCompetitiveGroupsTrajectory1 = (state: RootState) => {
  const { data } = state.statements.entities.competitiveGroups
  if (!data || !data.length) return []

  return data[0]?.trials?.map((trial) => {
    return trial?.disciplineTrial?.map((discipline) => {
      return {
        trial: 'trial-' + trial?.priority,
        disciplineId: discipline?.discipline?.id,
        ege: discipline?.ege,
        disciplineName: discipline?.discipline?.translate[0]?.name,
      }
    })
  })
}

export const selectTrainingLevels = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.trainingLevels.data || {}
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.trainingLevelTranslate
    return {
      value: translate.trainingLevelId,
      label: translate.name,
    }
  })
}

export const selectEntranceExams = (state: RootState) => {
  const { data } = state.statements.entities.entranceExams || []
  if (!data || !data?.status.success?.length) return []

  return data.status.success
}

export const selectContractsList = (state: RootState) =>
  state.statements.entities.contractsList.data

export const selectContract = (state: RootState) =>
  state.statements.entities.contract.data

export const selectFilteredDirectionsList = (state: RootState) => {
  const { data } = state.statements.entities.directionsList
  if (!data) return []
  return data.filter((list) => list.formPaymentsId !== 10)
}
export const selectDirectionsListStatements = (state: RootState) =>
  state.statements.entities.directionsListStatements.data

export const selectDirectionListStatementOptions = (
  state: RootState
): SelectOption[] => {
  const { data } = state.statements.entities.directionsList
  if (!data) return []
  return data.map((dl) => {
    const [translate] = dl.directionTraining.translate
    return {
      label: translate.name,
      value: translate.directionTrainingId,
    }
  })
}

export const selectDirectionListOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.directionListOptions
  if (!data) return []
  return data
    .filter((direction) => direction.formPayment.id !== 10)
    .map((dl) => {
      const [translate] = dl.directionTraining.translate
      const profileTranslate = dl?.profile?.translate[0]?.name
        ? ` (${dl?.profile?.translate[0]?.name})`
        : ''

      return {
        label: `${translate.name}${profileTranslate}, ${dl?.faculty?.facultyTranslate[0]?.name}, ${dl?.formEducation?.formEducationTranslate[0]?.name}`,
        value: translate.directionTrainingId,
        formPaymentCode: dl.formPayment.code,
        id: dl.pivot.id,
        disallow: dl.directionIncomplete,
        accessСonclusion: dl?.competitiveGroupStatement?.accessConclusion,
        formEducation: dl?.formPayment.translate[0].name,
        groupId: dl?.competitiveGroupStatement.competitiveGroupId,
      }
    })
}

export const selectLevelFormDirectionsTrainingSearchOptions = (
  state: RootState
): SelectOption[] => {
  const {
    levelDirectionsTrainingSearch: { data },
  } = state.statements.entities
  if (!data) return []
  return data.map((n) => {
    const [level] = n.levelTranslate
    return {
      label: level.name,
      value: level.levelEducationId,
    }
  })
}

export const selectPaymentDirectionsTrainingSearchOptions = (
  state: RootState
): SelectOption[] => {
  const {
    paymentDirectionsTrainingSearch: { data },
  } = state.statements.entities
  if (!data) return []
  return data.map((n) => {
    const [level] = n.translate
    return {
      label: level?.name,
      value: level?.formPaymentId,
    }
  })
}

export const selectLevelDirectionsTrainingSearchOptions = (
  state: RootState
): SelectOption[] => {
  const {
    formDirectionsTrainingSearch: { data },
  } = state.statements.entities
  if (!data) return []
  return data.map((n) => {
    const [formEducation] = n.formEducationTranslate
    return {
      label: formEducation.name,
      value: formEducation.formEducationId,
    }
  })
}

export const selectFacultyOptions = (state: RootState): SelectOption[] => {
  const {
    faculty: { data },
  } = state.statements.entities
  if (!data) return []
  return data.map((n) => {
    const [faculty] = n.facultyTranslate
    return {
      label: faculty?.name,
      value: faculty?.facultyId,
    }
  })
}

export const selectDirectionTrainingsList = (state: RootState): SelectOption[] => {
  const {
    directionTrainingsList: { data },
  } = state.statements.entities
  if (!data) return []
  return data.map((n) => {
    const [translate] = n.translate
    return {
      label: translate.name,
      value: translate.directionTrainingId,
    }
  })
}

export const selectLevelValue = (state: RootState) =>
  state.statements.tabs.levelValue

export const selectTrajectoryValue = (state: RootState) =>
  state.statements.tabs.trajectoryValue

export const selectSignedContractsList = (state: RootState) =>
  state.statements.signedContractsList
export const selectSignedPaidReceiptList = (state: RootState) =>
  state.statements.signedPaidReceiptList

export const selectEmptyValue = (state: RootState) =>
  state.statements.tabs.emptyValue

export const selectTabEgeList = (state: RootState) =>
  state.statements.entities.tabEgeData.data

export const selectTabEgeListV2 = (state: RootState) =>
  state.statements.entities.tabEgeDataV2.data
export const selectDisciplineEgeOptions = (
  state: RootState
): DisciplineEgeOptions[] => {
  const { data } = state.statements.entities.disciplineEgeOptions
  if (!data) return []
  return data.map((discipline: DisciplineEge) => {
    return {
      label: discipline.name,
      value: discipline.disciplineId,
      score: discipline.score || '0',
    }
  })
}

export const selectDisciplineCentralExamOptions = (
  state: RootState
): disciplineCentralExamOptions[] => {
  const { data } = state.statements.entities.disciplineCentralExamOptions
  if (!data) return []
  return data.map((discipline: DisciplineCentralExam) => {
    return {
      label: discipline.name,
      value: discipline.disciplineId,
      score: discipline.score || '0',
    }
  })
}

export const selectAchivementTypesOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.achivementTypes
  if (!data) return []
  return data.map((achivement) => {
    const [translate] = achivement.achivmentTranslate
    return {
      label: translate.name,
      value: translate.achivmentId,
    }
  })
}

export const selectOlympiadTypesOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.olympiadTypes
  if (!data) return []
  return data.data.map((docType) => ({
    label: docType.name,
    value: docType.id,
  }))
}

export const selectOlympiadsDocTypesOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.olympiadDocTypes
  if (!data) return []
  return data.data.map((docType) => ({
    label: docType.name,
    value: docType.id,
  }))
}

export const selectAchivementDocTypes = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.achivementDocTypes
  if (!data) return []
  return data.map((docType) => {
    const [translate] = docType.achivmentDocTranslate
    return {
      label: translate.name,
      value: translate.achivmentDocId,
    }
  })
}

export const selectDisciplines = (state: RootState) => {
  const { data } = state.statements.entities.disciplines
  if (!data) return []
  return data.map((d) => {
    return {
      ...d,
      dateDiscipline: d.dateDiscipline.map((date) => {
        return {
          value: date.id,
          label: date.date,
        }
      }),
    }
  })
}

export const selectAchivements = (state: RootState): Achivement[] => {
  const { data } = state.statements.entities.achivements
  if (!data?.achivment) return []
  return data.achivment.map((achivement) => {
    return {
      ...achivement,
      documents: [],
      secondDocuments: [],
      secondTypeDoc: 'copy_certificate',
      typeDoc: 'individual_achievements',
      name: achivement.achivment?.achivmentTranslate[0].name,
      docName: achivement.achivmentDoc?.achivmentDocTranslate[0].name,
      achivementDocOption: extractAchivementDocOption(achivement.achivmentDoc),
      achivementTypeOption: extractAchivementTypeOption(achivement.achivment),
      requireDopInfo: undefined,
    }
  })
}

export const selectAchivementAnketa = (
  state: RootState
): AchivementAnketa[] | [] => {
  const { data } = state.statements.entities.achivements
  if (!data?.achivmentAnketa) return []
  if (Array.isArray(data.achivmentAnketa)) return []
  return [data.achivmentAnketa]
}

export const selectAchivementSpecialCondition = (
  state: RootState
): AchivementResponseFrom['specialCondition'] => {
  const { data } = state.statements.entities.achivements
  return data?.specialCondition
}

export const selectOlympiads = (state: RootState) => {
  const { data } = state.statements.entities.olympiads
  if (!data) return []
  return data.data.map((olympiad) => ({
    ...olympiad,
    documents: [],
    typeDoc: 'individual_olympiad',
    name: olympiad.name,
    olympiadDoc: olympiad.docType,
    id: olympiad.userOlympiadId,
    olympiadDocOption: {
      label: olympiad.docType,
      value: olympiad.docType === 'Диплом победителя' ? 5 : 6,
    },
    olympiadTypeOption: { label: olympiad.name, value: olympiad.olympiadId },
    year: {
      label: olympiad.year,
      value: olympiad.year === 2018 ? 1 : olympiad.year === 2019 ? 2 : 3,
    },
  }))
}

function extractAchivementDocOption(achivementDoc?: {
  id: number
  code: string
  achivmentDocTranslate: {
    id: number
    localed: number
    achivmentDocId: number
    name: string
  }[]
}): SelectOption | undefined {
  if (!achivementDoc) return
  const [translate] = achivementDoc.achivmentDocTranslate
  return {
    label: translate.name,
    value: translate.achivmentDocId,
  }
}

function extractAchivementTypeOption(achivementType?: {
  id: number
  code: string
  achivmentTranslate: {
    id: number
    localeId: number
    achivmentId: number
    name: string
  }[]
}): SelectOption | undefined {
  if (!achivementType) return
  const [translate] = achivementType.achivmentTranslate
  return {
    label: translate.name,
    value: translate.achivmentId,
  }
}

export const selectHousingUsePaymentsOptions = (
  state: RootState
): SelectOption[] => {
  const { data } = state.statements.entities.housingUsePaymentsOptions
  if (!data) return []
  return data.data.map((el) => {
    return {
      label: el.name,
      value: el.id,
    }
  })
}

export const selectPaymentForEdusOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.paymentForEdusOptions
  if (!data) return []
  return data.data.map((el) => {
    return {
      label: el.name,
      value: el.id,
    }
  })
}

export const selectFarePaymentsOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.farePaymentsOptions
  if (!data) return []
  return data.data.map((el) => {
    return {
      label: el.name,
      value: el.id,
    }
  })
}

export const selectMonetaryPaymentsOptions = (state: RootState): SelectOption[] => {
  const { data } = state.statements.entities.monetaryPaymentsOptions
  if (!data) return []
  return data.data.map((el) => {
    return {
      label: el.name,
      value: el.id,
    }
  })
}

export const selectMoodleCourses = (state: RootState) =>
  state.statements.entities.moodleCourses.data

export const selectDisciplineTrials = (state: RootState) =>
  state.statements.entities.disciplineTrials.data

export const selectListGroups = (state: RootState) =>
  state.statements.entities.listGroups.data

export const selectIncomingList = (state: RootState) =>
  state.statements.entities.incomingList.data?.data

export const selectPlaceEmploymentsList = (state: RootState) =>
  state.statements.entities.placeEmploymentsList.data?.data

export const selectSchoolSubjects = (state: RootState) => {
  return state.statements.entities.schoolSubjects.data
}

export const selectUserSchoolSubjects = (state: RootState) => {
  let response = state.statements.entities.userSchoolSubjects.data

  return response?.data.map((item) => {
    return {
      estimate: item.estimate,
      userId: item.userId,
      schoolSubjectsId: item.subject.id,
      schoolSubjectsName: item.subject.name,
    }
  })
}

export const selectUserEstimates = (state: RootState) => {
  return state.statements.entities.userEstimates.data
}

export const selectUserConsentToEnrollmentHistory = (state: RootState) => {
  return state.statements.entities.userConsentToEnrollmentHistory.data ?? []
}
