import './index.scss'

import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import FileTypePdf from '@assets/images/FileTypePdf.svg'
import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import useVisaForm from '@hooks/useVisaForm'
import useVisaFormInitialization from '@hooks/useVisaFormInitialization'
import { dateLastValidDate } from '@lib/validators'
import { SelectOption, Visa } from '@models'
import {
  selectIsQuestionnaireBlockProfile,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import { getViewerProfile } from '@redux/reducers'
import { UpdateVisaResponse } from '@services/api'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Link from '@ui/Link'
import RadioButton from '@ui/RadioButton'
import Select from '@ui/Select'

const VisaForm = () => {
  const {
    fields,
    control,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    noTargetVisa,
    targetVisa,
    getDoc,
    formState: { errors },
    onSubmit,
  } = useVisaForm()

  const {
    docs: scanTranslationDocEdu,
    onDrop: scanTranslationDocEduOnDrop,
    onDelete: scanTranslationDocEduOnDelete,
    onChange: scanTranslationDocEduOnChange,
    onUploadDocs: scanTranslationDocEduOnUploadDocs,
    getUploadedDoc: scanTranslationDocEduGetUploadedDoc,
    documentLoaded: scanTranslationDocEduDocumentLoaded,
  } = useUploadDocuments(
    'VISA_APPLICATION_FORM_INVITATION',
    'Анкета для приглашения'
  )

  const valid = !(
    watch('countryVisaId') &&
    watch('cityVisaId') &&
    !!watch('estimatedDate') &&
    watch('qa.illCovid') &&
    watch('qa.vaccinatedCovid') &&
    watch('qa.goingVaccinatedCovid')
  )
  const profileData = useAppSelector(selectLocalizedViewerProfile)
  const { visaCountry, visaCityOptions, visaCountriesSelectProps, covidOption } =
    useVisaFormInitialization({ watch, setValue })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getViewerProfile())
    if (profileData?.needVisa != undefined) {
      if (profileData?.needVisa) {
        setValue('noTargetVisa', '1')
      } else {
        setValue('noTargetVisa', '0')
      }
    }
  }, [profileData?.needVisa])

  useEffect(() => {
    if (!scanTranslationDocEduDocumentLoaded) {
      scanTranslationDocEduGetUploadedDoc()
    }
  }, [])

  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const { onNextPageNavigate } = useProfileRoutesContext()

  const [docError, setDocError] = useState('')

  function sendVisaData() {
    clearErrors('estimatedDate')

    if (!dateLastValidDate(getValues('estimatedDate') || null, '2024-08-20')) {
      setError(
        'estimatedDate',
        { type: 'validate', message: terms.INVALID_VISA_DATE },
        { shouldFocus: true }
      )
      return
    }

    axios
      .post<Visa, UpdateVisaResponse>(
        '/userVisa',
        snakecaseKeys({
          cityVisaOption: getValues('cityVisaId'),
          countryVisaOption: getValues('countryVisaOption'),
          estimatedDate: getValues('estimatedDate'),
          qa: JSON.stringify({
            illCovid: getValues('qa.illCovid') ?? null,
            vaccinatedCovid: getValues('qa.vaccinatedCovid') ?? null,
            goingVaccinatedCovid: getValues('qa.goingVaccinatedCovid') ?? null,
          }),
          noTargetVisa: getValues('noTargetVisa'),
          // @ts-ignore
          countryVisaId: getValues('countryVisaId.value'),
          // @ts-ignore
          cityVisaId: getValues('cityVisaId.value'),
          phd: getValues('phd'),
          assistantship: getValues('assistantship'),
        })
      )
      .then(() => {
        getDoc()
      })
  }

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              e.preventDefault()
              if (noTargetVisa && scanTranslationDocEdu.length === 0) {
                setDocError('Заполните поле')
                return
              } else {
                onSubmit(e)
                if (noTargetVisa) {
                  scanTranslationDocEduOnUploadDocs(() =>
                    scanTranslationDocEduGetUploadedDoc()
                  )
                }
              }
            }
      }
    >
      <div className="profile__visa">
        {/* TODO: добавить англ язык */}
        <p className={'visa-title'}>{terms.VISA_TITLE}</p>
        <RadioButton>
          <div className="radio-button--title">
            <b>{terms.VISA}</b>
          </div>
          <RadioButton.Radio
            {...fields.noTargetVisa}
            text={terms.I_NEED_VISA}
            disabled={readOnly}
            value="1"
            checked={noTargetVisa}
          />
          <RadioButton.Radio
            {...fields.noTargetVisa}
            text={terms.I_NOT_NEED_VISA}
            disabled={readOnly}
            value="0"
            checked={targetVisa}
          />
        </RadioButton>
      </div>
      {noTargetVisa && (
        <>
          <div className="profile__visa">
            {/* TODO: интернационализация */}
            <Link
              href="/documents/Информация_для_обучающихся_ENG.pdf"
              target="_blank"
              className="visaLink"
            >
              <FileTypePdf className="visaLink_icon" fill="#575756" />
              Информация для пребывающих на территорию Российской Федерации
            </Link>
          </div>
          <ProfileSection>
            <ProfileSection title={terms.PLACE_OF_VISA_APPLICATION}>
              <div className="profile-form__fields">
                <Controller
                  name="countryVisaId"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectOption>
                      className="profile-form__field"
                      placeholder={terms.COUNTRY_OF_VISA_APPLICATION}
                      {...visaCountriesSelectProps}
                      {...(field as unknown)}
                      error={errors.countryVisaId?.message}
                      markRequired={true}
                    />
                  )}
                />

                <Controller
                  name="cityVisaId"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectOption>
                      className="profile-form__field"
                      {...(field as unknown)}
                      options={visaCityOptions}
                      isDisabled={!visaCountry?.value}
                      placeholder={terms.CITY_OF_VISA_APPLICATION}
                      error={errors.cityVisaId?.message}
                      markRequired={true}
                    />
                  )}
                />
              </div>

              <div className="profile-form__fields">
                <Input
                  className="profile-form__field"
                  type="date"
                  markRequired={true}
                  {...fields.estimatedDate}
                  title="Предполагаемый срок въезда в Россию"
                  placeholder="Предполагаемый срок въезда в Россию"
                  error={errors.estimatedDate?.message}
                  max="2036-01-01"
                />
              </div>
            </ProfileSection>

            <ProfileSection title={terms.COVID_QUESTIONS}>
              <div className="profile-form__fields">
                <Controller
                  name="qa.illCovid"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectOption>
                      className="profile-form__field"
                      placeholder={terms.SICK_COVID}
                      options={covidOption}
                      {...(field as unknown)}
                      markRequired={true}
                      error={errors.qa?.illCovid?.message}
                    />
                  )}
                />

                <Controller
                  name="qa.vaccinatedCovid"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectOption>
                      className="profile-form__field"
                      {...(field as unknown)}
                      options={covidOption}
                      placeholder={terms.HAVE_VACCINATION}
                      markRequired={true}
                      error={errors.qa?.vaccinatedCovid?.message}
                    />
                  )}
                />

                <Controller
                  name="qa.goingVaccinatedCovid"
                  control={control}
                  render={({ field }) => (
                    <Select<SelectOption>
                      className="profile-form__field"
                      {...(field as unknown)}
                      options={covidOption}
                      placeholder={terms.CAN_DO_VACCINATION_IN_RUSSIA}
                      markRequired={true}
                      error={errors.qa?.goingVaccinatedCovid?.message}
                    />
                  )}
                />
              </div>
            </ProfileSection>
            <div style={{ marginBottom: '15px' }}>
              <Checkbox
                text="Поступаю на программу аспирантуры РГПУ им. А.И. Герцена"
                {...fields.phd}
              />
            </div>

            <div>
              <Checkbox
                text="Поступаю на программу ассистентуры-стажировки РГПУ им. А.И. Герцена"
                {...fields.assistantship}
              />
            </div>

            <div className="profile-form__fields">
              <Button
                type={'button'}
                disabled={valid}
                onClick={sendVisaData}
                className="visa__upload-doc-btn"
              >
                {'Скачать анкету для приглашения'}
              </Button>
            </div>
          </ProfileSection>
          <ProfileSection
            fileUploadingHint
            title="Анкета для приглашения"
            markRequired={true}
          >
            <FileUploader
              disabled={readOnly}
              files={scanTranslationDocEdu}
              onDrop={(e) => {
                setDocError('')
                scanTranslationDocEduOnDrop(e)
              }}
              onChange={(e) => {
                setDocError('')
                scanTranslationDocEduOnChange(e)
              }}
              onDelete={(e) => {
                setDocError('Заполните поле')
                scanTranslationDocEduOnDelete(e)
              }}
              className="profile-form__file-uploader"
            />

            <p style={{ color: 'red' }}>{docError}</p>
          </ProfileSection>
        </>
      )}
    </ProfileForm>
  )
}

export default VisaForm
