import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms from '@common/terms'
import { UploadDocKey } from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useNotEntrantsContractFormInitialization from '@hooks/useNotEntrantsContractFormInitialization'
import {
  getPassportNumber,
  getPassportSeries,
  normalizeSNILS,
  normalizeYearTo4Characters,
  validateSNILS,
} from '@lib/validators'
import { ContractNotEntrants, SelectOption } from '@models'
import { createToast, getContractsList } from '@redux/action-types'
import {
  selectTypeIdentityDocumentOptions,
  selectTypeDocsOptions,
  selectScenarioKeysOptions,
} from '@redux/actions/selectors'
import api, { ResponseOk } from '@services/api'
import { AddContractNotEntrantsResponse } from '@services/api/statements-api'

// @ts-ignore
const useNotEntrantsContractForm = (
  contract?,
  trajectoryId?: number | string,
  scenarioId?: number | string
) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<ContractNotEntrants>({ mode: 'all' })
  const typeDocs = useAppSelector(selectTypeDocsOptions)
  const passportKeys = {
    foreign_citizen_passport_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 49
    ),
    identity_card_stateless_person_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 90
    ),
    other_document_code: typeDocs.find((value) => value.uniqueKeyOnSite == 128),
    passport_non_citizen_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 153
    ),
    russia_certificate_temporary_asylum_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 152
    ),
    russia_citizen_passport_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 47
    ),
    russia_foreign_passport_code: typeDocs.find(
      (value) => value.uniqueKeyOnSite == 89
    ),
  }
  const forIno =
    trajectoryId === 1 ||
    (trajectoryId === 2 &&
      scenarioId !== 5 &&
      scenarioId !== 6 &&
      scenarioId !== 1 &&
      scenarioId !== 3)

  const dispatch = useAppDispatch()
  const { statementId } = useParams()
  const navigate = useNavigate()

  const typeDocId = watch('typeIdentityDocumentId') as unknown as
    | SelectOption
    | undefined
  const isSeriesRequired =
    typeDocId?.value === passportKeys.russia_citizen_passport_code.tdId

  const isRussianPassport =
    typeDocId?.value === passportKeys.russia_citizen_passport_code.tdId

  const isDateOfExpirationExists =
    typeDocId?.value !== passportKeys.russia_citizen_passport_code.tdId

  const typeIdentityDocumentOptions = useAppSelector(
    selectTypeIdentityDocumentOptions
  )
  // @ts-ignore
  let forInoPassport = watch('typeIdentityDocumentId.code') === '49' && forIno

  const isRussianForeignPassport =
    typeDocId?.value === passportKeys.russia_citizen_passport_code.tdId

  const isCodeActive = () =>
    typeDocId?.code
      ? ![
          passportKeys.foreign_citizen_passport_code.onesKey,
          passportKeys.identity_card_stateless_person_code.onesKey,
          passportKeys.russia_certificate_temporary_asylum_code.onseKey,
          passportKeys.passport_non_citizen_code.onesKey,
          passportKeys.other_document_code.onesKey,
        ].includes(typeDocId?.code ?? '')
      : true

  const fields = {
    statementId: register('statementId'),
    directionTrainingId: register('directionTrainingId', {
      required: terms.REQUIRED_FIELD,
      onChange: (e) => setValue('competitiveGroupStatementId', e.target.value.id),
    }),
    contractId: register('contractId'),
    surname: register('surname', { required: terms.REQUIRED_FIELD }),
    name: register('name', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    patronymic: register('patronymic'),
    birthDay: register('birthDate', {
      required: { value: true, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('birthDate', normalizeYearTo4Characters(value)),
    }),
    phone: register('phone', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    address: register('address', { required: terms.REQUIRED_FIELD }),
    addressResidential: register('addressResidential', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    typeIdentityDocumentId: register('typeIdentityDocumentId', {
      required: { message: terms.REQUIRED_FIELD, value: true },
      onChange: (e) => {
        e.target.value === passportKeys.russia_citizen_passport_code.tdId
          ? setValue('validityPeriod', '')
          : null
      },
    }),
    series: register('series', {
      required: isSeriesRequired || forInoPassport ? terms.REQUIRED_FIELD : '',
      ...getPassportSeries(isSeriesRequired),
      pattern: {
        value:
          isRussianForeignPassport || isRussianPassport
            ? regexp.passportSeries
            : regexp.passportSeriesForeignSymbols,
        message:
          isRussianForeignPassport || isRussianPassport
            ? terms.PASSPORT_SERIES_LENGTH
            : terms.LATIN_CYRILLIC_CHARACTERS_AND_NUMBER_ONLY,
      },
    }),
    number: register('number', {
      required: { message: terms.REQUIRED_FIELD, value: true },
      ...getPassportNumber(isRussianPassport),
      pattern: {
        value:
          isRussianForeignPassport || isRussianPassport
            ? regexp.passportNumber
            : regexp.passportNumberForeign,
        message:
          isRussianForeignPassport || isRussianPassport
            ? terms.PASSPORT_SERIES_LENGTH
            : terms.ONLY_NUMBER,
      },
    }),
    code: register('code', {
      required: { value: isRussianPassport, message: terms.REQUIRED_FIELD },
    }),
    validityPeriod: register('validityPeriod', {
      required: { value: isDateOfExpirationExists, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('validityPeriod', normalizeYearTo4Characters(value)),
    }),
    whenIssued: register('whenIssued', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('whenIssued', normalizeYearTo4Characters(value)),
    }),
    issuedByWhom: register('issuedByWhom', { required: terms.REQUIRED_FIELD }),
    placeOfBirth: register('placeOfBirth', { required: terms.REQUIRED_FIELD }),
    attorneyRepresentative: register('attorneyRepresentative'),
    attorneyCustomer: register('attorneyCustomer'),
    data: register('data'),
    document: register('document', { required: terms.REQUIRED_FIELD }),
    payerTypeId: register('payerTypeId'),
    competitiveGroupStatementId: register('competitiveGroupStatementId'),
    typeDoc: register('typeDoc'),
    eduCredit: register('eduCredit'),
    payerEmail: register('payerEmail', {
      required: terms.REQUIRED_FIELD,
    }),
    snils: register('snils', {
      required: { value: !forIno, message: terms.REQUIRED_FIELD },
      validate: (value) =>
        value && value.length >= 15
          ? validateSNILS(normalizeSNILS(value))
            ? true
            : terms.SNILS_INVALID
          : true,
      onChange: ({ target: { value } }) =>
        value && setValue('snils', normalizeSNILS(value)),
    }),
  }

  const isEditing = Boolean(contract)

  const { docs, onDrop, onDelete, onChange } =
    useNotEntrantsContractFormInitialization(setValue, contract)

  const catchPrepare = (
    response: Exclude<AddContractNotEntrantsResponse, ResponseOk>
  ) => {
    const { status } = response

    if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      const { data } = response
      dispatch(createToast(data.message, 'danger'))

      Object.keys(data.errors).forEach((key) => {
        setError(key as keyof ContractNotEntrants, {
          type: 'manual',
          message: data.errors[key][0],
        })
      })
    } else {
      dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
    }
  }

  const editContractFile = (
    document: File[],
    contractId: string,
    typeDoc: UploadDocKey
  ) => {
    api.client
      .editContractFile(document, String(contractId), typeDoc)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch(catchPrepare)
  }

  const contractRevoke = (id) =>
    api.client
      .contractRevokeById(id)
      .then(() => dispatch(createToast('Успешно', 'success')))
      .catch(catchPrepare)
      .finally(() => {
        dispatch(getContractsList(String(statementId)))
        navigate(`/application/${statementId}/filling-statements/contract`)
      })

  const contractDelete = (id) =>
    api.client
      .contractDeleteById(id)
      .then(() => dispatch(createToast('Успешно', 'success')))
      .catch(catchPrepare)
      .finally(() => {
        dispatch(getContractsList(String(statementId)))
        navigate(`/application/${statementId}/filling-statements/contract`)
      })

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: ContractNotEntrants = {
      ...data,
      statementId: data.statementId,
      directionTrainingId: data.directionTrainingId.value,
      typeIdentityDocumentId: data.typeIdentityDocumentId.value,
      snils: data?.snils?.replace(/\D/g, ''),
    }

    data.typeIdentityDocumentId.value ===
    passportKeys.russia_citizen_passport_code.tdId
      ? delete normalizedData.validityPeriod
      : null

    if (isEditing) {
      editContractFile(
        data.document,
        String(data.contractId),
        'COPY_PASSPORT_FOR_CONTRACT'
      )
      api.client
        .editContractNotEntrants(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch(catchPrepare)
        .finally(() => {
          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    } else {
      api.client
        .addContractNotEntrants(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch(catchPrepare)
        .finally(() => {
          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    }
  })

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    handleSubmit,
    ...rest,
    clearErrors,
    isSeriesRequired,
    isDateOfExpirationExists,
    docs,
    onDrop,
    onDelete,
    onChange,
    editContractFile,
    typeIdentityDocumentOptions,
    isCodeActive,
    contractRevoke,
    contractDelete,
    forIno,
    forInoPassport,
    isRussianPassport,
  }
}

export default useNotEntrantsContractForm
