import React from 'react'
import { Controller } from 'react-hook-form'

import TemplateInoAuthForm from 'components/TemplateInoAuthForm'

import { registrationFieldsByLocale } from '@common/constants'
import terms, { locale } from '@common/terms'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import useRegisterCheckbox from '@hooks/useRegisterCheckbox'
import useSignUpForm from '@hooks/useSignUpForm'
import { Nationality } from '@models'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import Link from '@ui/Link'
import RouteButtonLink from '@ui/RouteButtonLink'
import Select from '@ui/Select'

type SignUpFormProps = {
  title: string
  dataView: any
}

const isRuLocale = locale === 'ru'
const localeCharactersOnly = isRuLocale
  ? terms.CYRILLIC_CHARACTERS_ONLY
  : terms.LATIN_CHARACTERS_ONLY

const SignUpForm = ({ dataView, title }: SignUpFormProps) => {
  const {
    fields,
    onSubmit,
    control,
    loading,
    captchaImage,
    noNationality,
    formState: { errors },
    setValue,
    watch,
  } = useSignUpForm()

  const {
    isRussia,
    isShowRegisterError,
    isShowCheckboxesError,
    setIsShowCheckboxesError,
    handleSubmit,
  } = useRegisterCheckbox({ onSubmit, watch })

  const nationalSelectProps = usePaginatedNationalsIno()

  let image
  if (dataView['picture']) {
    image = `data:image/jpeg;base64,${dataView['picture']}`
  }
  if (dataView['link'] == null) {
    dataView['link'] = '/sign-up'
  }

  return (
    <TemplateInoAuthForm title={title} data={dataView} message>
      <div className="auth-form__body">
        <div className="auth-form__policy">
          <div className="auth-form__image">
            <img
              src={image}
              alt=""
              style={{
                width: '100%',
                minWidth: '80%',
                height: 'auto',
                marginBottom: '15px',
              }}
            />
            <div className="auth_invite_text_message ">
              {/*начало коммита текста и картинки*/}
              {/*{dataView['textPicture'] + ' '}*/}
              {/*<a className="auth-invite__link" href={dataView['link']}>*/}
              {/*  {dataView['textPictureLink']}*/}
              {/*</a>*/}
              {/*конец коммита текста и картинки*/}

              {/*<RouteLink className="auth-invite__link" to={dataView['link']}>*/}
              {/*  {dataView['textPictureLink']}*/}
              {/*</RouteLink>*/}
            </div>
          </div>
          <p className="auth-form__paragraph">
            <Link
              href="https://www.herzen.spb.ru/abiturients/priem-inostrannykh-grazhdan/"
              target="_blank"
            >
              {terms.USER_MANUAL_FOR_APPLICANTS}
            </Link>
          </p>
          <p className="auth-form__paragraph">
            <Link
              href="/documents/User manual for foreign applicants.pdf"
              target="_blank"
            >
              {terms.USER_MANUAL}
            </Link>
          </p>
          <p className="auth-form__paragraph">
            <Link href="https://vk.com/video-46508610_456239728" target="_blank">
              {terms.USER_VIDEO_INSTRUCTION_RUSSIAN}
            </Link>
          </p>
          <p className="auth-form__paragraph">
            <Link href="https://vk.com/video-46508610_456239750" target="_blank">
              {terms.USER_VIDEO_INSTRUCTION_CHINA}
            </Link>
          </p>
        </div>
        <div className="auth-form__fields">
          {isShowRegisterError ? (
            <div className="auth-form__fields">
              <div className="globalError">
                Уважаемый абитуриент! У Вас есть возможность подать заявление только
                через{' '}
                <b>
                  <a
                    href="https://www.gosuslugi.ru/vuzonline"
                    className="sspvo_link"
                  >
                    ССПВО «Поступление в вуз онлайн»
                  </a>
                </b>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="auth-form__body auth-form__body--mobile-column-reverse">
                <div className="auth-form__fields">
                  <h3 className="auth-form__subtitle">{terms.BASIC_DATA}</h3>

                  <Input
                    className="auth-form-register__input"
                    hint={localeCharactersOnly}
                    placeholder={
                      isRuLocale ? terms.SURNAME_IN_RUSSIAN : terms.SURNAME_IN_LATIN
                    }
                    markRequired={true}
                    {...fields.surname}
                    error={errors[registrationFieldsByLocale['surname']]?.message}
                  />
                  <Input
                    className="auth-form-register__input"
                    hint={localeCharactersOnly}
                    placeholder={
                      isRuLocale ? terms.NAME_IN_RUSSIAN : terms.NAME_IN_LATIN
                    }
                    markRequired={true}
                    {...fields.name}
                    error={errors[registrationFieldsByLocale['name']]?.message}
                  />
                  <Input
                    className="auth-form-register__input"
                    hint={localeCharactersOnly}
                    placeholder={
                      isRuLocale
                        ? terms.PATRONYMIC_IN_RUSSIAN
                        : terms.PATRONYMC_IN_LATIN
                    }
                    {...fields.patronymic}
                    error={errors[registrationFieldsByLocale['patronymic']]?.message}
                  />
                  <Input
                    className="auth-form-register-date__input"
                    placeholder={terms.DATE_OF_BIRTH}
                    type="date"
                    max="100"
                    markRequired={true}
                    {...fields.birthday}
                    error={errors.date_birthday?.message}
                  />
                  <Input hidden={true} {...fields.code} value={'2'} />
                  <Controller
                    name="nationality"
                    control={control}
                    render={({ field }) => (
                      <Select<Nationality>
                        placeholder={terms.CITIZENSHIP}
                        className="auth-form-register-date__input"
                        markRequired={!noNationality}
                        {...(field as any)}
                        {...nationalSelectProps}
                        isDisabled={noNationality}
                        error={errors.nationality?.message}
                      />
                    )}
                  />
                  <Checkbox
                    text={terms.NO_NATIONALITY}
                    className="profile-form__checkbox"
                    {...fields.noNationality}
                  />

                  {/* Чекбоксы */}
                  {isRussia && (
                    <div className="signCheckboxes">
                      <div>Поступаю на:</div>
                      {isShowCheckboxesError && (
                        <div className="signCheckboxes_error">
                          Обязательно к заполнению
                        </div>
                      )}
                      <Checkbox
                        text="Программы среднего профессионального образования"
                        {...fields.programsSpo}
                        onChange={(e) => {
                          const value = e.target.checked
                          setIsShowCheckboxesError(false)
                          setValue('programs.spo', value)
                        }}
                      />
                      <Checkbox
                        text="Программы бакалавриата и специалитета"
                        {...fields.programsBakAndSpec}
                        onChange={(e) => {
                          const value = e.target.checked
                          setIsShowCheckboxesError(false)
                          setValue('programs.bakAndSpec', value)
                        }}
                      />
                      <Checkbox
                        text="Программы магистратуры"
                        {...fields.programsMag}
                        onChange={(e) => {
                          const value = e.target.checked
                          setIsShowCheckboxesError(false)
                          setValue('programs.mag', value)
                        }}
                      />
                      <Checkbox
                        text="Программы аспирантуры"
                        {...fields.programsAsp}
                        onChange={(e) => {
                          const value = e.target.checked
                          setIsShowCheckboxesError(false)
                          setValue('programs.asp', value)
                        }}
                      />
                      <Checkbox
                        text="Программы ассистентуры-стажировки"
                        {...fields.programsIntern}
                        onChange={(e) => {
                          const value = e.target.checked
                          setIsShowCheckboxesError(false)
                          setValue('programs.intern', value)
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="auth-form__fields">
                  <h3 className="auth-form__subtitle">{terms.AUTHORIZATION}</h3>
                  <Input
                    placeholder={terms.EMAIL}
                    className="auth-form__input"
                    markRequired={true}
                    {...fields['email']}
                    error={errors.email?.message}
                  />
                  <Input
                    className="auth-form-register__input"
                    hint={terms.PASS_HINT}
                    placeholder={terms.CREATE_PASSWORD}
                    enableSwitcher={true}
                    markRequired={true}
                    {...fields['password']}
                    error={errors.password?.message}
                  />
                  <div className="auth-form__captcha">
                    <img src={captchaImage} alt="captcha" />
                    <Input
                      className="auth-form__input"
                      placeholder={terms.CAPTCHA}
                      markRequired
                      error={errors.captcha?.message}
                      {...fields.captcha}
                    />
                  </div>
                  <div className="auth-form__agreement">
                    <Checkbox
                      text={terms.CONSENT_TO_DATA_PROCESSING}
                      {...fields['accept']}
                    />
                  </div>
                </div>
              </div>
              <div className="auth-form__buttons">
                <RouteButtonLink to="/ino-sign-in" className="auth-form__button">
                  {terms.BACK}
                </RouteButtonLink>
                <Button
                  theme="success"
                  className="auth-form__button"
                  disabled={loading}
                >
                  {terms.REGISTER}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </TemplateInoAuthForm>
  )
}

export default SignUpForm
