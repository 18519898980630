import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import useAppDispatch from './useAppDispatch'
import {
  CONTRACT_APPROVED,
  CONTRACT_DENIED,
  CONTRACT_EDITING,
  CONTRACT_REVOKE,
  CONTRACT_WORK,
  CONTRACT_SIGNED,
  CONTRACT_RECEIPT_SENT,
  CONTRACT_DOCUMENTS_SENT,
  CONTRACT_SEND_FORMING,
} from '@common/constants'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import { SelectOption } from '@models'
import { createToast, getModeratorContractInfo } from '@redux/action-types'
import { selectRoleIdProfile } from '@redux/actions/selectors'
import api, { ResponseOk, UpdateComment, UpdateStatus } from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

const useModeratorContractIdInfoForm = (id) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { register, control, setValue } = useForm<{
    moderatorId: SelectOption | undefined
  }>({ mode: 'all' })
  const fields = { moderator: register('moderatorId') }

  const uploadContractModerator = (data) =>
    api.client
      .addContractModerator(data)
      .then(() => dispatch(createToast('Успешно', 'success')))
      .then(() => changeStatus(CONTRACT_DOCUMENTS_SENT))
      .then(() => dispatch(getModeratorContractInfo(String(id))))
      .catch(() => dispatch(createToast(data.message, 'danger')))

  const downloadGeneratedContract = (params) =>
    api.client
      .getGeneratedContract(params)
      .then(({ data }) =>
        base64toFile(
          data,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'docx',
          `договор`
        )
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))

  const downloadGeneratedReceived = (params) =>
    api.client
      .getGeneratedReceived(params)
      .then(({ data }) => {
        return base64toFile(data, 'application/pdf', 'pdf', `квитанция`)
      })
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))

  const getModeratorDoc = (typeDoc, docId, statementId?) =>
    api.client.getModeratorDocs(typeDoc, docId, statementId)
  const inWork = () => changeStatus(CONTRACT_WORK)
  const sendUser = () => changeStatus(CONTRACT_EDITING)
  const revokeApprove = () => changeStatus(CONTRACT_REVOKE)
  const approve = () =>
    api.client
      .addContractTo1C({ id, status: 1 })
      .then(() => changeStatus(CONTRACT_SIGNED))
  const sendTo1C = () =>
    api.client
      .getSendTo1C(Number(id))
      .then(() => changeStatus(CONTRACT_SEND_FORMING))
  const backToWorkContract = () => {
    api.client
      .putBackToWork({ id: Number(id), code: 2 })
      .then(() => changeStatus(CONTRACT_WORK))
  }
  const send = () => changeStatus(CONTRACT_EDITING)
  const denied = () => changeStatus(CONTRACT_DENIED)
  const signed = () => changeStatus(CONTRACT_SIGNED)
  const receiptSent = () => changeStatus(CONTRACT_RECEIPT_SENT)
  const roleId = useAppSelector(selectRoleIdProfile)

  const changeStatus = (code: number) => {
    if (id && roleId) {
      setLoading(true)

      api.client
        .putModeratorStatusContract(roleId, {
          id: Number(id),
          code: code,
          response_person_id: roleId,
        })
        .then((response) => {
          const { data } = response
          dispatch(createToast(data.message, 'success'))
          dispatch(getModeratorContractInfo(id))
        })
        .catch((response: Exclude<UpdateStatus, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const changeModerator = (moderatorId) => {
    if (!id) {
      return
    }

    api.client
      .postNewModeratorContract({
        statementId: id,
        moderatorId: moderatorId,
      })
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
        dispatch(getModeratorContractInfo(id))
      })
      .catch((response: Exclude<UpdateComment, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  const reject = (id) =>
    api.client.deleteContract(id).then(() => {
      dispatch(createToast('Успешно', 'success'))
      navigate('../contracts')
    })

  return {
    control,
    fields,
    reject,
    send,
    approve,
    uploadContractModerator,
    downloadGeneratedContract,
    downloadGeneratedReceived,
    getModeratorDoc,
    inWork,
    sendUser,
    revokeApprove,
    denied,
    signed,
    receiptSent,
    changeModerator,
    sendTo1C,
    backToWorkContract,
    loading,
    setValue,
  }
}

export default useModeratorContractIdInfoForm
