import React, { useEffect } from 'react'

import { TRAJECTORY_INO_ID } from '@common/constants'
import terms from '@common/terms'
import BlankList from '@components/BlankList'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAllowDownloadApplication from '@hooks/useAllowDownloadApplication'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useBlockSendRequest from '@hooks/useBlockSendRequest'
import useBudgetDate from '@hooks/useBudgetDate'
import useCitizenshipResult from '@hooks/useCitizenshipResult'
import useDateBakIno from '@hooks/useDateBakIno'
import useDownloadFilesForm from '@hooks/useDownloadFilesForm'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import { getBlankStatement, getPassportData, createToast } from '@redux/action-types'
import {
  selectBlankStatement,
  selectBlankStatementCheckbox,
  selectPassportData,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const DownloadFilesForm = (scenarioKeys) => {
  const statement = useAppSelector(selectStatementsElement)

  const { fields, formValues, register, remove, append, selectedCheckboxes } =
    useDownloadFilesForm(statement)

  const isAdmin = scenarioKeys.isAdmin ?? false

  const { isAllow, isNotAllow } = useAllowDownloadApplication()

  const dispatch = useAppDispatch()
  const { nationalId } = useCitizenshipResult()

  const checkboxes = useAppSelector(selectBlankStatementCheckbox)
  const blankStatements = useAppSelector(selectBlankStatement)
  const passportData = useAppSelector(selectPassportData)
  const haveQuota = !!passportData?.regNumberEduinru
  const isBakalavr = blankStatements?.trainingLevelName === 'Бакалавр'
  const isMagistr = blankStatements?.trainingLevelName === 'Магистр'

  const scenarios = [
    scenarioKeys.level_training_bachelor.tdId,
    scenarioKeys.level_training_bachelor_ino.tdId,
    scenarioKeys.level_training_bachelor_tashkent.tdId,
  ]
  const isBachelor = scenarios.includes(Number(statement?.scenarioId))

  const requiredCheckboxes = checkboxes.filter((checkbox) =>
    statement?.trajectoryId === TRAJECTORY_INO_ID
      ? checkbox.statementCheckboxId !== 6 &&
        isNotAllow(
          checkbox.statementCheckbox.code.toString(),
          statement?.trajectoryId
        )
      : checkbox.statementCheckboxId !== 6
  )
  const notRequiredCheckboxes = checkboxes.filter((checkbox) =>
    statement?.trajectoryId === TRAJECTORY_INO_ID
      ? checkbox.statementCheckboxId === 6 ||
        isAllow(checkbox.statementCheckbox.code.toString(), statement?.trajectoryId)
      : checkbox.statementCheckboxId === 6
  )

  const { isBlockSendBudgetApp } = useBudgetDate(scenarioKeys)

  const trajectory = useAppSelector(selectTrajectoryValue)

  const { isBlockSendRequest } = useDateBakIno({
    scenarioKeys,
    statementEl: statement,
    trajectory,
  })

  const {
    isBlockSendRequestBakRusPay,
    isBlockSendRequestMagRusPay,
    isBlockSendRequestAsp,
  } = useBlockSendRequest({
    scenarioKeys,
    statement,
  })

  useEffect(() => {
    if (statement) {
      dispatch(getBlankStatement(statement.id))
      dispatch(getPassportData())
    }
  }, [statement])

  useEffect(() => {
    if (checkboxes) {
      remove()
      checkboxes.map((check) => append(check, { shouldFocus: false }))
    }
  }, [checkboxes])

  const { onNextPageNavigate, allFieldsFilled } = useFillingStatementsRoutesContext()
  const { fixDirection } = useReadOnlyFillingStatements()

  if (!(blankStatements && allFieldsFilled)) {
    return (
      <header className="download-files-form">
        <p>{terms.TITLE_BLANK_NULL}</p>
      </header>
    )
  }

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()

        if (isBlockSendRequestAsp) {
          dispatch(createToast('Прием документов завершён', 'danger'))
          return
        }

        if (isBlockSendRequestBakRusPay) {
          dispatch(createToast('Прием документов завершён', 'danger'))
          return
        }

        if (isBlockSendRequestMagRusPay) {
          dispatch(createToast('Прием документов завершён', 'danger'))
          return
        }

        if (isBlockSendRequest) {
          dispatch(createToast('Прием документов завершён', 'danger'))
          return
        }

        if (isAdmin) {
          dispatch(createToast('Невозможное действие за модератора', 'danger'))
        } else {
          const isBlockSendApp = isBlockSendBudgetApp(statement)

          if (isBlockSendApp) {
            dispatch(
              createToast(
                'Прием за счёт бюджетных ассигнований федерального бюджета завершён 25 июля в 18:00 по московскому времени',
                'danger'
              )
            )
            return
          }

          onNextPageNavigate(selectedCheckboxes)
        }
      }}
      className="download-files-form"
    >
      <header className={'download-files-form__header'}>
        <p>{terms.TITLE_BLANK_HEADER}</p>
        <p>{terms.TITLE_BLANK_EL1}</p>
        <p>{terms.TITLE_BLANK_EL2}</p>
        <p>
          {statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId
            ? terms.TITLE_BLANK_EL3
            : terms.TITLE_BLANK_EL3_ANOTHER}
        </p>
        <p>{terms.TITLE_BLANK_EL4}</p>
      </header>
      {blankStatements &&
        requiredCheckboxes?.map((field, index) => (
          <div key={`${field.id}${index}`}>
            <Checkbox
              {...register(`checkbox.${index}.accept` as const, {
                required: true,
              })}
              text={
                nationalId != 1 && index == 1
                  ? field.statementCheckbox.statementCheckboxTranslate[0].nameForIno
                  : field.statementCheckbox.statementCheckboxTranslate[0].name
              }
              disabled={fixDirection}
            />
          </div>
        ))}
      {blankStatements &&
        !isBachelor &&
        notRequiredCheckboxes?.map((field) => (
          <div key={`${field.id}${checkboxes.length - 1}`}>
            <Checkbox
              {...register(`checkbox.${checkboxes.length - 1}.accept` as const, {
                required: false,
              })}
              text={field.statementCheckbox.statementCheckboxTranslate[0].name}
              disabled={fixDirection}
            />
          </div>
        ))}

      <BlankList
        scenarioKeys={scenarioKeys}
        blankStatements={blankStatements}
        flag={
          formValues?.findIndex((el) =>
            statement?.trajectoryId === TRAJECTORY_INO_ID
              ? el.accept === false &&
                el.statementCheckboxId !== 6 &&
                isNotAllow(
                  el.statementCheckbox.code.toString(),
                  statement?.trajectoryId
                )
              : el.accept === false && el.statementCheckboxId !== 6
          ) === -1
        }
      />
      {blankStatements &&
        isBachelor &&
        notRequiredCheckboxes?.map((field) => (
          <div key={`${field.id}${checkboxes.length - 1}`}>
            <Checkbox
              {...register(`checkbox.${checkboxes.length - 1}.accept` as const, {
                required: false,
              })}
              text={field.statementCheckbox.statementCheckboxTranslate[0].name}
              disabled={fixDirection}
            />
          </div>
        ))}
    </FillingStatementsForm>
  )
}

export default DownloadFilesForm
