import React from 'react'
import { useParams } from 'react-router-dom'

import Table from './components/Table'
import { TRAJECTORY_RUSSIA_ID } from '@common/constants'
import useAchivements from '@hooks/useAchivements'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import { showModal } from '@redux/action-types'
import { selectLevelValue, selectTrajectoryValue } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'

const AchivementsForm = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const { statementId } = useParams()

  const onOpenAchivementsModal = () => {
    dispatch(showModal({ name: 'ADD_INDIVIDUAL_ACHIVEMENT', data: { statementId } }))
  }

  const headers = [
    'Тип достижения',
    'Подтверждающий документ',
    'Серия и номер',
    'Дата выдачи',
  ]

  const {
    onDelete,
    onDownloadFile,
    onEdit,
    achivements,
    achivementsAnketa,
    achivementSpecialCondition,
    handleGetAchivements,
    handleAchivementSpecialConditionChange,
  } = useAchivements(String(statementId))

  const { readOnly } = useReadOnlyFillingStatements()
  const scenarioId = useAppSelector(selectLevelValue)
  const trajectoryValue = useAppSelector(selectTrajectoryValue)

  const onChangeSpecialCondition = async (checked: boolean) => {
    await handleAchivementSpecialConditionChange(checked)
    await handleGetAchivements()
  }

  return (
    <>
      <h4 className="achivements__title">Индивидуальные достижения</h4>
      {(scenarioId.id === scenarioKeys.level_training_bachelor_ino.tdId ||
        scenarioId.id === scenarioKeys.level_training_bachelor.tdId) && (
        <div className={'achievements__subtitle'}>
          <a
            href={
              'https://www.herzen.spb.ru/abiturients/informatsiya-o-prieme/postuplenie-dlya-grazhdan-rf/bakalavriat/perechen-individualnykh-dostizheniy/'
            }
            target={'_blank'}
          >
            «Индивидуальные достижения»
          </a>
        </div>
      )}

      {(scenarioId.id === scenarioKeys.magistra_rf.tdId ||
        scenarioId.id === scenarioKeys.magistra_ino.tdId) && (
        <div className={'achievements__subtitle'}>
          <a
            href={
              'https://www.herzen.spb.ru/abiturients/informatsiya-o-prieme/postuplenie-dlya-grazhdan-rf/magistratura/perechen-individualnykh-dostizheniy/'
            }
            target={'_blank'}
          >
            «Индивидуальные достижения»
          </a>
        </div>
      )}

      <Button
        type={'button'}
        className="achivements__button"
        onClick={onOpenAchivementsModal}
        disabled={readOnly}
      >
        Добавить
      </Button>
      <table className="achivements-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>

        {achivementsAnketa.length > 0 && (
          <Table
            items={achivementsAnketa}
            onDownload={onDownloadFile}
            readOnly={readOnly}
          />
        )}
        {achivements.length > 0 && (
          <Table
            items={achivements}
            onDelete={(docId) =>
              onDelete({ statementId: Number(statementId), docId })
            }
            onDownload={onDownloadFile}
            onEdit={onEdit}
            readOnly={readOnly}
          />
        )}
      </table>

      {trajectoryValue?.id === TRAJECTORY_RUSSIA_ID &&
        scenarioId.id === scenarioKeys.level_training_spo.tdId &&
        achivementSpecialCondition && (
          <div className="achivements__checkbox">
            <Checkbox
              disabled={readOnly}
              text='Имею право на зачисление в образовательную организацию на обучение по образовательным программам среднего профессионального образования в первоочередном порядке согласно пунктам 1 - 13 части 7 статьи 71 Федерального закона "Об образовании в Российской Федерации"'
              checked={achivementSpecialCondition.haveRight113771Law}
              onChange={(e) => onChangeSpecialCondition(e.target.checked)}
            />

            {achivementSpecialCondition.haveRight113771Law && (
              <b>
                Вам необходимо загрузить подтверждающий документ в раздел
                "Дополнительные файлы" и уведомить об этом модератора, написав в
                чате.
              </b>
            )}
          </div>
        )}
    </>
  )
}

export default AchivementsForm
