import { useMemo } from 'react'

import { appWithAdditionalConditionsCode } from '../common/constants'
import type { ViewerDirectionsListStatements } from '../models'

const useAppWithAdditionalConditions = (item: ViewerDirectionsListStatements) => {
  const isAppWithAdditionalConditions = useMemo<boolean>(() => {
    return appWithAdditionalConditionsCode.includes(item.code)
  }, [item])

  return { isAppWithAdditionalConditions }
}

export default useAppWithAdditionalConditions
