const uploadDocsTypes = Object.freeze({
  LGOTA: 'lgota', // Документ подтверждающий льготу
  LGOTA_SPECIAL: 'lgota_special_conditions', // Документ специалных условий в льготах
  LGOTA_REASON: 'lgota_reason_conditions', // Справка об инвалидности в льготах
  PRESIDENT_DECREE: 'presidential_decree_268', // Льгота для военных указ президента
  PRESIDENT_DECREE_B: 'presidential_decree_268_b', // Льгота для военных указ президента
  CONTRACT_SCAN_MODERATOR: 'receipt_payment_contract', // Скан договора загружаемый модератором
  VNZ: 'document_vnz', // Документ о ВНЖ в РФ
  RVP: 'document_rvp', // Документ о РВП в РФ
  OLYMPIAD_CONFIRMING: 'confirming_the_olympiad', // Документ об участии в олимпиадах
  OLYMPIAD: 'individual_achievements', // Документ об индивидуальных достижениях
  COPY_CERTIFICATE: 'copy_certificate',
  ACHIVMENT: 'confirming_the_olympiad', // Документ об участии в олимпиадах
  PRIVILEGES: 'privileges', // Документ о наличии привилегий при поступлении
  SCAN_CONTRACT: 'scan_for_contract', // Скан подписанного контракта в договорах
  SCAN_CONTRACT_MODERATOR: 'scan_for_contract_moderator', // Скан подписанного контракта в договорах
  PASSPORT: 'copy_passport', // Копия паспорта
  QUOTA_MINOBR: 'quota_minobr', // Направление Минобрнауки
  TARGET_CONTRACT: 'target_contract', // Направление Минобрнауки
  ADDITIONAL_FILES: 'dop_files', // Дополнительные файлы
  EDUCATION: 'education_document', // Документ об образовании
  SIGNED_CONTRACT: 'signed_contract', // Подписанный договор
  SNILS: 'snils', // Копия снилс
  PREEMPTIVE_RIGHT: 'preemptive_right', // Документ дающий право преимущественного поступления
  TARGET_AGREEMENT: 'target_agreement', // Согласие на обработку персональных данных от законного представителя
  INDIVIDUAL_ACHIEVEMENTS: 'individual_achievements', // Документ о наличии индивидуальных наградах
  PAYERS_PASSPORT: 'payers_passport_under_the_contract', // Копия паспорта плательщика по договору
  COPY_PASSPORT_FOR_CONTRACT: 'copy_passport_for_dogovor', //  Копия паспорта плательщика по договору
  BIRTH_CERTIFICATE: 'copy_of_the_applicants_birth_certificate', // Копия свидетельства о рождении абитуриента
  COMPATRIOT_APPLICATION: 'application_for_recognition_of_compatriot_status', // Заявление о признании статуса соотечественника
  BIRTH_CERTIFICATES_OF_RELATIVES: 'copies_of_birth_certificates_of_next_of_kin	', // Копии свидетельств о рождении, подтверждающие принадлежность к статусу соотечественника ближайших родственников
  SUPPORTING_DOCUMENT: 'graduate_psychological_and_pedagogical_class', // Документ, который подтверждает то что человек является выпускником психолого-педагогического класса,
  SCAN_TRANSLATION_DOC_EDY: 'scan_translation_doc_edu', // Скан перевода документа об образовании
  NOSTRIFICATION_EDU: 'nostrification_edu', //Свидетельство о признании документа об образовании
  PREVIOUS_DOCUMENTS: 'previous_documents', // Страница паспорта со сведениями о ранее выданных паспортах». Прикрепление файла обязательно
  CONFIRM_RESULT_EGE: 'confirming_result_ege', //Документ с подтверждением результатов цт/цэ
  EDUCATION_DOCUMENT: 'scan_translation_doc_passport', // Скан нотариально заверенного перевода паспорта (страница 2-3)
  VISA_APPLICATION_FORM_INVITATION: 'visa_application_form_invitation', // Анкета для приглашения (ВИЗА)
  REFERENCE_MSE: 'reference_MSE', // Справка МСЭ
  SPECIAL_CONDITIONS: 'special_conditions', // Справка МСЭ
  CONCLUSION_FROM_BASE_DEPARTMENT: 'conclusion_from_base_department',
  OTHER: 'other', //другое
  CHANGES_SURNAME: 'change_surname', //свидетельство о смене имени
  RECEIPT_PAYMENT_CONTRACT: 'receipt_payment_contract', // загрузка квитанции модератором payment_contract
  RECEIPT_PAID_RECEIPT: 'receipt_paid_contract', //загрузка квитанции абитуриентом
  CONSENT_TO_ENROLLMENT: 'consent_to_enrollment', //согласие на зачисление
  OTHER_DOCUMENTS: 'other_document', //загрузка других документов об образовании
} as const)

export type UploadDocKey = keyof typeof uploadDocsTypes
export type UploadDocType = typeof uploadDocsTypes[UploadDocKey]

export default uploadDocsTypes
