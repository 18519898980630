import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import {
  CONTRACT_REVOKE,
  CONTRACT_WORK,
  STATUS_REVOKE,
  STATUS_WORK,
} from '@common/constants'
import { mimeTypes } from '@common/manual'
import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
// @ts-ignore
import useDirectionTrainingModeration from '@hooks/useDirectionTraningModeration'
import useDownloadDocument from '@hooks/useDownloadDocument'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { SelectOption } from '@models'
import { getConsentToEnrollmentHistory } from '@redux/action-types'
import {
  selectStatementsElement,
  selectUserConsentToEnrollmentHistory,
} from '@redux/actions/selectors'
import api from '@services/api'
import Button from '@ui/Button'
import Select from '@ui/Select'

const DirectionTrainings = () => {
  const statement = useAppSelector(selectStatementsElement)
  const { control, options, watch } = useDirectionTrainingModeration({
    statement: statement,
  })
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const { downloadDocument } = useDownloadDocument()
  const dispatch = useAppDispatch()
  const history = useAppSelector(selectUserConsentToEnrollmentHistory)
  const [showSelector, setShowSelector] = useState<Boolean>(false)

  useEffect(() => {
    if (statement) {
      dispatch(getConsentToEnrollmentHistory({ id: statement.id }))
      api.client.sendEnroll(statement.id).then((response) => {
        setShowSelector(!response.data.status)
      })
    } else {
      let id = window.location.pathname.split('/')[2]
      dispatch(getConsentToEnrollmentHistory({ id: id }))

      api.client.sendEnroll(id).then((response) => {
        setShowSelector(!response.data.status)
      })
    }
  }, [])

  function change() {
    setShowSelector(true)
  }

  function send() {
    if (!watch('optionId')) {
      return
    }

    api.client
      .sendConsentToEnrollment({
        directionTraining: watch('optionId.id'),
        statement: statement?.id ?? 1,
      })
      .then(() => {
        if (statement) {
          dispatch(getConsentToEnrollmentHistory({ id: statement.id }))
          api.client.sendEnroll(statement.id).then((response) => {
            setShowSelector(!response.data.status)
          })
        }
      })
  }

  function downloadDoc(userDocID: number, userDocName: string) {
    downloadDocument(
      'CONSENT_TO_ENROLLMENT',
      mimeTypes.docx,
      'docx',
      userDocID,
      userDocName
    )
  }

  function revoke(item) {
    api.client
      .revokeConsentToEnrollment({
        directionTraining: item.competitiveGroupId,
        statement: statement?.id ?? 1,
        id: item.id,
      })
      .then(() => {
        if (statement) {
          dispatch(getConsentToEnrollmentHistory({ id: statement.id }))
          api.client.sendEnroll(statement.id).then((response) => {
            setShowSelector(!response.data.status)
          })
        }
      })
  }

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      <p>
        Подача (отзыв) согласия на зачисление.
        <br />
        <br />
        Чтобы первично подать согласие на зачисление необходимо нажать на кнопку
        «Подать согласие», далее необходимо выбрать направление из выпадающего
        списка, на которое Вы планируете подать согласие на зачисление.
      </p>
      <br />
      <p>
        Чтобы отозвать согласие на зачисление и подать новое, необходимо сделать
        следующее: сначала необходимо отозвать старое согласие путем нажатия на
        кнопку «Отозвать согласие» , далее нажать на кнопку «Подать согласие» и
        выбрать то направление, на которое Вы хотите подать согласие на зачисление.
      </p>
      <br />
      <p>
        Если Вы хотите отозвать согласие на зачисление и не подавать новое, то
        необходимо просто нажать на кнопку «Отозвать согласие».
      </p>

      {!showSelector && !history[0] ? (
        <Button
          type={'button'}
          style={{ margin: '20px 0' }}
          theme={'success'}
          onClick={change}
        >
          Подать согласие
        </Button>
      ) : (
        ''
      )}

      {showSelector && (
        <div>
          <div style={{ margin: '20px 0' }}>
            <Controller
              name="optionId"
              control={control}
              render={({ field }) => (
                <Select<SelectOption>
                  className="directions-training-search__select directions-training-search__select--full-width"
                  {...(field as unknown)}
                  options={options}
                  placeholder={'Научная специальность'}
                  markRequired={true}
                  isDisabled={statement?.statusStatement.id === STATUS_WORK}
                />
              )}
            />
          </div>

          <Button
            disabled={statement?.statusStatement.id === STATUS_WORK}
            style={{ margin: '20px 0' }}
            theme={'success'}
            onClick={send}
            type={'button'}
          >
            Подтвердить согласие на зачисление
          </Button>
        </div>
      )}

      {history.map((item) => {
        return (
          <div key={item.id} className={'direction-training-card__list'}>
            <div className={'direction-training-card'}>
              <h4>Согласие на зачисление</h4>

              <p>
                Научная специальность:
                {item.directionTraining.translations[0].name}
              </p>
              <p>
                Дата формирования: {item.createdAt.substr(8, 2)}.
                {item.createdAt.substr(5, 2)}.{item.createdAt.substr(0, 4)}
              </p>

              <p className={'direction-training-card__time'}>
                Форма оплаты:{' '}
                {item.competitiveGroup
                  ? item.competitiveGroup.formPayment.translate[0].name
                  : ''}
              </p>

              <div className={'direction-training-card__download-button'}>
                <Button
                  disabled={
                    item.blank.blankTranslate[0].name ===
                    'Отзыв согласия на зачисление'
                  }
                  onClick={() => downloadDoc(item.userDoc.id, item.userDoc.name)}
                  type={'button'}
                >
                  Скачать документ
                </Button>
                <p>Скачайте, проверьте данные</p>
              </div>

              <Button
                className={'direction-training-card__revoke'}
                onClick={() => revoke(item)}
                disabled={
                  item.blank.blankTranslate[0].name ===
                  'Отзыв согласия на зачисление'
                }
                type={'button'}
              >
                {item.blank.blankTranslate[0].name === 'Отзыв согласия на зачисление'
                  ? 'Отозвано'
                  : 'Отозвать согласие'}
              </Button>
            </div>
          </div>
        )
      })}
    </FillingStatementsForm>
  )
}

export default DirectionTrainings
