import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useConclusionBaseDepartment from '@hooks/useConclusionBaseDepartment'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { createToast } from '@redux/action-types'
import api, { ResponseOk } from '@services/api'
import FileUploader from '@ui/FileUploader'

const ConclusionBaseDepartment = () => {
  const dispatch = useAppDispatch()
  const { statementId } = useParams()
  const { conclusionBaseDepartment } = useConclusionBaseDepartment()
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const { onChange, onDelete, onDrop, docs, onUploadDocs, getUploadedDoc, setDocs } =
    useUploadDocuments(
      'CONCLUSION_FROM_BASE_DEPARTMENT',
      terms.CONCLUSION_BASE_DEPARTMENT
    )

  const uploadDoc = (docs) => {
    if (!docs) return

    const formData = new FormData()
    formData.append('typeDoc', 'conclusion_from_base_department')
    formData.append('statement_id', `${statementId}`)
    for (const doc of docs) {
      formData.append('documents[]', doc)
    }

    api.client
      .postConclusionBaseDepartmentDocs(formData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))

        setDocs(docs)
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const deleteDoc = () => {
    api.client
      .deleteConclusionBaseDepartmentDoc({
        statementId: Number(statementId),
        docId: conclusionBaseDepartment?.status?.id,
      })
      .finally(() => {
        setDocs([])
      })
  }

  useEffect(() => {
    if (conclusionBaseDepartment?.status?.id) {
      getUploadedDoc(conclusionBaseDepartment?.status?.id)
    }
  }, [conclusionBaseDepartment])

  return (
    <FillingStatementsForm
      className="entr-exams__form"
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      <p className={'mb-24'}>
        Загрузите заключение базовой кафедры в поле ниже. Данное поле обязательное
        для заполнения.
        <br />
        <br />
        Для получения заключения базовой кафедры обратитесь{' '}
        <a
          target={'_blank'}
          style={{ color: '#3a79d7' }}
          href={
            'https://herzen.spb.ru/about/struct-uni/contr/upravlenie-organizatsii-dissertatsionnykh-issledovaniy/otdel-aspirantury/abiturientam/perechen-spetsialnostey/'
          }
        >
          по указанным контактам.
        </a>
      </p>

      <ProfileSection
        title={'Заключение базовой кафедры'}
        markRequired
        fileUploadingHint
      >
        <FileUploader
          files={docs}
          onDrop={(file) => uploadDoc(file)}
          onChange={(file) => uploadDoc(file)}
          onDelete={deleteDoc}
          className="profile-form__file-uploader"
        />
      </ProfileSection>
    </FillingStatementsForm>
  )
}

export default ConclusionBaseDepartment
