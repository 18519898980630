import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import StatementsListItem from './StatementsListItem'
import {
  ASPERATNUTA_RF,
  BACHELOR_INO,
  BACHELOR_RF,
  BACHELOR_TASHKENT,
  MAGISTRA_INO,
  MAGISTRA_RF,
  MAGISTRA_TASHKENT,
  QUOTA_ID,
  SPO_ID,
  TRAINING_PREPARATION,
} from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useBudgetDate from '@hooks/useBudgetDate'
import useStatementsList from '@hooks/useStatementsList'
import { ViewerElementStatements } from '@models'
import { getScenarioKeys } from '@redux/action-types'
import { selectScenarioKeysOptions } from '@redux/actions/selectors'

type CreatePageStatementsListProps = {
  list: ViewerElementStatements[]
}

const StatementsList = ({ list }: CreatePageStatementsListProps, scenarioKeys) => {
  const navigate = useNavigate()
  const { fixStatements, deleteStatements, revoke } = useStatementsList()
  const goStatements = (id: number) => {
    navigate(`../${id}/filling-statements`)
  }
  if (!scenarioKeys) {
    useEffect(() => {
      dispatch(getScenarioKeys('Scenario'))
    }, [])
  }
  let scenario_Keys
  const sKeys = useAppSelector(selectScenarioKeysOptions)
  if (sKeys) {
    scenario_Keys = {
      level_training_spo: sKeys.find((value) => value.uniqueKeyOnSite == SPO_ID),
      level_training_preparation: sKeys.find(
        (value) => value.uniqueKeyOnSite == TRAINING_PREPARATION
      ),
      level_training_bachelor: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_RF
      ),
      level_training_bachelor_ino: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_INO
      ),
      level_training_bachelor_tashkent: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_TASHKENT
      ),
      magistra_tashkent: sKeys.find(
        (value) => value.uniqueKeyOnSite == MAGISTRA_TASHKENT
      ),
      magistra_rf: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_RF),
      magistra_ino: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_INO),
      asperatnuta_rf: sKeys.find((value) => value.uniqueKeyOnSite == ASPERATNUTA_RF),
      quota: sKeys.find((value) => value.uniqueKeyOnSite == QUOTA_ID),
    }
  }
  scenarioKeys = scenario_Keys

  const goSendStatements = (id: number) => {
    navigate(`../${id}/filling-statements/blank`)
  }
  const dispatch = useAppDispatch()

  const sortList: ViewerElementStatements[] = list
    .filter((el) => el.statusStatement !== null)
    .sort((a, b) => a.id - b.id)

  const currentDate = new Date()
  const endDate1 = new Date('2024-08-20T23:59:00+03:00')
  const endDate2 = new Date('2024-08-21T23:59:00+03:00')

  const isHiddenWithdrawApplicationButton = (statement: any) => {
    if (
      (statement.scenarioId === scenarioKeys.level_training_bachelor.tdId ||
        statement.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId) &&
      currentDate > endDate1
    ) {
      return false
    }

    if (
      statement.scenario.code === scenarioKeys.magistra_rf.onesKey &&
      currentDate > endDate2
    ) {
      return false
    }

    return true
  }

  const { isBlockSendBudgetApp } = useBudgetDate(scenarioKeys)

  return (
    <section className={'statements-container'}>
      {sortList.map((el: ViewerElementStatements, index) => (
        <StatementsListItem
          key={el.id}
          index={index}
          deleteStatements={deleteStatements}
          el={el}
          fixStatements={fixStatements}
          goSendStatements={goSendStatements}
          goStatements={goStatements}
          isBlockSendBudgetApp={isBlockSendBudgetApp}
          isHiddenWithdrawApplicationButton={isHiddenWithdrawApplicationButton}
          revoke={revoke}
          scenarioKeys={scenarioKeys}
        />
      ))}
    </section>
  )
}

export default StatementsList
