import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAdditionalInfoForm from '@hooks/useAdditionalInfoForm'
import { SelectOption } from '@models'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AdditionalInformationForm = () => {
  const {
    submitAdditionInfo,
    learningOpportunities,
    proficiencyLevel,
    whereStudy,
    personalDataAdditionalInfoDefault,
    formState: { errors },
    watch,
    control,
    getSelectorData,
    fields,
  } = useAdditionalInfoForm()

  let additionalInfoTrainingOpportunityId = watch('training_opportunity_id')?.value
  let additionalInfoTrainingOpportunityIsOther =
    additionalInfoTrainingOpportunityId === 7

  let additionalInfoWhereStudyId = watch('study_russian_id')?.value
  let additionalInfoWhereStudyIsOther = additionalInfoWhereStudyId === 2

  const [isLoading, setLoading] = useState(true)

  if (isLoading) {
    getSelectorData()
    setLoading(false)
  }

  return (
    <ProfileForm
      onSubmit={(e) => {
        e.preventDefault()
        submitAdditionInfo(e)
      }}
    >
      <ProfileSection>
        <Controller
          name="training_opportunity_id"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              className="profile-form__field"
              {...(field as unknown)}
              options={learningOpportunities}
              placeholder={terms.HOW_DID_YOU_FIND_OUT_ABOUT_TRAINING_OPPORTUNITY}
              markRequired={true}
              error={errors.training_opportunity_id?.message}
            />
          )}
        />

        {additionalInfoTrainingOpportunityIsOther && (
          <div className="profile-form__field">
            <Input
              placeholder={terms.HOW_DID_YOU_FIND_OUT_ABOUT_TRAINING_OPPORTUNITY}
              error={errors.other_training_opportunity?.message}
              type="text"
              markRequired={true}
              {...fields.other_training_opportunity}
            />
          </div>
        )}

        <Controller
          name="lvl_rus_lang_id"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              className="profile-form__field"
              {...(field as unknown)}
              error={errors.lvl_rus_lang_id?.message}
              options={proficiencyLevel}
              placeholder={terms.EVALUATE_LEVEL_RUSSIAN_LANGUAGE_PROFICIENCY}
              markRequired={true}
            />
          )}
        />

        <Controller
          name="study_russian_id"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              className="profile-form__field"
              error={errors.study_russian_id?.message}
              {...(field as unknown)}
              options={whereStudy}
              placeholder={terms.WHERE_YOU_STUDY_RUSSIAN}
              markRequired={true}
            />
          )}
        />

        {additionalInfoWhereStudyIsOther && (
          <div className="profile-form__field">
            <Input
              placeholder={terms.WHERE_YOU_STUDY_RUSSIAN}
              error={errors.other_study_russian?.message}
              type="text"
              markRequired={true}
              {...fields.other_study_russian}
            />
          </div>
        )}
      </ProfileSection>
    </ProfileForm>
  )
}

export default AdditionalInformationForm
