import {
  BLOCK_SEND_BUDGET_APPS_DATE,
  PAYMENT_TYPE_BUDGET_ID,
} from '../common/constants'
import type { ViewerElementStatements } from '../models'

/**
 * Хук для работы с блокировкой подачи заявления, в зависимости от разных условий
 */
const useBudgetDate = (scenarioKeys: any) => {
  /** Разрешенные сценарии для блокировки подачи заявления на бюджет */
  const allowScenarioForBudgetIDs: number[] = [
    scenarioKeys.level_training_bachelor.tdId,
    scenarioKeys.magistra_rf.tdId,
  ]

  /**
   * Текущая дата >= указанной дате, при которой надо блокировать подачу заявления
   * или нет
   */
  const checkTrueDateForBlockSendAppBudget = (): boolean => {
    const currentTime = new Date()
    const blockSendAppDate = new Date(BLOCK_SEND_BUDGET_APPS_DATE)
    return currentTime >= blockSendAppDate
  }

  /**
   * Проверка, что выбран тип оплаты - бюджет
   */
  const checkIsBudget = (paymentType: number): boolean => {
    return paymentType === PAYMENT_TYPE_BUDGET_ID
  }

  /**
   * Проверка, что выбранный сценарий подходит под тот, что надо блокировать
   */
  const checkAllowScenarioForBudget = (scenarioId: number): boolean => {
    return allowScenarioForBudgetIDs.includes(scenarioId)
  }

  /**
   * Заблокировать отправку заявления, в зависимости от даты, типа оплаты и сценария
   * или нет
   */
  const isBlockSendBudgetApp = (
    statement: ViewerElementStatements | null
  ): boolean => {
    if (!statement) {
      return false
    }

    const isTrueDateForBlockSendAppBudget = checkTrueDateForBlockSendAppBudget()
    const isBudget = checkIsBudget(statement.paymentType)
    const isTrueScenario = checkAllowScenarioForBudget(statement.scenarioId)

    // Если:
    // 1. Бюджет
    // 2. Сценарий: Магистратура РФ или Бакалавр/Специалитет РФ
    // 3. Текущая дата больше даты конца приема документов
    if (isBudget && isTrueScenario && isTrueDateForBlockSendAppBudget) {
      return true
    }

    return false
  }

  return { isBlockSendBudgetApp }
}

export default useBudgetDate
