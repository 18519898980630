const en = {
  // Electronic Admission Office
  SPBPU_ONLINE_APPLICATION_SYSTEM:
    'Electronic Admission Office of Herzen State Pedagogical University',
  SPBPU_ONLINE_APPLICATION_SYSTEM_ASP:
    'Electronic Admission Office of Postgraduate Studies',
  SPBPU_ONLINE_APPLICATION_SYSTEM_INO:
    'Electronic Admission Office for Foreign Students',
  REVIEW_INFORMATION_ABOUT_ADMISSION:
    'To apply to the university, carefully read the materials on the admissions office website and then',
  COMPLETE_REGISTRATION_PROCEDURE: 'go through the registration procedure',
  USER_MANUAL_FOR_RUSSIAN_APPLICANTS:
    'Instructions for creating Personal Account for Russian applicants and compatriots',
  USER_MANUAL_FOR_FOREIGNERS:
    'Instructions for creating Personal Account for foreign applicants',
  USER_MANUAL_FOR_APPLICANTS: 'Information for applicants',
  USER_MANUAL: 'User manual',
  USER_VIDEO_INSTRUCTION_RUSSIAN:
    'Video instruction on working with the Personal Account in Russian',
  USER_VIDEO_INSTRUCTION_CHINA:
    'Video instruction on working with the Personal Account in Chinese',
  IF_ALREADY_HAVE_ACCOUNT:
    'If you are already registered in the system, use your registered email address and password to log in to your Personal Account',
  IN_TO_CABINET: "LOGIN TO THE APPLICANT'S PERSONAL ACCOUNT",
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot password?',
  REGISTER: 'Register',
  CAPTCHA: 'Enter the characters in the image',
  LOG_IN: 'Log in',

  // Registration
  REGISTRATION: 'Registration',
  BASIC_DATA: 'Basic data',
  SURNAME_IN_RUSSIAN: 'Surname',
  CYRILLIC_CHARACTERS_ONLY: 'Cyrillic characters only',
  LATIN_CYRILLIC_CHARACTERS_AND_NUMBER_ONLY:
    'Only Cyrillic and Latin letters and numbers',
  SURNAME_IN_LATIN: 'Surname in Latin letters',
  LATIN_CHARACTERS_ONLY: 'Latin characters only',
  NAME_IN_RUSSIAN: 'Given name',
  NAME_IN_LATIN: 'Given name in Latin letters',
  PATRONYMC_IN_LATIN: 'Patronymic (if any) in Latin letters',
  PATRONYMIC_IN_RUSSIAN: 'Patronymic (if any)',
  DATE_OF_BIRTH: 'Date of birth',
  JOB: 'Job',
  CHILDREN_AMOUNT: 'Number of children',
  CITIZENSHIP: 'Citizenship',
  AUTHORIZATION: 'Authorization data',
  CREATE_PASSWORD: 'Create password',
  PASS_HINT:
    'Password must be at least 6 characters long, include lowercase and uppercase Latin letters, numbers and special characters',
  CONSENT_TO_DATA_PROCESSING:
    'By clicking the "Register" button, you give your consent to the processing of the entered personal information in accordance with Federal Law No. 152-FZ of 27.07.2006 "On Personal Data"',
  BACK: 'Back',
  EMAIL_CONFIRMED: 'Email confirmed',
  ACCOUNT_CREATED_AND_CONFIRMED:
    "Account has been successfully created and confirmed. You can now log in to the applicant's Personal Account",
  PASSWORD_RESET: 'Reset password',
  ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET:
    'Enter your email you used for registration and we will send you instructions to reset your password',
  RESET: 'Reset',
  IN_RUSSIA: 'In Russia',
  YOUR_ID_IN_SYSTEM: 'Your ID in the system',
  CONTACT_TECHNICAL_SUPPORT: 'Contact Technical Support',
  NO_NATIONALITY: 'No citizenship',

  // Personal information
  PERSONAL_INFORMATION: 'Personal information',
  APPLICATION: 'Application for admission',
  ADDITIONAL_FILES: 'Additional files',
  LIST_OF_APPLICANTS: 'List of applicants',
  BASIC_DATA_HINT: 'Fill in according to passport or other identification document',
  COMPATRIOT_DOCUMENTS: 'Documents confirming compatriot status',
  COMPATRIOT_DOCUMENTS_HINT:
    'Fill in the self-identification application and upload supporting documents',
  INFORMATION_ABOUT_PARENTS: 'Legal representative',
  INFORMATION_ABOUT_PARENTS_HINT: 'Fill in the details of your parent or guardian',
  PASSPORT_DATA: 'Passport details',
  PASSPORT_DATA_HINT: 'Fill in as in your passport',
  VISA: 'Visa',
  VISA_HINT:
    'If you require a visa, your passport must be valid for at least 18 months',
  ADDITIONAL_INFORMATION: 'Additional information',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS:
    'Registration address (as in passport) and current residential address',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_FOREIGN:
    'Address of residence in the country of citizenship',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT:
    'Enter passport registration address and current residence address',
  EDUCATIONIONAL_BACKGROUND: 'Educational background',
  EDUCATIONIONAL_BACKGROUND_HINT:
    'Upload your education document with its attachment (grades) in one file.',
  SAVE_AND_CONTINUE: 'Save and continue',
  DIPLOMA_WITH_HONOR: 'Diploma with distinction',
  CREATED: 'Created',
  INVALID_VISA_DATE: 'Date not earlier than 20.08.2023',

  // "Basic Data" section
  PHOTO: 'Photo',
  CHANGE_PASSWORD: 'Change password',
  SURNAME: 'Surname',
  NAME: 'Given name',
  PATRONYMIC: 'Patronymic',
  NO_PATRONYMIC: 'No patronymic',
  GENDER: 'Gender',
  MOBILE_PHONE: 'Mobile phone',
  ADDITIONAL_MOBILE_PHONE_POSTSCRIPT: 'Additional phone number (if available)',
  ADDITIONAL_MOBILE_PHONE: 'Additional phone number',
  ADDITIONAL_EMAIL_IF_ANY: 'Additional email (if available)',
  RECRUITING_COMPANY: 'Partner organisation',
  PARTNER_ORGANIZATION_EMAIL: 'Partner organisation email',
  PARTNER_ORGANIZATION_PHONE: 'Partner organisation phone',
  SNILS: 'SNILS',
  NO_SNILS: 'No SNILS',
  NO_DATE_SNILS: 'No date SNILS',
  DATE_OF_ISSUE_SNILS: 'Date of SNILS issue',
  COUNTRY_CODE: 'Country code',

  // "Change Password" window
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  SAVE: 'Save',

  // Updating directories
  EXCHANGE_LINKS_TITLE: 'Update selected directories via 1C',
  EXCHANGE_PERSONAL_TITLE: 'Update directories for filling out user data',
  EXCHANGE_HUGE_DATA_TITLE: 'Update large directories',
  EXCHANGE_GENERAL_LINKS_TITLE: 'Complete update',
  EXCHANGE_UPDATE_MODAL_TITLE: 'Update from 1С',
  EXCHANGE_UPDATE_YEAR: 'Updated year',
  EXCHANGE_DELETE_OLD: 'Delete old entries before upload?',
  EXCHANGE_ACCEPT: 'Update',
  EXCHANGE_MESSAGE: '1C data exchange in progress',

  // "Edit Image" window
  // and editing authorisation pages tab
  LOGIN_REGISTER_PAGES: 'Authorisation pages',
  AUTH_SETTING_TITLE: 'Editing login page',
  LOGIN_PAGE: 'Login page',
  LOGIN_PAGE_NAME: 'Login page name in admin',
  LOGIN_PAGE_TITLE: 'Login page title',
  REGISTER_PAGE_TITLE: 'Register page title',
  SELECT_IMAGE: 'Select image',
  SELECT_LOGO: 'Select logo',
  SELECT_ANOTHER_IMAGE: 'Select another image',
  SIGN_IMAGE: 'Sign-up image',
  LEFT_CELL_TITLE: 'Left cell title',
  LEFT_CELL_TEXT: 'Left cell text',
  TEXT_ON_IMAGE: 'Text on image',
  LINK_ON_IMAGE: 'Link on image',
  URL_LINK_ON_IMAGE: 'Link on image /rus-sign-up',
  SITE_AUTH_LOGO: 'Website logo',
  SITE_AUTH_LOGO_TEXT: 'Text to the right from logo',
  RIGHT_CELL_TITLE: 'Right cell title',
  RIGHT_CELL_TEXT: 'Right cell text',
  EDIT_SIGN_IMAGE: 'Edit image',
  SUCCESS_IMAGE_DOWNLOAD: 'Image successfully updated',
  IMAGE_REQUIREMENTS: 'Best 2:1 aspect ratio',
  LOGO_REQUIREMENTS_RATIO: 'Best 1:1 aspect ratio',

  // "Edit Photo" window
  SELECT_PHOTO: 'Select photo',
  SELECT_ANOTHER_PHOTO: 'Select another photo',
  PHOTO_REQUIREMENTS: 'Follow the requirements for the photo you are uploading:',
  IMAGE_REQUIREMENTS_TEXT:
    'Follow the requirements for the image you are uploading:',
  LOGO_REQUIREMENTS: 'Follow the requirements for the logo you are uploading:',
  PHOTO_REQUIREMENTS_BG:
    'light (white or light gray) background without drawings or text',
  PHOTO_REQUIREMENTS_FACE: 'the face should cover about 70% of the photo area',
  PHOTO_REQUIREMENTS_WIDTH: 'the width of the photo not less than 300 pixels',
  PHOTO_REQUIREMENTS_HEIGHT:
    'the height of the photo not less than 400 pixels, resolution of the photo not less than',
  PHOTO_REQUIREMENTS_PX: '300 dpi',
  PHOTO_REQUIREMENTS_SIZE: 'file size not more than 1 Mb',
  PHOTO_REQUIREMENTS_EXTENSION: 'file types: .jpg, .jpeg and .png',
  SITES_IMAGE_REQUIREMENTS_EXTENSION: 'file types: .jpg and .png',
  PHOTO_REQUIREMENTS_CARD: '',
  PROFILE_PHOTO_UPDATED: 'Profile photo updated',
  AUTH_PHOTO_UPDATED: 'Registration page photo updated',
  EDIT_PHOTO: 'Edit photo',

  // "Documents confirming compatriot's status" section
  FILE_UPLOADER_TITLE:
    'Press the image or move the files here for uploading. \n' +
    'You can upload JPG, JPEG images (they will be combined in on\n' +
    'PDF) or one PDF document. The file size not more than 5 Mb',
  FILE_UPLOADER_DESCRIPTION:
    'You can upload JPG images (they will be combined in one PDF) or one PDF document',

  // "Legal representative" section
  RELATIONSHIP: 'Degree of relationship',
  PASSPORT_DATA_NOT_NECESSARY: 'Passport details (optional)',
  DOCUMENT_TYPE: 'Type of document',
  SERIES: 'Series',
  HINT_SERIES_FOREIGN:
    "If the passport has no series and the number consists of letters and numbers, enter letters into the 'series' field and numbers into the 'number' field.",
  NUMBER: 'Number',
  DIVISION_CODE: 'Division code',
  DATE_OF_ISSUE: 'Date of issue',
  ISSUING_AUTHORITY: 'Issued by',
  TRIAL_TYPE: 'Exam type',
  PRIORITY_GROUP: 'Priority area of study',
  ITEM_TRIAL: 'Subject',
  PERMANENT_ADDRESS_NOT_NECESSARY: 'CurrentHome address (optional)',
  COUNTRY: 'Country',
  REGION: 'Region',
  DISTRICT: 'District',
  CITY: 'City',
  STREET: 'Street',
  NO_STREET: 'No street',
  BUILDING: 'Building number',
  SITE: 'Block',
  Apartment: 'Apartment',
  ZIP_CODE: 'Zip code',
  PERMANENT_ADDRESS_AND_HOME_ADDRESS_THE_SAME:
    'Permanent address and current address are the same',
  CONSENT_OF_PERSONAL_DATA_PROCESSING:
    'Consent for personal data processing from the legal representative',
  NAME_AUTHORITY_ISSUED_IDENTITY_DOCUMENT_MISSING:
    'The name of the authority that issued the identity document is missing.',

  // "Passport details" section
  TRANSLATION_OF_THE_IDENTITY_DOCUMENT:
    'Specify strictly according to the translation of the document of identification (passport or other government-issued ID).',
  DATE_OF_EXPIRATION: 'Valid till',
  COUNTRY_OF_BIRTH: 'Country of birth',
  PLACE_OF_BIRTH: 'Place of birth',
  I_HAVE_DUAL_CITIZENSHIP: 'Second citizenship',
  DUAL_CITIZENSHIP: 'Country of second citizenship',
  COPY_OF_PASSPORT: 'Copy of passport',
  NO_RUSSIA_PASSPORT:
    'Scan of pages 2 and 3 of passport or certificate of temporary asylum on the territory of the Russian Federation',
  NOTARIZED_PASSPORT: 'Scan of notarised translation of passport (pages 2 and 3)',
  I_AM_COMPATRIOT: 'I am a compatriot',
  I_AM_COMPATRIOT_HINT:
    'To be filled in only by applicants for state-sponsored places on an equal basis with citizens of the Russian Federation if there are supporting documents. Admission for state-sponsored places begins on 20.06.2023)',
  I_NEED_VISA: 'Visa required',
  I_NOT_NEED_VISA: 'Visa not required',
  I_NEED_VISA_YES: 'Yes',
  I_NEED_VISA_NO: 'No',
  I_HAVE_VNZ: 'I have a residence permit in Russia',
  I_HAVE_VNZ_HINT:
    'To be filled in only by applicants for state-sponsored places on an equal basis with citizens of the Russian Federation if there are supporting documents. Admission for state-sponsored places begins on 20.06.2023)',
  RECIEVED_MINOBR_QUOTA:
    'I am applying under the quota of the Ministry of Education and Science of Russia /I am a winner or prize-winner of the Open Doors Olympiad',
  I_HAVE_VISA_OR_RVP: 'I have a Russian visa / residence permit',
  DOCUMENT_NUMBER: 'Document number',
  VNZ_COPY: 'Copy of residence permit',
  VNZ_DESCRIPTION:
    'Upload the pages of residence permit with main data and registration information',
  VNZ: 'Residence permit in Russia',
  REG_NUMBER_EDUCATION_IN_RUSSIA: 'Registration number at education-in-russia.com',
  MSHE_CONFIRMATION_LETTER:
    'Referral form the Ministry of Education and Science of Russia',
  PASSPORT_DATA_COPY_PASSPORT_HINT:
    'Upload the pages of residence permit with main data and registration information',
  QUOTA_MINOBR:
    'I am applying under the quota of the Ministry of Education and Science of Russia',
  REG_NUMBER_EDUINRU: 'Registration number',
  HINT_NUMBER_EDUINRU:
    'on the website of the Ministry of Education and Science russia-edu.minobrnauki.gov.ru',

  // "Visa" section
  PLACE_OF_VISA_APPLICATION: 'Place of visa issuance',
  COUNTRY_OF_VISA_APPLICATION: 'Country of visa issuance',
  CITY_OF_VISA_APPLICATION: 'City of visa issuance',
  HAVE_BEEN_TO_RUSSIA_WITH_VISA:
    'Have you ever travelled to Russia on a study visa?',
  VISA_TITLE:
    'If you require a visa to enter the Russian Federation for study purposes, you will need to obtain an invitation from the University. For this purpose, please fill in the invitation form.',
  VISA_PLACE: 'Place of visa issuance',
  YES: 'Yes',
  NO: 'No',
  COVID_QUESTIONS: 'Answer the following questions',
  SICK_COVID: 'Did you have COVID-19?',
  HAVE_VACCINATION: 'Have you been vaccinated against COVID-19?',
  CAN_DO_VACCINATION_IN_RUSSIA:
    'Are you going to get vaccinated against COVID-19 in the Russian Federation?',

  // Additional information section

  HOW_DID_YOU_FIND_OUT_ABOUT_TRAINING_OPPORTUNITY:
    'How did you learn about the opportunity to study at the Herzen State Pedagogical University?',
  EVALUATE_LEVEL_RUSSIAN_LANGUAGE_PROFICIENCY:
    'Your level of Russian language proficiency',
  WHERE_YOU_STUDY_RUSSIAN: 'Where did you study Russian?',

  // Permanent address (as in passport)
  PERMANENT_ADDRESS: 'Permanent address (as in passport)',
  PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME:
    'Permanent address (as in passport) and current address are the same',
  CHECK_ADDRESS: 'Address verification',
  CURRENT_HOME_ADDRESS: 'Current address',
  I_NEED_PLACE_OF_DORMITORY: 'I need to stay in a dormitory during studies',
  LINK_INFO: 'Information about dormitory accommodation',
  NEED: 'Required',
  NO_NEED: 'Not required',
  NOTICE:
    'Dear applicants! The University gives priority in dormitory accommodation to students of the privileged category.\n' +
    'Based on Order No. 4335 of 20.06.2016. "On the dormitory accommodation for non-resident students":',
  NOTICE_LIST_1:
    '• Non-privileged category students are placed in dormitories by the decision of the institute/faculty (in order of received applications) if there are vacant places (as places become available) during the academic year;',
  NOTICE_LIST_2:
    '• The list of non-privileged category students is formed at each faculty separately.',

  // "Education information" section
  COUNTRY_WHERE_YOU_STUDIED: 'Country where you studied',
  MODE_OF_STUDY: 'Mode of study',
  STUDIED_IN_RGPU_FOUNDATION_PROGRAM: 'Studied in RGPU preparatory programme',
  GRADUATE_INSTITUTION: 'I am a graduate of psychology and pedagogy course',
  HAVE_NO_MATCH: 'Other area of study/Specialty',
  EDUCATIONAL_INSTITUTION: 'Educational institution',
  YEAR_OF_GRADUATION: 'Year of graduation',
  STUDY_LEVEL: 'Study level',
  DOCUMENT_SERIES_AND_NUMBER: 'Series (if available) and number of document',
  DIPLOMA_WITH_DISTINCTION: 'Certificate / Diploma with distinction',
  GRADUATED_FROM_SPBPU: 'Graduation from Herzen State Pedagogical University',
  STUDIED_IN_SPBPU_FOUNDATION_PROGRAM:
    'Studied in RGPU preparatory programme (for internaitonal students)',
  EDUCATIONAL_DOCUMENT: 'Education document',
  YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE:
    'Upload a scan of the certificate/diploma and all pages of the appendix to the certificate/diploma',
  YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE_INO:
    'A scanned copy of the original education document, including the annexes (the copy must be in color)',

  EDUCATIONAL_DOCUMENT_SECOND:
    'Certificate of recognition of education document (nostrification)',
  SUPPORTING_DOCUMENT: 'Supporting document',
  FOREIGN_lANGUAGE_STUDY: 'Foreign language studied in the educational institution',
  SPECIALIZATION_OF_LAST_EDU: 'Area of study/Specialty of previous education',
  GPA: 'Average grade',
  SCAN_TRANSLATION_DOC_EDY: 'Scan of translation of education document',
  SCAN_TRANSLATION_DOC_EDY_INO:
    'A scanned copy of a notarized translation of the education document, or a scanned copy of the translation of the education document executed in accordance with the legislation of the Russian Federation (including the annexes to the education document)',
  NOSTRIFICATION_EDU: 'Certificate of recognition of education document',
  OTHER_DOCUMENTS: 'Other documents',

  // Additional files
  ADD_FILE: 'Add file',
  EDIT_FILE: 'Edit file',
  EDIT: 'Edit',
  FILE: 'File',
  FILE_DELETED: 'File deleted',
  DESIGNATION: 'Name',
  AVAILABLE_FORMATS:
    'This file format is not supported. Only pdf or jpeg files can be uploaded',

  // Validation
  SNILS_VALID: 'SNILS should be a valid field',
  NOT_ALL_REQUIRED_FIELDS_FILLED: 'Some the required fields are filled incorrectly',
  REQUIRED_FIELD: 'Required field',
  REQUIRED_CHECK_ADDRESS: 'Choose one of the options in Address Verification field',
  TWO_CHARACTERS_MIN: 'Two characters minimum',
  ONLY_LOCALE_IS_ALLOWED: 'Only Cyrillic letters',
  DATE_RANGE:
    'Date of birth should be net less than 10 and not more than 100 years ago',
  VALID_EMAIL: 'The field should contain a valid email address',
  VALID_MOBILE: 'The field should contain a valid mobile number',
  PASSWORD_MIXED:
    'Passport must contain capital and small Latin letters, numbers and special characters',
  PASSWORD_MIN_LENGTH: 'Minimum password length is 6 characters',
  PHONE_LENGTH: 'Number must consist of 10 digits',
  PASSPORT_SERIES_LENGTH: 'Passport series length 4 characters',
  PASSPORT_RUSSIAN_FOREIGN_SERIES_LENGTH: 'Series length 2 characters',
  DIVISION_CODE_LENGTH: 'Code division length 7 characters',
  DIVISION_CODE_TYPE: 'Code division type: 000-000',
  PASSPORT_NUMBER_LENGTH: 'Passport number 6 characters',
  PASSPORT_RUSSIAN_FOREIGN_NUMBER_LENGTH: 'Passport number 7 characters',
  DATE_DOCUMENT_ISSUE_INVALID: 'Date of issue cannot be later than current date',
  DATE_BIRTHDAY_ISSUE_INVALID: 'Date of issue cannot be before date of birth',
  NUMBER_IN_LIST_LENGTH: 'Number in list 3 characters',
  ONLY_NUMBER: 'Only numbers',

  // FileUploader
  ONLY_ONE_PDF: 'Only one pdf file',
  FORMAT: 'Format',
  NOT_AVAILABLE: 'Not supported',
  ONLY_JPG: 'Only jpg and jpeg files',
  SIZE_BIGGER_5: 'The size of files bigger than 5 Mb',

  // Document Forms section
  BLANK_DOCUMENTS_LIST_TITLE:
    'To send your application to the Admissions Office, you need to:',
  BLANK_DOCUMENTS_LIST_ITEM_1: 'Tick ALL the boxes at the bottom of this page.',
  BLANK_DOCUMENTS_LIST_ITEM_2: 'Download and read the printed application form.',
  BLANK_DOCUMENTS_LIST_ITEM_3:
    "Sign the printed application form by clicking on the appropriate button. Consent for personal data processing is generated automatically during applicant's Personal Account registration.",
  DONLOAD_FILES_CHECKBOX1:
    'I am informed about responsibility for reliability of information provided in the application and for authenticity of documents submitted',
  DONLOAD_FILES_CHECKBOX2:
    'I am familiar with the contents of the RGPU Charter, the License for the Right to Conduct Educational Activities with Annexes, the Certificate of State Accreditation with Annex, the Admission Rules of RGPU, the Internal Regulations for Students, and information regulating the organisation and conduct of educational activities',
  DONLOAD_FILES_CHECKBOX3:
    "I confirm that I do not have a bachelor's degree, specialist's degree, or master's degree (valid only when applying for a state-sponsored place)",
  DONLOAD_FILES_CHECKBOX4:
    'I have applied for admission to not more than five universities',
  DONLOAD_FILES_CHECKBOX5:
    'I confirm that I have submitted an application with a special right to be admitted without entrance examinations only to Herzen State Pedagogical University (valid only for those eligible for admission without entrance examinations)',
  APPLICATION_DATE: 'Application date',
  APPLICATION_DOWNLOAD: 'Download application',
  HINT_APPLICATION_DOWNLOAD: "Download, check all information and press 'Sign'",

  //Login and registration mistakes
  INVALID_EMAIL_OR_PASSWORD: 'Invalid email or password',

  // Unsorted
  MASTER_PROGRAM: "Master's programme",
  SPECIALIST_PROGRAM: 'Specialist programme',
  ERROR_HAS_OCCURED: 'Sorry, an error has occurred. Please try again',
  NOT_USE_CONTRACT: 'Applications for this enrolment track are no longer available',
  FULL_EXCHANGE_WAS_SENT: 'Full exchange of 1C data has been initiated.',
  TEMP_PASSWORD_SEND: 'Temporary password has been sent to email',
  PASSWORDS_MUST_MATCH: 'Passwords must be the same',
  MARITAL_STATUS: 'Marital status',
  //NEXT: 'Next',
  NEXT: 'Save and continue',
  REPRESENTATIVE_OVER_18: 'Should be over 18',
  SNILS_INVALID: 'Invalid SNILS number',
  DATE_INVALID: 'Invalid date',

  CREATE_APPLICATION: 'Create application',
  NEW_APPLICATION: 'New application for admission',
  TRAJECTORY_FOR_ADMISSION: 'Admission trajectory',
  LEVEL_OF_TRAINING: 'Level of education',
  BUDGET_CONTRACT: 'State-sponsored/Contract',
  FREE_EDU: 'Free education',
  PAYMENT_EDU: 'Paid tuition',
  FILLING_APPLICATION: 'Application',
  SAVE_AND_NEXT: 'Save and continue',
  ADD_MORE: 'Add more',
  DATA_NOT_RECEIVED: 'Data not received',
  RESET_FILTER: 'Reset filter',

  STATEMENT: 'Statement',
  DATE_AND_TIME: 'Date and time of submission',
  PAYMENT_TYPE_FORM: 'Payment type',
  STATUS: 'Status',
  CONCLUSION_BASE_DEPARTMENT: 'Conclusion of profile department',
  ACHIEVEMENTS: 'Achievements',
  PRIORITY_ENROLLMENT: 'Privileges',
  CONTRACT: 'Contract',
  CONTRACT_TEXT: 'Fill in if you plan to apply for paid tuition',
  FORMS_OF_DOCUMENTS: 'Forms',
  FORMS_OF_DOCUMENTS_TEXT: 'Read printed forms',
  DIRECTIONS_TRAINING: 'Areas of study',
  DIRECTIONS_TRAINING_PRIORITY: 'Changing priority areas',
  DIRECTIONS_TRAINING_PRIORITY_TEXT: 'You can change priority areas of study',
  ENTRANCE_EXAMS: 'Entrance exams',
  SELECT_EXAM_DATE: 'Select exam date',
  PASSING_SCORE: 'Minimal passing score',
  EDUCATIONAL_PROGRAM: 'Educational programme',
  ENTRANCE_EXAMS_TEXT: 'Choose date and time of entrance exams',
  BASIC_EXAMS: 'Results of basic state exams',
  BASIC_EXAMS_TEXT:
    'Fill in the section if you have taken Basic State Exam or Centralised Testing  (for citizens of the Republic of Belarus)',
  UNIFRED_EXAMS: 'Results of USE/CE/CT',
  UNIFRED_EXAMS_TEXT:
    'Fill in the section if you have taken Unified State Exam of Centralised Testing / Centralised Exam (for citizens of the Republic of Belarus)',
  NUMBER_IN_LIST: 'Number in the list',

  CHOOSE_DIRECTION: 'Choose the field of study',
  DIRECTION: 'Field of study',
  FORM_PAYMENT_ID: 'Form of payment',
  FIND: 'Find',
  CONFIRM: 'Confirm',
  CHOOSE: 'Choose',

  PAYMENT_TYPE: 'Payment type',
  PAYER_APPLICANT_THE_SAME:
    'The payer (customer) and the applicant are the same person',
  PAYER_IS_NOT_APPLICANT:
    'The payer (customer) and the applicant are different people',
  PAYER_IS_ORGANIZATION: 'The contract payer (customer) is a legal entity',

  BANK_IDENTIFIERR_CODE: 'BIC code of 9 digits',
  CHECKING_ACCOUNT: 'Current account of 20 digits',
  CORRESPONDENT_ACCOUNT: 'Correspondent account of 20 digits',

  POLYTECH_FULLNAME: 'Russian State Pedagogical University named after A.I. Herzen',
  DOWNLOAD_FILE_ERROR: 'Error when downloading file',
  UPLOAD_CONTRACT_ERROR: `Contract can be uploaded only in Approved and Contract Editing status'`,

  MODERATOR_STATEMENTS: 'Applications',
  MODERATOR_CONTRACTS: 'Contracts',
  MODERATOR_APPLICANTS: 'Lists of applicants',

  TITLE_BLANK_NULL:
    'Please complete all sections. You will then be able to read the documents and sign them.',
  TITLE_BLANK_HEADER:
    'In order to send an application to the Admission Office you need: ',
  TITLE_BLANK_EL1: '1.  Tick ALL the boxes at the top of this page. ',
  TITLE_BLANK_EL2:
    '2.  Download and read the printed application form for admission. ',
  TITLE_BLANK_EL3:
    "3.  Sign the printed application by clicking on the corresponding button. The consent for personal data processing is generated automatically during the applicant's Personal Account registration.",
  TITLE_BLANK_EL3_ANOTHER:
    "3.  Sign the printed application for admission by clicking on the corresponding button. The consent for personal data processing is generated automatically during the applicant's Personal Account registration.",
  TITLE_BLANK_EL4: '4.  Send the application for review.',
  TITLE_BLANK_EL4_ANOTHER:
    "4.  Sign the printed application for admission by clicking on the corresponding button. The consent for personal data processing is generated automatically during  the applicant's Personal Account registration.",
  TITLE_BLANK_EL5: '5.  Send the application for review.',

  EDIT_APPLICATION: 'Edit application',
  SEND_APPLICATION: 'Send the application for review',
  EDIT_FIELDS: 'Edit fields of study',
  PLAN_CONTINUE: 'After graduating from the preparatory faculty I plan to apply to:',
  STUDY_FIELD: 'Field of study',
  WITHDRAW_APPLICATION: 'Withdraw application',
  ADD_ANOTHER_PASSPORT: 'Добавить документ',
  HINT_EXAM_DATE:
    'Select the date of the entrance exam. On the day of the exam, you should log in to this section and start the exam by clicking the "Start Exam" button',
  EXAM: 'Discipline',
  EXAM_DATE: 'Date and time of the exam',
  SCORE: 'Grade (score)',
  NOT_GRADED: 'Not graded yet',
  EXAM_DATE_MOSCOW: 'Date and time of the exam',
  HINT_NOT_AVAILABLE: 'Not available',
  SEMESTER_PAYMENTS: 'Semester payments',
  DOWNLOAD_FORM: 'Download the form',
  HINT_DOWNLOAD_FORM:
    'Download, print and sign the contract, then upload the signed copy to the field below',
  SIGNED_CONTRACT: 'Signed contract',
  SEND: 'Send',
  STUDY_CONTRACT: 'Contract',
  CONTRACT_SENT: 'The contract has been sent for review',

  SING: 'Sign',
  START_EXAM:
    'Entrance tests schedule will be set later. Follow the updates of this section.',

  //Benefits and preferential rights
  ADD_PRIVILEGES_TITLE: 'Add privilege title',
  EDIT_PRIVILEGES_TITLE: 'Edit privilege title',
  ADD_PREEMPTIVE_RIGHT_TITLE: 'Add preferential right title',
  EDIT_PREEMPTIVE_RIGHT_TITLE: 'Edit preferential right title',
  TYPE_PREEMPTIVE: 'Eligibility',
  TYPE_LGOTA: 'Type of benefit',
  TYPE_DOCUMENT: 'Type of document',
  NUMBER_DOCUMENT: 'Series (if available) and number of the document',
  DATE_ISSUE: 'Date of issue',
  DOCUMENT: 'Document',
  CHECKBOX_LGOTA: 'I do not have benefits or preferential rights for admission',
  ADD_BUTTON: 'Add',
  LGOTS: 'Benefits',
  LGOT: 'Benefit',
  SEPARATE_QUOTA: 'Separate quota',
  SPECIAL_QUOTA: 'Special quota',
  PREEMPTIVE_RIGHTS: 'Preferential right',
  CONFIRMATION_DOCUMENT: 'Supporting document',
  SERIES_AND_NUMBER: 'Series and number',

  //Areas of training (moderator)
  RECENTLY_ADDED: 'Recently added',
  REMOVED: 'Removed',
  DISCIPLINES: 'Disciplines',
  SOURCE_OF_FINANCING: 'Source of financing',
  FORM_OF_STUDY: 'Form of study',
  THE_LEVEL_OF_EDUCTION: 'Level of education',
  SUBDIVISION: 'Subdivision',
  PROFILE_SCORE: 'Profile discipline score',
  CONSENT_TO_ENROLLMENT: 'Consent to enrolment',
  RECEIVED: 'Received',
  DENIED: 'Denied',
  DELETE: 'Delete',
  NAME_CONSENT: 'Name',
  REVOCATION_DATE: 'Revocation date',
  CONFIRM_MODAL:
    "Confirm action. By clicking 'Yes' you are signing a statement of consent to enrolment",
  REJECT_MODAL:
    "Confirm action. By clicking 'Yes', you are signing a declaration of withdrawal of consent to enrolment",

  //Unified State Exams
  NO_RESULTS_OF_USE_CE_CCT:
    'If you have taken the Unified State Exam (ЕГЭ) but have not received the results at the moment, you should enter the minimum USE (ЕГЭ) scores for each subject',
  DIDNT_TAKE: 'Did not pass the USE/CE/CT',
  TAKE_EGE: 'Have taken USE',
  CENTRALIZED_EXAM: 'Have Centralised Exam results',
  CENTRALIZED_TEST: 'Have Centralised Test results',
  SUBJECT: 'Subject',
  ADD_SUBJECT: 'Add subject',
  EXAM_YEAR: 'Exam year',
  MINIMUM_SCORE: 'Minimum score',
  CHECK_RESULTS: 'Check USE results',
  ANOTHER_PASSPORT: 'Took USE with another ID',
  EGE_LINK_INFO_AVERAGE_SCORE: 'Minimum scores',
  EGE_LINK_START_TEXT:
    'If you have taken the USE but have not received the results yet, you should enter',
  EGE_LINK_END_TEXT: 'USE for each subject',
  EGE_RESULT_OF_CT_CE_TEXT:
    'To enter the results of CT or CE, select USE/CT/CE in the "Form of test" field',

  //Olympiads
  WITHOUT_ENTRANCE_TEST: 'Without entrance exams',
  PROFILE_SUBJECT: 'Score 100 points in the profile discipline',

  //Contracts
  CREATE_CONTRACT: 'Create contract',
  PAYER_INFORMATION: 'Payer information',
  PAYER_PASSPORT: 'Payer passport details',
  SUBMIT_CONTRACT_FOR_APPROVAL: 'Submit contract for approval',

  //Chat
  CHAT_TITLE: 'Applicant Support Chat',
  CHAT_SUBTITLE: 'Ask a question',
  CHAT_PLACEHOLDER: 'Enter a mesage...',

  //Hints
  HINT_SUPPORTING_DOCUMENT: 'optional',

  //Residence address in country of citizenship
  CYRILLIC_AND_NUMBERS_ONLY: 'Cyrillic letters and numbers only',
  CYRILLIC_AND_Latin_AND_NUMBERS_ONLY: 'Cyrillic and Latin letters and numbers only',

  //Benefits
  LINK_FEATURES_OF_TESTING:
    'Features of entrance tests for disabled persons and persons with disabilities',
  DOWNLOAD_THE_APPLICATION_FORM_FOR_SPECIAL_CONDITION:
    'Download the application form for special conditions for entrance tests',
}

export type TermKey = keyof typeof en
export default en
