import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import {
  getGenders,
  getLevelRussianLanguageProficiency,
  getPersonalData,
  getWhereStudyRussian,
  getWorks,
  getYouFindAboutPossibilityStudying,
} from '@redux/action-types'
import { selectPersonalFormData } from '@redux/actions/selectors'
import api from '@services/api'

const useAdditionalInfoForm = () => {
  const { watch, register, handleSubmit, setError, setValue, clearErrors, ...rest } =
    useForm<any>({ mode: 'all' })

  const { callApi, loading } = useProfileApiHandling({
    apiFn: api.client.updateProfileAdditionalInfo,
    setError,
    onSuccessCb: () => dispatch(getPersonalData()),
  })

  const dispatch = useAppDispatch()

  let isGetData = true

  useEffect(() => {
    !personalData &&
      dispatch(getPersonalData()).then((res) =>
        getPersonalDataAdditionalInfo(res.payload)
      )
  }, [])

  const personalData = useAppSelector(selectPersonalFormData)
  let personalDataAdditionalInfoDefault: any = {
    training_opportunity_id: null,
    other_training_opportunity: null,
    lvl_rus_lang_id: null,
    study_russian_id: null,
    other_study_russian: null,
  }

  function getPersonalDataAdditionalInfo(data: any) {
    return {
      training_opportunity_id: data?.trainingOpportunityId,
      other_training_opportunity: data?.otherTrainingOpportunity,
      lvl_rus_lang_id: data?.lvlRusLangId,
      study_russian_id: data?.studyRussianId,
      other_study_russian: data?.otherStudyRussian,
    }
  }

  let additionalInfoTrainingOpportunityId = watch('training_opportunity_id')?.value
  let additionalInfoTrainingOpportunityIsOther =
    additionalInfoTrainingOpportunityId === 7

  let additionalInfoWhereStudyId = watch('study_russian_id')?.value
  let additionalInfoWhereStudyIsOther = additionalInfoWhereStudyId === 2

  const fields = {
    other_training_opportunity: register('other_training_opportunity', {
      required: {
        message: terms.REQUIRED_FIELD,
        value: additionalInfoTrainingOpportunityIsOther,
      },
    }),
    other_study_russian: register('other_study_russian', {
      required: {
        message: terms.REQUIRED_FIELD,
        value: additionalInfoWhereStudyIsOther,
      },
    }),
    training_opportunity_id: register('training_opportunity_id', {
      required: terms.REQUIRED_FIELD,
    }),
    lvl_rus_lang_id: register('lvl_rus_lang_id', {
      required: terms.REQUIRED_FIELD,
    }),
    study_russian_id: register('study_russian_id', {
      required: terms.REQUIRED_FIELD,
    }),
  }

  const submitAdditionInfo = handleSubmit((data: any) => {
    const normalizedData: any = {
      ...personalData,
      training_opportunity_id: data.training_opportunity_id.value,
      other_training_opportunity: data.other_training_opportunity,
      lvl_rus_lang_id: data.lvl_rus_lang_id.value,
      study_russian_id: data.study_russian_id.value,
      other_study_russian: data.other_study_russian,
      recruiting_company: personalData?.recruitingOption || null,
    }

    callApi(normalizedData)
  })

  const inputField = ''

  const [idLoadingPage, setLoadingPage] = useState(false)
  const [learningOpportunities, setLearningOpportunities] = useState([])
  const [proficiencyLevel, setProficiencyLevel] = useState([])
  const [whereStudy, setWhereStudy] = useState([])

  function getSelectorData() {
    if (isGetData) {
      setLoadingPage(true)

      dispatch(getLevelRussianLanguageProficiency()).then((resp: any) => {
        setProficiencyLevel(
          resp.payload.map((item: any) => {
            return {
              value: item.lvlRusLangTranslate[0].id,
              label: item.lvlRusLangTranslate[0].name,
            }
          })
        )

        dispatch(getPersonalData()).then((res) => {
          setValue(
            'lvl_rus_lang_id',
            resp.payload
              .map((item: any) => {
                return {
                  value: item.lvlRusLangTranslate[0].id,
                  label: item.lvlRusLangTranslate[0].name,
                }
              })
              .find((item: any) => {
                return (
                  item.value ===
                  getPersonalDataAdditionalInfo(res.payload).lvl_rus_lang_id
                )
              })
          )
        })
      })

      dispatch(getWhereStudyRussian()).then((resp: any) => {
        setWhereStudy(
          resp.payload.map((item: any) => {
            return {
              value: item.studyRussianTranslate[0].id,
              label: item.studyRussianTranslate[0].name,
            }
          })
        )

        dispatch(getPersonalData()).then((res: any) => {
          setValue(
            'study_russian_id',
            resp.payload
              .map((item: any) => {
                return {
                  value: item.studyRussianTranslate[0].id,
                  label: item.studyRussianTranslate[0].name,
                }
              })
              .find((item: any) => {
                return (
                  item.value ===
                  getPersonalDataAdditionalInfo(res.payload).study_russian_id
                )
              })
          )
          setValue('other_study_russian', res.payload.otherStudyRussian)
        })
      })

      dispatch(getYouFindAboutPossibilityStudying()).then((resp: any) => {
        setLearningOpportunities(
          resp.payload.map((item: any) => {
            return {
              value: item.trainingOpportunityTranslate[0].id,
              label: item.trainingOpportunityTranslate[0].name,
            }
          })
        )
        dispatch(getPersonalData()).then((res: any) => {
          setValue(
            'training_opportunity_id',
            resp.payload
              .map((item: any) => {
                return {
                  value: item.trainingOpportunityTranslate[0].id,
                  label: item.trainingOpportunityTranslate[0].name,
                }
              })
              .find((item: any) => {
                return item.value === res.payload.trainingOpportunityId
              })
          )
          setValue(
            'other_training_opportunity',
            res.payload.otherTrainingOpportunity
          )
        })
      })

      setLoadingPage(false)
      isGetData = false
    }
  }

  return {
    fields,
    idLoadingPage,
    getSelectorData,
    inputField,
    submitAdditionInfo,
    learningOpportunities,
    proficiencyLevel,
    whereStudy,
    personalDataAdditionalInfoDefault,
    watch,
    ...rest,
  }
}

export default useAdditionalInfoForm
