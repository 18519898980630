import { useMemo } from 'react'

import {
  TRAJECTORY_INO_ID,
  BLOCK_DATES,
  PAYMENT_TYPE_PAY_ID,
} from './../common/constants'
import type { ViewerElementStatements } from './../models'

type Props = {
  scenarioKeys?: any
  statementEl?: ViewerElementStatements | null
  trajectory?: { id: number; value: string }
}

const useDateBakIno = (props: Props) => {
  const { scenarioKeys, statementEl, trajectory } = props

  const isBlockSendRequest = useMemo(() => {
    const currentTime = new Date()
    const blockSendAppDate = new Date(BLOCK_DATES.BAK_PAY_INO)
    const paymentType = statementEl?.paymentType === PAYMENT_TYPE_PAY_ID

    const isBakINO =
      scenarioKeys.level_training_bachelor_ino.tdId === statementEl?.scenario.id
    const isINO = trajectory?.id === TRAJECTORY_INO_ID

    return paymentType && isBakINO && isINO && currentTime >= blockSendAppDate
  }, [statementEl, trajectory, scenarioKeys, BLOCK_DATES, PAYMENT_TYPE_PAY_ID])

  return { isBlockSendRequest }
}

export default useDateBakIno
