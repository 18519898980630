import { Modify } from 'types/types'

import uploadDocsTypes, { UploadDocKey } from '@common/uploadDocsTypes'
import { UserOtherDocument } from '@models'
import { StateStatus } from '@redux/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '@services/api'

type DocumentState = {
  data: { document: string | null }
  status: StateStatus
  error: unknown
}

type DocsInitialState = Record<UploadDocKey, DocumentState> & {
  additionalDocuments: Modify<DocumentState, { data: UserOtherDocument[] }>
}

const initialDocState: DocumentState = {
  data: { document: null },
  status: 'idle',
  error: null,
}

const initialState: DocsInitialState = Object.keys(uploadDocsTypes).reduce(
  (acc: DocsInitialState, key) => {
    acc[key] = initialDocState
    return acc
  },
  {
    additionalDocuments: { ...initialDocState, data: [] as UserOtherDocument[] },
  } as DocsInitialState
)

export const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    clearDocs: (state, action: PayloadAction<UploadDocKey>) => {
      state[action.payload] = initialDocState
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDocs.pending, (state, action) => {
        const { typeDoc } = action.meta.arg
        if (!state[typeDoc]) return
        state[typeDoc].status = 'loading'
      })
      .addCase(getDocs.fulfilled, (state, action) => {
        const { typeDoc } = action.meta.arg
        if (!state[typeDoc]) return
        state[typeDoc].status = 'succeeded'
        state[typeDoc].data = action.payload
      })
      .addCase(getDocs.rejected, (state, action) => {
        const { typeDoc } = action.meta.arg
        if (!state[typeDoc]) return
        state[typeDoc].status = 'failed'
        state[typeDoc].data = { document: null }
        state[typeDoc].error = action.payload
      })
      .addCase(getAdditionalDocs.fulfilled, (state, action) => {
        state.additionalDocuments.data = action.payload
        state.additionalDocuments.status = 'succeeded'
        state.additionalDocuments.error = null
      })
      .addCase(getAdditionalDocs.rejected, (state, action) => {
        state.additionalDocuments.data = []
        state.additionalDocuments.status = 'failed'
        state.additionalDocuments.error = action.payload
      })
  },
})

export const getDocs = createAsyncThunk(
  'documents/getDocs',
  async ({
    typeDoc,
    docId,
    userId,
  }: {
    typeDoc: UploadDocKey
    docId?: number
    userId?: number
  }) => {
    const response = await api.client.getDocs(typeDoc, docId, userId)
    return response.data
  }
)

export const downloadTemplateDoc = createAsyncThunk(
  'documents/templateDocument',
  async (typeDoc: UploadDocKey) => {
    const response = await api.client.downloadTemplateDoc(typeDoc)
    return response.data
  }
)

export const getAdditionalDocs = createAsyncThunk(
  'documents/getAdditionalDocs',
  async (statementId: number) => {
    const response = await api.client.getOtherDocs(statementId)
    return response.data
  }
)

export const getAdditionalDocsModerator = createAsyncThunk(
  'documents/getAdditionalDocs',
  async ({ statementId, userId }: { statementId: number; userId: any }) => {
    console.log(userId)
    const response = await api.client.getOtherDocsModerator(statementId, userId)
    return response.data
  }
)

export const getMoreFile = createAsyncThunk(
  'documents/getMoreFile',
  async (id: string | number) => {
    const response = await api.client.getDocsForStatement(id)
    return response.data
  }
)

export default docsSlice.reducer
