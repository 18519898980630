import React from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { hideModal } from '@redux/action-types'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'

import './DirectionsModal.scss'

const DirectionsModal = () => {
  const { text, func, onNextPageNavigate } = useAppSelector(modalDataSelector)
  const dispatch = useAppDispatch()

  return (
    <TemplateModal title="Подтвердите действие">
      <div className="content">
        <p>
          Заявление о согласии на зачисление подается при условии представления в
          приемную комиссию оригинала документа об образовании (бюджет) или
          заверенной копии документа об образовании (контракт). Бланк согласия {''}
          <a
            href="https://www.herzen.spb.ru/abiturients/pk/1657004932/"
            target="_blank"
            className="modal__link"
          >
            здесь
          </a>
          .
        </p>
      </div>
    </TemplateModal>
  )
}

export default DirectionsModal
