import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

import AnotherDocs from './AnotherDocs'
import CheckMark from '@assets/images/Check-mark.svg'
import TrashIcon from '@assets/images/Trash.svg'
import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useResultExamForm from '@hooks/useResultExamForm'
import useResultExamFormInitialization from '@hooks/useResultExamFormInitialization'
import useUploadDocuments from '@hooks/useUploadDocuments'
import {
  SelectOption,
  DisciplineEgeOptions,
  disciplineCentralExamOptions,
  type AnotherPassports,
} from '@models'
import { selectTabEgeList, selectTrajectoryValue } from '@redux/actions/selectors'
import api, { UploadDocsRespOk, UploadDocsResponse } from '@services/api'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import ReactSelect from '@ui/Select'
import { base64ToPdf } from '@utils/common'

const ResultExamForm = (passportIds) => {
  const {
    fieldsPassports,
    appendPassports,
    fieldsForm,
    fields,
    control,
    noEge,
    takeEge,
    anotherPassport,
    onSubmit,
    append,
    remove,
    removePassports,
    removeElement,
    register,
    setValue,
    watch,
  } = useResultExamForm(passportIds)
  const {
    yearOptions,
    yearOptionsCE,
    yearOptionsCT,
    typeIdentityDocumentOptions,
    disciplineEgeOptions,
    personData,
    disciplineCentralExamOptions,
  } = useResultExamFormInitialization({
    remove,
    append,
    setValue,
    appendPassports,
    removePassports,
  })

  const { readOnly } = useReadOnlyFillingStatements()
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const isBelarusPerson = personData?.nationalOption?.value === 4
  const trajectory = useAppSelector(selectTrajectoryValue)
  const tabEgeData = useAppSelector(selectTabEgeList)
  // console.log(tabEgeData)
  const checkIsRussianPassport = (value: number | undefined) => {
    if (!value) return false
    return value === passportIds.russia_citizen_passport_code.tdId
  }

  const checkIsRussianForeignPassport = (value: number | undefined) => {
    if (!value) return false
    return value === passportIds.russia_foreign_passport_code.tdId
  }

  const checkIsForeignPassport = (value: number | undefined) => {
    if (!value) return false
    return !checkIsRussianPassport(value) && !checkIsRussianForeignPassport(value)
  }

  return (
    <FillingStatementsForm
      className="result-exam"
      onSubmit={(e) => {
        if (!readOnly) {
          onSubmit(e)
          // onUploadPreviousDocuments(() => getPreviousDocuments())
        } else {
          // if (!previousDocumentsLoaded) {
          //   onUploadPreviousDocuments(() => getPreviousDocuments())
          // }

          e.preventDefault()
          onNextPageNavigate()
        }
      }}
    >
      {trajectory.id === 2 && <p>{terms.NO_RESULTS_OF_USE_CE_CCT}</p>}
      <Checkbox
        disabled={readOnly}
        text={`${terms.DIDNT_TAKE}`}
        defaultChecked={watch('noEge')}
        {...fieldsForm.noEge}
      />
      {!noEge && (
        <>
          {fields &&
            !watch('centralizedExam') &&
            !watch('resultCentralExam') &&
            !isBelarusPerson &&
            fields.map((field, index) => (
              <div>
                <div key={`${field.id}${index}`} className="result-exam__element">
                  {/* Предмет */}
                  <Controller
                    name={`resultEge.${index}.disciplineEgeId`}
                    control={control}
                    render={({ field }) => (
                      <ReactSelect<DisciplineEgeOptions>
                        options={disciplineEgeOptions}
                        placeholder={terms.SUBJECT}
                        className="result-exam__element_field"
                        markRequired={true}
                        styles={{ menu: (base) => ({ ...base, minWidth: '200px' }) }}
                        {...(field as unknown)}
                        onChange={(e) => {
                          if (e) {
                            setValue(`resultEge.${index}.score`, e.score)
                            field.onChange(e)
                          }
                        }}
                        isDisabled={readOnly}
                      />
                    )}
                  />
                  <Input
                    className="result-exam__element_field"
                    placeholder={terms.SCORE}
                    type="number"
                    disabled={readOnly}
                    markRequired={true}
                    {...register(`resultEge.${index}.result`, {
                      valueAsNumber: true,
                      required: true,
                      onChange: (event) => {
                        if (event.target.value > 100) {
                          setValue(`resultEge.${index}.result`, 100)
                        }
                        if (event.target.value < 0) {
                          setValue(`resultEge.${index}.result`, 0)
                        }
                      },
                    })}
                  />
                  <Controller
                    name={`resultEge.${index}.year`}
                    control={control}
                    render={({ field }) => (
                      <ReactSelect<SelectOption>
                        options={yearOptions}
                        placeholder={terms.EXAM_YEAR}
                        className="result-exam__element_field"
                        markRequired={true}
                        styles={{ menu: (base) => ({ ...base, minWidth: '100px' }) }}
                        {...(field as unknown)}
                        isDisabled={readOnly}
                      />
                    )}
                  />
                  <div className={'result-exam__element_status'}>
                    {field.verified && (
                      <>
                        <p>Проверено</p> <CheckMark />
                      </>
                    )}
                    {field.verified === false && (
                      <Button
                        type={'button'}
                        disabled={readOnly}
                        onClick={() => removeElement(field.disciplineEgeId)}
                      >
                        <TrashIcon />
                      </Button>
                    )}
                    {field.verified === undefined && (
                      <Button
                        type={'button'}
                        onClick={() => remove(index)}
                        disabled={readOnly}
                      >
                        <TrashIcon />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {!isBelarusPerson && (
            <div className={'result-exam__btn'}>
              <Button
                type="button"
                onClick={() => {
                  append({}, { shouldFocus: false })
                }}
                disabled={readOnly}
              >
                {terms.ADD_SUBJECT}
              </Button>
            </div>
          )}

          {isBelarusPerson && (
            <Checkbox
              disabled={readOnly}
              {...fieldsForm.takeEge}
              defaultChecked={watch('takeEge')}
              text={terms.TAKE_EGE}
            />
          )}

          {takeEge && !noEge && (
            <div>
              {terms.EGE_LINK_START_TEXT}
              <a
                className="link_to_information"
                href="https://www.herzen.spb.ru/upload/medialibrary/826/fuqevaivl23bg4t5k2ae6qn6r7xieuir/Minimalnoe-kolichestvo-ballov.pdf"
                target="_blank"
              >
                {terms.EGE_LINK_INFO_AVERAGE_SCORE}
              </a>
              {terms.EGE_LINK_END_TEXT}
            </div>
          )}

          {fields &&
            watch('takeEge') &&
            isBelarusPerson &&
            fields.map(
              (field, index) =>
                !field.resultCentralExam &&
                !field.resultCentralTesting && (
                  <div>
                    <div
                      key={`${field.id}${index}`}
                      className="result-exam__element"
                    >
                      {/* Предмет */}
                      <Controller
                        name={`resultEge.${index}.disciplineEgeId`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<DisciplineEgeOptions>
                            options={disciplineEgeOptions}
                            placeholder={terms.SUBJECT}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({
                                ...base,
                                minWidth: '200px',
                              }),
                            }}
                            {...(field as unknown)}
                            onChange={(e) => {
                              if (e) {
                                setValue(`resultEge.${index}.score`, e.score)
                                field.onChange(e)
                              }
                            }}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      {/* Оценка */}
                      <Input
                        className="result-exam__element_field"
                        placeholder={terms.SCORE}
                        type="number"
                        disabled={readOnly}
                        markRequired={true}
                        {...register(`resultEge.${index}.result`, {
                          valueAsNumber: true,
                          required: true,
                          onChange: (event) => {
                            if (event.target.value > 100) {
                              setValue(`resultEge.${index}.result`, 100)
                            }
                            if (event.target.value < 0) {
                              setValue(`resultEge.${index}.result`, 0)
                            }
                          },
                        })}
                      />
                      {/* Год */}
                      <Controller
                        name={`resultEge.${index}.year`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<SelectOption>
                            options={yearOptions}
                            placeholder={terms.EXAM_YEAR}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({ ...base, minWidth: '100px' }),
                            }}
                            {...(field as unknown)}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      {/* Проверено */}
                      <div className={'result-exam__element_status'}>
                        {field.verified && (
                          <>
                            <p>Проверено</p> <CheckMark />
                          </>
                        )}
                        {field.verified === false && (
                          <Button
                            type={'button'}
                            disabled={readOnly}
                            onClick={() => removeElement(field.disciplineEgeId)}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                        {field.verified === undefined && (
                          <Button
                            type={'button'}
                            onClick={() => remove(index)}
                            disabled={readOnly}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}

          {watch('takeEge') && isBelarusPerson && (
            <div className={'result-exam__btn'}>
              <Button
                type="button"
                onClick={() => {
                  append({}, { shouldFocus: false })
                }}
                disabled={readOnly}
              >
                {terms.ADD_SUBJECT}
              </Button>
            </div>
          )}

          <Checkbox
            defaultChecked={watch('anotherPassport')}
            disabled={readOnly}
            {...fieldsForm.anotherPassport}
            text={terms.ANOTHER_PASSPORT}
          />
          {anotherPassport && (
            <Button
              type="button"
              onClick={() => appendPassports({}, { shouldFocus: false })}
              disabled={readOnly}
            >
              {terms.ADD_ANOTHER_PASSPORT}
            </Button>
          )}

          {/* Другие документы */}
          {anotherPassport && (
            <div className="anotherPassports">
              {fieldsPassports.map((itemPassport, index) => (
                <AnotherDocs
                  key={itemPassport.id}
                  item={itemPassport}
                  index={index}
                  register={register}
                  isReadOnly={readOnly}
                  options={typeIdentityDocumentOptions}
                  control={control}
                  setValue={setValue}
                  passportIds={passportIds}
                  file={
                    //@ts-ignore
                    itemPassport && itemPassport.files
                      ? //@ts-ignore
                        itemPassport.files[index]
                      : null
                  }
                />
              ))}
            </div>
          )}

          {isBelarusPerson && (
            <Checkbox
              {...fieldsForm.centralizedExam}
              defaultChecked={watch('resultCentralExam')}
              disabled={readOnly}
              text={terms.CENTRALIZED_EXAM}
            />
          )}

          {isBelarusPerson &&
            watch('centralizedExam') &&
            fields.map(
              (field, index) =>
                field.resultCentralExam && (
                  <div>
                    <div
                      key={`${field.id}${index}`}
                      className="result-exam__element"
                    >
                      <Controller
                        name={`resultEge.${index}.disciplineEgeId`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<disciplineCentralExamOptions>
                            options={disciplineCentralExamOptions}
                            placeholder={terms.SUBJECT}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({ ...base, minWidth: '200px' }),
                            }}
                            {...(field as unknown)}
                            onChange={(e) => {
                              if (e) {
                                setValue(`resultEge.${index}.score`, e.score)
                                field.onChange(e)
                              }
                            }}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      <Input
                        className="result-exam__element_field"
                        placeholder={terms.SCORE}
                        type="number"
                        disabled={readOnly}
                        markRequired={true}
                        {...register(`resultEge.${index}.result`, {
                          valueAsNumber: true,
                          required: true,
                          onChange: (event) => {
                            if (event.target.value > 100) {
                              setValue(`resultEge.${index}.result`, 100)
                            }
                            if (event.target.value < 0) {
                              setValue(`resultEge.${index}.result`, 0)
                            }
                          },
                        })}
                      />
                      <Controller
                        name={`resultEge.${index}.year`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<SelectOption>
                            options={yearOptionsCE}
                            placeholder={terms.EXAM_YEAR}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({ ...base, minWidth: '100px' }),
                            }}
                            {...(field as unknown)}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      <div className={'result-exam__element_status'}>
                        {field.verified && (
                          <>
                            <p>Проверено</p> <CheckMark />
                          </>
                        )}
                        {field.verified === false && (
                          <Button
                            type={'button'}
                            disabled={readOnly}
                            onClick={() => removeElement(field.disciplineEgeId)}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                        {field.verified === undefined && (
                          <Button
                            type={'button'}
                            onClick={() => remove(index)}
                            disabled={readOnly}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                      </div>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                      {(fieldsForm.centralizedExam ||
                        fieldsForm.centralizedTest) && (
                        <div>
                          <ProfileSection
                            title={'Добавить документ'}
                            markRequired={true}
                            {...register(`resultEge.${index}.resultCentralExam`, {
                              value: true,
                            })}
                          >
                            <FileUploader
                              {...register(`resultEge.${index}.doc`, {
                                required: {
                                  value:
                                    Boolean(fieldsForm.centralizedExam) ||
                                    Boolean(fieldsForm.centralizedTest),
                                  message: terms.REQUIRED_FIELD,
                                },
                              })}
                              {...register(`resultEge.${index}.doc_id`)}
                              files={watch(`resultEge.${index}.doc`) || []}
                              onDrop={(newFile) => {
                                api.client
                                  .uploadDocs({
                                    documents: newFile,
                                    typeDoc: 'CONFIRM_RESULT_EGE',
                                  })
                                  .then((response: UploadDocsResponse) => {
                                    const { data } = response as UploadDocsRespOk
                                    const { id } = data
                                    setValue(`resultEge.${index}.doc_id`, id)
                                    setValue(`resultEge.${index}.doc`, newFile)
                                  })
                              }}
                              onChange={(newFile) => {
                                api.client
                                  .uploadDocs({
                                    documents: newFile,
                                    typeDoc: 'CONFIRM_RESULT_EGE',
                                  })
                                  .then((response: UploadDocsResponse) => {
                                    const { data } = response as UploadDocsRespOk
                                    const { id } = data
                                    setValue(`resultEge.${index}.doc_id`, id)
                                    setValue(`resultEge.${index}.doc`, newFile)
                                  })
                              }}
                              onDelete={() => {
                                setValue(`resultEge.${index}.doc_id`, null)
                                setValue(`resultEge.${index}.doc`, [])
                              }}
                              className="profile-form__file-uploader"
                            />
                          </ProfileSection>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}

          {isBelarusPerson && watch('centralizedExam') && (
            <div className={'result-exam__btn'}>
              <Button
                type="button"
                onClick={() => {
                  append({ resultCentralExam: true }, { shouldFocus: false })
                }}
                disabled={readOnly}
              >
                {terms.ADD_SUBJECT}
              </Button>
            </div>
          )}

          {isBelarusPerson && (
            <Checkbox
              disabled={readOnly}
              {...fieldsForm.centralizedTest}
              defaultChecked={watch('resultCentralTesting')}
              text={terms.CENTRALIZED_TEST}
            />
          )}

          {fields &&
            isBelarusPerson &&
            (watch('centralizedTest') || watch('resultCentralTesting')) &&
            fields.map(
              (field, index) =>
                field.resultCentralTesting && (
                  <div>
                    <div
                      key={`${field.id}${index}`}
                      className="result-exam__element"
                    >
                      <Controller
                        name={`resultEge.${index}.disciplineEgeId`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<DisciplineEgeOptions>
                            options={disciplineEgeOptions}
                            placeholder={terms.SUBJECT}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({ ...base, minWidth: '200px' }),
                            }}
                            {...(field as unknown)}
                            onChange={(e) => {
                              if (e) {
                                setValue(`resultEge.${index}.score`, e.score)
                                field.onChange(e)
                              }
                            }}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      <Input
                        className="result-exam__element_field"
                        placeholder={terms.SCORE}
                        type="number"
                        disabled={readOnly}
                        markRequired={true}
                        {...register(`resultEge.${index}.result`, {
                          valueAsNumber: true,
                          required: true,
                          onChange: (event) => {
                            if (event.target.value > 100) {
                              setValue(`resultEge.${index}.result`, 100)
                            }
                            if (event.target.value < 0) {
                              setValue(`resultEge.${index}.result`, 0)
                            }
                          },
                        })}
                      />
                      <Controller
                        name={`resultEge.${index}.year`}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect<SelectOption>
                            options={yearOptionsCT}
                            placeholder={terms.EXAM_YEAR}
                            className="result-exam__element_field"
                            markRequired={true}
                            styles={{
                              menu: (base) => ({ ...base, minWidth: '100px' }),
                            }}
                            {...(field as unknown)}
                            isDisabled={readOnly}
                          />
                        )}
                      />
                      <div className={'result-exam__element_status'}>
                        {field.verified && (
                          <>
                            <p>Проверено</p> <CheckMark />
                          </>
                        )}
                        {field.verified === false && (
                          <Button
                            type={'button'}
                            disabled={readOnly}
                            onClick={() => removeElement(field.disciplineEgeId)}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                        {field.verified === undefined && (
                          <Button
                            type={'button'}
                            onClick={() => remove(index)}
                            disabled={readOnly}
                          >
                            <TrashIcon />
                          </Button>
                        )}
                      </div>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                      {(fieldsForm.centralizedExam ||
                        fieldsForm.centralizedTest) && (
                        <div>
                          <ProfileSection
                            title={'Добавить документ'}
                            markRequired={true}
                            {...register(`resultEge.${index}.resultCentralTesting`, {
                              value: true,
                            })}
                          >
                            <FileUploader
                              {...register(`resultEge.${index}.doc`, {
                                required: {
                                  value:
                                    Boolean(fieldsForm.centralizedExam) ||
                                    Boolean(fieldsForm.centralizedTest),
                                  message: terms.REQUIRED_FIELD,
                                },
                              })}
                              {...register(`resultEge.${index}.doc_id`)}
                              files={watch(`resultEge.${index}.doc`) || []}
                              onDrop={(newFile) => {
                                api.client
                                  .uploadDocs({
                                    documents: newFile,
                                    typeDoc: 'CONFIRM_RESULT_EGE',
                                  })
                                  .then((response: UploadDocsResponse) => {
                                    const { data } = response as UploadDocsRespOk
                                    const { id } = data
                                    setValue(`resultEge.${index}.doc_id`, id)
                                    setValue(`resultEge.${index}.doc`, newFile)
                                  })
                              }}
                              onChange={(newFile) => {
                                api.client
                                  .uploadDocs({
                                    documents: newFile,
                                    typeDoc: 'CONFIRM_RESULT_EGE',
                                  })
                                  .then((response: UploadDocsResponse) => {
                                    const { data } = response as UploadDocsRespOk
                                    const { id } = data
                                    setValue(`resultEge.${index}.doc_id`, id)
                                    setValue(`resultEge.${index}.doc`, newFile)
                                  })
                              }}
                              onDelete={() => {
                                setValue(`resultEge.${index}.doc_id`, null)
                                setValue(`resultEge.${index}.doc`, [])
                              }}
                              className="profile-form__file-uploader"
                            />
                          </ProfileSection>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}

          {isBelarusPerson && watch('centralizedTest') && (
            <div className={'result-exam__btn'}>
              <Button
                type="button"
                onClick={() => {
                  append({ resultCentralTesting: true }, { shouldFocus: false })
                }}
                disabled={readOnly}
              >
                {terms.ADD_SUBJECT}
              </Button>
            </div>
          )}
        </>
      )}
    </FillingStatementsForm>
  )
}

export default ResultExamForm
