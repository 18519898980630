import React, { useEffect } from 'react'

import { columnsContracts } from '@common/table/moderator'
import TableList from '@components/TableList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  getFormEducation,
  setModeratorStatementsPage,
  setModeratorStatementsPerPage,
} from '@redux/action-types'
import {
  selectFormEducationOptions,
  selectModeratorContractsList,
  selectModeratorScenario,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'

function contractMapper(arr, scenarios, formEducation) {
  if (!arr) return []
  const rows: any[] = []
  arr.forEach((item) => {
    const user = item?.competitiveGroupStatement?.statement?.users
    const fullname: any = `${user?.usersTranslate[0]?.surname} ${
      user?.usersTranslate[0]?.name
    } ${user?.patronymic ?? ''}`
    rows.push({
      id: item?.id ?? '-',
      statementId: item?.contract?.competitiveGroupStatement?.statement?.id || '',
      fullName: fullname ?? '-',
      scenario: scenarios.find(
        (scenario) =>
          scenario?.value ==
          item?.competitiveGroupStatement?.competitiveGroup?.scenarioId
      )?.label,
      email: user?.email ?? '-',
      direction:
        `${item?.competitiveGroupStatement?.competitiveGroup?.directionTraining?.translate[0]?.name} (${item?.competitiveGroupStatement?.competitiveGroup?.profile?.translate[0]?.name})` ??
        '-',
      formEducationId: formEducation.find(
        (form) =>
          form?.value ==
          item?.competitiveGroupStatement?.competitiveGroup?.formEducationId
      )?.label,
      status:
        item?.competitiveGroupStatement?.statement?.statusStatement?.translate[0]
          ?.name ?? '-',
      statusContract: item?.status?.translate[0].name || '-',
      moderator: item?.responsible[0]?.user?.usersTranslate[0]?.name,
      partner:
        item?.competitiveGroupStatement?.statement?.users?.partner
          ?.partnerTranslate[0]?.name,
    })
  })
  return rows
}

const ModeratorContractList = () => {
  const dispatch = useAppDispatch()
  const scenarios = useAppSelector(selectModeratorScenario)
  const formEducation = useAppSelector(selectFormEducationOptions)
  const contractList = useAppSelector(selectModeratorContractsList) || []
  useEffect(() => {
    dispatch(getFormEducation())
  }, [])
  if (contractList.length === 0) {
    return <Loader />
  }
  const mappedData = contractMapper(contractList.data, scenarios, formEducation)

  if (mappedData.length === 0) {
    dispatch(setModeratorStatementsPage(1))
  }

  return (
    <div className={'moderator-contracts-list'}>
      <TableList
        link={'contracts'}
        mappedData={mappedData}
        columns={columnsContracts}
      />
      <Pagination
        lastPage={contractList.lastPage}
        currentPage={contractList.currentPage}
        perPage={contractList.perPage}
        setPage={setModeratorStatementsPage}
        setPerPage={setModeratorStatementsPerPage}
      />
    </div>
  )
}

export default ModeratorContractList
