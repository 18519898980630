import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import cn from 'classnames'

import TrashIcon from '@assets/images/Trash.svg'
import { WITHOUT_PREV_EDUCATION_CODE } from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useAppWithAdditionalConditions from '@hooks/useAppWithAdditionalConditions'
import useDirectionsTrainingElement from '@hooks/useDirectionsTrainingElement'
import useTrialsTypeForm from '@hooks/useTrialsTypeForm'
import {
  ChosenTrials,
  IndexForSelect,
  SelectOptionNumber,
  SelectOptionTrials,
  SelectOptionTrialTypes,
  ViewerDirectionsListStatements,
  ViewerTabEgeData,
} from '@models'
import { getProfileEducation } from '@redux/action-types'
import {
  selectLevelEducKeysOptions,
  selectLevelValue,
  selectStatementsElement,
  selectTrainingLevels,
  selectTrajectoryValue,
  selectViewerProfileEducation,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import ReactSelect from '@ui/Select/view'

type DirectionsTrainingElementProps = {
  item: ViewerDirectionsListStatements
  index: number
  readOnly: boolean
  chosenTrials: ChosenTrials[]
  selectNumberProps: IndexForSelect[]
  acceptId?: number
  first: number | true
  last: number | true
  tabEge: ViewerTabEgeData | null
  isNationalityBy: boolean
  scenarioKeys: any
}

const DirectionsTrainingElementStatement = ({
  item,
  selectNumberProps,
  tabEge,
  acceptId,
  readOnly,
  chosenTrials,
  isNationalityBy,
  scenarioKeys,
}: DirectionsTrainingElementProps) => {
  const { deleteElement, swap, openModal } = useDirectionsTrainingElement()
  const statementEl = useAppSelector(selectStatementsElement)
  const level = useAppSelector(selectLevelValue)
  const trajectory = useAppSelector(selectTrajectoryValue)
  const resultEge = tabEge?.resultEge
  const { control, setValue, getValues } = useTrialsTypeForm()
  const oldTrials = item.groupTrials
  const scenarioId = level?.scenarioId ? level?.scenarioId : level?.id
  const scenariosWithoutAcceptButton = [1, 2, 3, 4, 7, 9, 10]
  const isShowAcceptStatementButton =
    !scenariosWithoutAcceptButton.includes(scenarioId)

  const a = () => {
    if (oldTrials.length != 0) {
      oldTrials.map((oneTrial, index2) => {
        chosenTrials.push({
          trialId: oneTrial['trialId'],
          id: oneTrial['competitiveGroupStatementId'],
          typeTrialId: oneTrial['trialTypeId'],
          externalId: oneTrial['disciplineExternalId'],
        })
      })
    }
  }

  const dispatch = useAppDispatch()

  const { isAppWithAdditionalConditions } = useAppWithAdditionalConditions(item)

  useEffect(() => {
    !educationData && dispatch(getProfileEducation())
    a()
  }, [a])
  const educationData = useAppSelector(selectViewerProfileEducation)
  const levelsEducationCodes = useAppSelector(selectLevelEducKeysOptions)
  let schoolEducationCodes: any = []
  levelsEducationCodes.map((value) => {
    if (value.uniqueKeyOnSite == WITHOUT_PREV_EDUCATION_CODE) {
      schoolEducationCodes.push(value.onesKey)
    }
  })
  const schoolLevel = schoolEducationCodes

  if (item.pivot.number != null) {
    let priority: SelectOptionNumber[] = [
      {
        label: item.pivot.number,
        value: Number(item.pivot.number),
      },
    ]
    setValue(`resultEge.${item.pivot.id}.priority`, priority[0])
  } else {
    let priorityNull: SelectOptionNumber[] = [
      {
        label: '',
        value: 0,
      },
    ]
    setValue(`resultEge.${item.pivot.id}.priority`, priorityNull[0])
  }

  return (
    <div
      className={cn('directions-training-element', {
        ['special-quota']: item.specialQuota,
      })}
    >
      <div className={'row'}>
        <div>
          <h2>{item.directionTraining?.translate[0]?.name || '-'} </h2>
        </div>
        <div className={'directions-training-element__control-btn-container_btn'}>
          <Button
            type={'button'}
            onClick={() => deleteElement(item.id)}
            disabled={readOnly}
          >
            <TrashIcon />
          </Button>
        </div>
      </div>

      <div className={'row'}>
        <div className={'column'}>
          {item.specialQuota ? (
            <p>
              <b>{terms.SEPARATE_QUOTA}</b>
            </p>
          ) : item.benefits ? (
            <p>
              <b>{terms.SPECIAL_QUOTA}</b>
            </p>
          ) : null}
          <p>
            {terms.MODE_OF_STUDY}:{' '}
            <b>{item.formEducation?.formEducationTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.FORM_PAYMENT_ID}:{' '}
            <b>{item.formPayment?.translate[0]?.name || '-'}</b>
          </p>
        </div>
        <div className={'column'}>
          <p>
            {terms.LEVEL_OF_TRAINING}:{' '}
            <b>{item.trainingLevel?.trainingLevelTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.EDUCATIONAL_PROGRAM}:{' '}
            <b>{item.profile?.translate[0]?.name || '-'}</b>
          </p>
        </div>
      </div>
      <div className="row">
        <div className={'column'}>
          {isAppWithAdditionalConditions ? (
            <p>
              Данная специальность реализуется в следующих подразделениях: на
              факультете географии, факультете биологии, факультете химии, факультете
              безопасности жизнедеятельности, факультете математики, филологическом
              факультете; в институте физики, институте детства, институте русского
              языка как иностранного, институте иностранных языков, институте музыки,
              театра и хореографии, институте информационных технологий и
              технологического образования, институте истории и социальных наук.
            </p>
          ) : (
            <p>
              {terms.SUBDIVISION}:{' '}
              <b>{item.faculty?.facultyTranslate[0].name || '-'}</b>
            </p>
          )}
        </div>
      </div>
      <div className="row">
        {/*(trajectory.id === 2 &&
        (level.scenarioId == undefined
        ? level.id === LEVEL_TRAINING_PREPARATION
        : level.scenarioId === LEVEL_TRAINING_PREPARATION))*/}
        <div className="column column-flex-full">
          {!(trajectory.id === 3) && (
            <>
              <p>
                {terms.ENTRANCE_EXAMS} / {terms.PASSING_SCORE}
              </p>
              {item?.trials.length > 0 &&
                item.trials.map((trial, index) => {
                  let disciplineForSelect: SelectOptionTrials[] = []
                  let trialTypeForSelect: SelectOptionTrialTypes[] = []

                  if (trial.disciplineTrialDistinct.length === 0) {
                    return
                  }

                  const handleOption = (value) => {
                    if (value != undefined) {
                      trialTypeForSelect.length = 0
                      if (educationData?.levelEducation) {
                        if (
                          !schoolLevel.includes(
                            Number(educationData?.levelEducation.code)
                          )
                        ) {
                          trialTypeForSelect.push({
                            trialId: value.trialId,
                            value: 1,
                            trialTypeId: '1',
                            label: 'Вступительное испытание',
                            externalId: value.external_id,
                          })
                        }
                      }

                      resultEge?.map((discipline, index) => {
                        if (discipline.externalId == value.external_id) {
                          trialTypeForSelect.push({
                            trialId: value.trialId,
                            value: 2,
                            trialTypeId: '2',
                            label: isNationalityBy ? 'ЕГЭ/ЦТ/ЦЭ' : 'ЕГЭ',
                            externalId: value.external_id,
                          })
                        }
                      })

                      if (trialTypeForSelect.length == 0) {
                        trialTypeForSelect.push({
                          trialId: value.trialId,
                          value: 1,
                          trialTypeId: '1',
                          label: 'Вступительное испытание',
                          externalId: value.external_id,
                        })
                      }
                    }
                  }

                  // let oldTrials = item.groupTrials

                  let trialEmpty: SelectOptionTrialTypes[] = [
                    {
                      trialId: 0,
                      value: 0,
                      trialTypeId: '',
                      label: '',
                      externalId: '',
                    },
                  ]

                  trial?.disciplineTrialDistinct.map((discipline, index3) => {
                    disciplineForSelect.push({
                      value: index3,
                      trialId: discipline.trialId,
                      label: discipline?.name,
                      external_id: discipline?.externalId,
                      typeId: discipline?.trialTypeId,
                    })

                    if (oldTrials.length != 0) {
                      oldTrials.map((oneTrial, index2) => {
                        if (
                          oneTrial['disciplineExternalId'] == discipline.externalId
                        ) {
                          disciplineForSelect.map((disc) => {
                            if (
                              disc['external_id'] == oneTrial['disciplineExternalId']
                            ) {
                              setValue(`resultEge.${index}.disciplineEgeId`, disc)

                              let trialOld: SelectOptionTrialTypes[] = [
                                {
                                  trialId: 1,
                                  value: 1,
                                  trialTypeId: '1',
                                  label: 'Вступительное испытание',
                                  externalId: oneTrial['disciplineExternalId'],
                                },
                              ]
                              let trialOldEge: SelectOptionTrialTypes[] = [
                                {
                                  trialId: 2,
                                  value: 2,
                                  trialTypeId: '1',
                                  label: isNationalityBy ? 'ЕГЭ/ЦТ/ЦЭ' : 'ЕГЭ',
                                  externalId: oneTrial['disciplineExternalId'],
                                },
                              ]

                              trialTypeForSelect.length = 0
                              trialTypeForSelect.push({
                                trialId: oneTrial['trialId'],
                                value: 1,
                                trialTypeId: '1',
                                label: 'Вступительное испытание',
                                externalId: oneTrial['disciplineExternalId'],
                              })

                              resultEge?.map((disciplineEge, index) => {
                                if (
                                  disciplineEge.externalId ==
                                  oneTrial['disciplineExternalId']
                                ) {
                                  trialTypeForSelect.push({
                                    trialId: oneTrial['trialId'],
                                    value: 2,
                                    trialTypeId: '2',
                                    label: isNationalityBy ? 'ЕГЭ/ЦТ/ЦЭ' : 'ЕГЭ',
                                    externalId: oneTrial['disciplineExternalId'],
                                  })
                                }
                              })

                              if (oneTrial['trialTypeId'] == '1') {
                                setValue(`resultEge.${index}.trialType`, trialOld[0])
                              } else {
                                setValue(
                                  `resultEge.${index}.trialType`,
                                  trialOldEge[0]
                                )
                              }
                            }
                          })
                        }
                      })
                    } else {
                      const disciplineSelected = getValues(
                        `resultEge.${index}.disciplineEgeId`
                      )
                      if (disciplineSelected) {
                        handleOption(disciplineSelected)
                      }
                    }
                  })

                  const handleTrials = (value) => {
                    let changed = false
                    if (value != undefined) {
                      if (chosenTrials.length == 0) {
                        chosenTrials.push({
                          trialId: value.trialId,
                          id: item.pivot.id,
                          typeTrialId: value.trialTypeId,
                          externalId: value.externalId,
                        })
                      }
                      chosenTrials?.map((each, index) => {
                        //если поменялось вступительное
                        if (
                          each.externalId == value.externalId &&
                          each.id == item.id
                        ) {
                          each.typeTrialId = value.trialTypeId
                          changed = true
                        }
                        if (each.trialId == value.trialId) {
                          each.typeTrialId = value.trialTypeId
                          each.externalId = value.externalId
                          each.id = item.pivot.id
                          changed = true
                        }
                      })
                      if (!changed) {
                        chosenTrials.push({
                          trialId: value.trialId,
                          id: item.pivot.id,
                          typeTrialId: value.trialTypeId,
                          externalId: value.externalId,
                        })
                      }
                    }
                  }

                  return (
                    <div key={index}>
                      <div className="row">
                        <div className={'column-trial column-trial_item_trials'}>
                          <b>
                            {trial?.disciplineTrialDistinct.map(
                              (discipline, index3) =>
                                `${index3 !== 0 ? `/` : ''} ${discipline?.name} - ${
                                  level.id === scenarioKeys.level_training_spo.tdId
                                    ? discipline?.passingScore === 2
                                      ? 'Зачет'
                                      : discipline?.passingScore
                                    : discipline?.passingScore
                                }`
                            )}
                          </b>
                        </div>
                        <div className={'column-flex'}>
                          <Controller
                            name={`resultEge.${index}.disciplineEgeId`}
                            control={control}
                            render={({ field }) => (
                              <ReactSelect<SelectOptionTrials>
                                options={disciplineForSelect}
                                placeholder={terms.SUBJECT}
                                className="result-exam__element_field"
                                markRequired={true}
                                styles={{
                                  menu: (base) => ({ ...base, minWidth: '200px' }),
                                }}
                                {...(field as unknown)}
                                //  isClearable={true}
                                onChange={(e) => {
                                  console.log(e)
                                  handleOption(e)
                                  if (e) {
                                    setValue(
                                      `resultEge.${index}.trialType`,
                                      trialEmpty[0]
                                    )
                                    field.onChange(e)
                                  }
                                }}
                                isDisabled={readOnly}
                              />
                            )}
                          />
                        </div>
                        <div className={'column-flex'}>
                          <Controller
                            name={`resultEge.${index}.trialType`}
                            control={control}
                            render={({ field }) => (
                              <ReactSelect<SelectOptionTrialTypes>
                                options={trialTypeForSelect}
                                placeholder={terms.TRIAL_TYPE}
                                className="result-exam__element_field"
                                markRequired={true}
                                styles={{
                                  menu: (base) => ({ ...base, minWidth: '200px' }),
                                }}
                                {...(field as unknown)}
                                //  isClearable={true}
                                onChange={(e) => {
                                  handleTrials(e)
                                  if (e) {
                                    field.onChange(e)
                                  }
                                }}
                                isDisabled={readOnly}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/*))}*/}
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </div>
      <div className="row row_flex">
        <div className="directions-training-element__control-btn-container directions-training-element-priority">
          <div className={'directions-training-element__control-btn-container_btn'}>
            <div className="priority-number directions-training-element-priority">
              <div className={'column-flex'}>
                <Controller
                  name={`resultEge.${item.pivot.id}.priority`}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect<SelectOptionNumber>
                      options={selectNumberProps}
                      placeholder={terms.PRIORITY_GROUP}
                      className="result-exam__element_field"
                      markRequired={true}
                      styles={{
                        menu: (base) => ({ ...base, minWidth: '200px' }),
                      }}
                      {...(field as unknown)}
                      //  isClearable={true}
                      onChange={(e) => {
                        swap(item.id, e.value, true)
                        if (e) {
                          field.onChange(e)
                        }
                      }}
                      isDisabled={readOnly}
                    />
                  )}
                />
              </div>
              {/*{'Номер приоритета зачисления '}*/}
              {/*{item ? console.log(item.pivot) : 'console.log(item.pivot)'}*/}
              {/*{`${*/}
              {/*  item.pivot.number != null*/}
              {/*    ? item.pivot.number*/}
              {/*    : 'устанавливается стрелками'*/}
              {/*}`}*/}
            </div>
            {/*<Button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => swap(item.id, first, true)}*/}
            {/*  disabled={readOnly || first === true}*/}
            {/*>*/}
            {/*  <ArrowUp />*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => swap(item.id, last, true)}*/}
            {/*  disabled={readOnly || last === true}*/}
            {/*>*/}
            {/*  <ArrowDown />*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DirectionsTrainingElementStatement
