import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDocumentsPage from '../ModeratorDocumentsPage'
import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import {
  getDirectionTrainings,
  getModeratorStatementsEducationData,
} from '@redux/action-types'
import { selectModeratorStatementsEducationData } from '@redux/actions/selectors'
import { formatDateYYYYMMDD } from '@utils/date'

import './ModeratorEducationPage.scss'

const ModeratorEducationPage = () => {
  const dispatch = useAppDispatch()

  const { id, userId } = useParams<{ id?: string; userId?: string }>()
  useEffect(() => {
    if (id || userId) {
      dispatch(getModeratorStatementsEducationData({ id: id, userId: userId }))
    }
  }, [id, userId])

  const education = useAppSelector(selectModeratorStatementsEducationData) || []

  const list: DataList = [
    {
      title: 'Вид образования:',
      label:
        (education.length > 0 &&
          education[0]?.levelEducation?.levelTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Тип документа:',
      label:
        (education.length > 0 &&
          education[0]?.typeEducationDocument?.typeEduDocTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Серия и номер документа:',
      label: (education.length > 0 && education[0]?.numberEducationDocument) || '-',
    },
    {
      title: 'Дата выдачи:',
      label:
        education.length > 0 && education[0]?.dateDocumentIssue
          ? formatDateYYYYMMDD(education[0]?.dateDocumentIssue)
          : '-',
    },
    /*{
      title: 'Аттестат/Диплом с отличием:',
      label:
        (education.length > 0 && (education[0]?.diplomaWithHonors ? 'Да' : 'Нет')) ||
        '-',
    },*/
    // {
    //   title: 'Количество оценок «Удовлетворительно»:',
    //   label:
    //     (education.length > 0 &&
    //       education[0]?.userScore?.find((el) => el?.score?.code === 'PR-03-01')
    //         ?.count) ||
    //     '-',
    // },
    // {
    //   title: 'Количество оценок «Хорошо»:',
    //   label:
    //     (education.length > 0 &&
    //       education[0]?.userScore?.find((el) => el?.score?.code === 'OK-04-02')
    //         ?.count) ||
    //     '-',
    // },
    // {
    //   title: 'Количество оценок «Отлично»:',
    //   label:
    //     (education.length > 0 &&
    //       education[0]?.userScore?.find((el) => el?.score?.code === 'GR-05-03')
    //         ?.count) ||
    //     '-',
    // },
    {
      title: 'Наименование ОУ:',
      label: (education.length > 0 && education[0]?.nameEducationInstitution) || '-',
    },
    {
      title: 'Страна ОУ:',
      label:
        (education.length > 0 &&
          education[0]?.nationality?.nationalityTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Форма обучения ОУ:',
      label:
        (education.length > 0 &&
          education[0]?.formEducation?.formEducationTranslate[0].name) ||
        '-',
    },
    {
      title: 'Год окончания ОУ:',
      label: (education.length > 0 && education[0].yearGraduation) || '-',
    },
    {
      title: 'Обучался на подготовительных курсах РГПУ им А.И.Герцена:',
      label:
        (education.length > 0 &&
          (education[0]?.studiedPrepatoryDepartment ? 'Да' : 'Нет')) ||
        '-',
    },
    {
      title: 'Иностранный язык, изучавшийся ранее в образовательном учреждении:',
      label:
        (education.length > 0 && education[0]?.studiedLanguesTranslates.name) || '-',
    },
    {
      title: 'Направление/специальность предыдущего образования:',
      label:
        (education.length > 0 &&
          education[0]?.directionTraining?.translations[0]?.name) ||
        '-',
    },
    {
      title: 'Иное направление/ Специальность:',
      label:
        (education.length > 0 &&
          (education[0]?.noMatchDirectionTraining ? 'Да' : 'Нет')) ||
        '-',
    },
    {
      title: 'Являюсь выпускником психолого-педагогического класса:',
      label:
        (education.length > 0 &&
          (education[0]?.graduatePsychologicalAndPedagogicalClass ? 'Да' : 'Нет')) ||
        '-',
    },
  ]
  return (
    <div className="moderator__education-page">
      <ModeratorDataList list={list} />
      <ModeratorDocumentsPage
        params={[
          { typeDoc: 'education_document', title: 'Документ об образовании' },
          {
            typeDoc: 'scan_translation_doc_edu',
            title: 'Скан перевода документа об образовании',
          },
          {
            typeDoc: 'nostrification_edu',
            title:
              'Свидетельство о признании документа об образовании (нострификация)',
          },
          {
            typeDoc: 'other_document',
            title: 'Иные документы',
          },
        ]}
      />
    </div>
  )
}

export default ModeratorEducationPage
