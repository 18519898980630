import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  getPassportNumber,
  getPassportSeries,
  normalizeYearTo4Characters,
} from '../lib/validators'
import { SelectOption } from '../models/common'
import { ContractPayer, ContractPayerWithOptions } from '../models/statements'
import regexp from './../common/regexp'
import terms from './../common/terms'
import { selectTypeIdentityDocumentOptions } from './../redux/actions/selectors/profile'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import {
  createToast,
  getDirectionsListOptions,
  getModeratorContractInfo,
  getTypeIdentityDocuments,
} from '@redux/action-types'
import {
  selectContract,
  selectModeratorContractInfo,
} from '@redux/actions/selectors'
import api, { ResponseOk } from '@services/api'

const useChangePayerDetails = (
  competitiveGroupStatementId,
  payerTypeId,
  contractNotEntrants,
  passportIds,
  payerEmail
) => {
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<ContractPayerWithOptions>({ mode: 'all' })

  const { id: contractId } = useParams()
  const typeDocId = watch('typeIdentityDocumentId') as unknown as
    | SelectOption
    | undefined
  const isSeriesRequired =
    typeDocId?.value === passportIds.russia_citizen_passport_code.tdId
  const isRussianPassport =
    typeDocId?.value === passportIds.russia_citizen_passport_code.tdId
  const isRussianForeignPassport =
    typeDocId?.value === passportIds.russia_foreign_passport_code.tdId

  const typeIdentityDocumentOptions = useAppSelector(
    selectTypeIdentityDocumentOptions
  )
  !typeIdentityDocumentOptions.length && dispatch(getTypeIdentityDocuments())

  const fields = {
    surname: register('surname', { required: terms.REQUIRED_FIELD }),
    name: register('name', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    patronymic: register('patronymic'),
    birthDay: register('birthDate', {
      required: { value: true, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('birthDate', normalizeYearTo4Characters(value)),
    }),
    phone: register('phone', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    payerEmail: register('email', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    address: register('address', { required: terms.REQUIRED_FIELD }),
    addressResidential: register('addressResidential', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    placeOfBirth: register('placeOfBirth', { required: terms.REQUIRED_FIELD }),
    typeIdentityDocumentId: register('typeIdentityDocumentId', {
      required: terms.REQUIRED_FIELD,
    }),
    series: register('series', {
      required: isSeriesRequired ? terms.REQUIRED_FIELD : '',
      ...getPassportSeries(isSeriesRequired),
      pattern: {
        value:
          isRussianForeignPassport || isRussianPassport
            ? regexp.passportSeries
            : regexp.passportSeriesForeignSymbols,
        message:
          isRussianForeignPassport || isRussianPassport
            ? terms.PASSPORT_SERIES_LENGTH
            : terms.LATIN_CYRILLIC_CHARACTERS_AND_NUMBER_ONLY,
      },
    }),
    number: register('number', {
      required: { message: terms.REQUIRED_FIELD, value: true },
      ...getPassportNumber(isRussianPassport),
      pattern: {
        value:
          isRussianForeignPassport || isRussianPassport
            ? regexp.passportNumber
            : regexp.passportNumberForeign,
        message:
          isRussianForeignPassport || isRussianPassport
            ? terms.PASSPORT_SERIES_LENGTH
            : terms.ONLY_NUMBER,
      },
    }),
    whenIssued: register('whenIssued', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('whenIssued', normalizeYearTo4Characters(value)),
    }),
    issuedByWhom: register('issuedByWhom', { required: terms.REQUIRED_FIELD }),
  }

  useEffect(() => {
    if (contractNotEntrants) {
      initializationData()
    }
    if (contractNotEntrants && typeIdentityDocumentOptions.length > 0) {
      const optTypeDoc = typeIdentityDocumentOptions.filter(
        (item) => item.id === contractNotEntrants?.typeIdentityDocumentId
      )
      setValue('typeIdentityDocumentId', {
        label: optTypeDoc[0].label,
        value: optTypeDoc[0].value,
      })
    }
  }, [contractNotEntrants, typeIdentityDocumentOptions.length])

  function initializationData() {
    if (contractNotEntrants) {
      setValue('surname', contractNotEntrants?.surname || '')
      setValue('name', contractNotEntrants?.name || '')
      setValue('patronymic', contractNotEntrants?.patronymic || '')
      setValue('birthDate', contractNotEntrants?.birthDate || '')
      setValue('phone', contractNotEntrants?.phone || '')
      setValue('email', payerEmail || '')
      setValue('address', contractNotEntrants?.address || '')
      setValue('addressResidential', contractNotEntrants?.addressResidential || '')
      setValue('placeOfBirth', contractNotEntrants?.placeOfBirth || '')
      setValue('series', contractNotEntrants?.series || '')
      setValue('number', contractNotEntrants?.number || '')
      setValue('whenIssued', contractNotEntrants?.whenIssued || '')
      setValue('issuedByWhom', contractNotEntrants?.issuedByWhom || '')
    }
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: ContractPayer = {
      ...data,
      competitive_group_statement_id: competitiveGroupStatementId,
      contract_id: contractId,
      payer_type_id: payerTypeId,
      typeIdentityDocumentId: data.typeIdentityDocumentId.value,
    }

    data.typeIdentityDocumentId.value ===
    passportIds.russia_citizen_passport_code.tdId
      ? delete normalizedData.validityPeriod
      : null

    api.client
      .editContractModerator(normalizedData)
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(getModeratorContractInfo(String(contractId)))
      })
  })

  return {
    fields,
    onSubmit,
    getValues,
    typeIdentityDocumentOptions,
    ...rest,
  }
}

export default useChangePayerDetails
