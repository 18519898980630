import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useJuridicaContractForm from '@hooks/useJuridicalContractForm'
import { SelectOption } from '@models'
import { getDirectionsListOptions } from '@redux/action-types'
import { selectDirectionListOptions } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import Select from '@ui/Select'

const JuridicalContractForm = ({
  payerTypeId,
  contract,
  trajectoryId,
}: {
  payerTypeId: number
  trajectoryId?: number
  contract?: any
}) => {
  const { statementId } = useParams()

  const {
    onSubmit,
    fields,
    formState: { errors },
    control,
    setValue,
    forIno,
  } = useJuridicaContractForm(contract, trajectoryId, statementId)

  const dispatch = useAppDispatch()

  const directionOptions = useAppSelector(selectDirectionListOptions)
  let filterOptions = directionOptions.filter((item) => item.accessСonclusion)

  useEffect(() => {
    dispatch(getDirectionsListOptions(String(statementId)))
    if (contract) {
      setValue('contractId', contract.id)
    }
    setValue('payerTypeId', payerTypeId)
    if (statementId) {
      setValue('statementId', Number(statementId))
    }
  }, [])

  return (
    <ProfileForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <ProfileSection title="">
        <div className="profile-form__fields contract">
          <Controller
            name="directionTrainingId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={
                  trajectoryId === 2
                    ? filterOptions.filter(
                        (option: any) => option.formPaymentCode === '2'
                      )
                    : filterOptions
                }
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.directionTrainingId?.message}
              />
            )}
          />
          {forIno && (
            <Checkbox
              text="Планирую брать образовательный кредит"
              className="profile-form__checkbox"
              {...fields.eduCredit}
            />
          )}
        </div>

        <div className="profile-form__fields">
          <Input
            className="profile-form__field"
            placeholder="Организация"
            {...fields.organization}
            error={errors.organization?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="ФИО руководителя"
            {...fields.fullNameLeader}
            error={errors.fullNameLeader?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="Руководитель действует на основании"
            {...fields.managementBases}
            error={errors.managementBases?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="ИНН организации"
            {...fields.iin}
            error={errors.iin?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="КПП организации"
            {...fields.kpp}
            error={errors.kpp?.message}
          />
          <Input
            placeholder="Наименование банка"
            className="profile-form__field"
            {...fields.nameBank}
            error={errors.nameBank?.message}
          />
          <Input
            placeholder="БИК банка"
            className="profile-form__field"
            {...fields.bankIdentifierCode}
            error={errors.bankIdentifierCode?.message}
          />
          <Input
            placeholder="Расчетный счет"
            className="profile-form__field"
            {...fields.paymentAccount}
            error={errors.paymentAccount?.message}
          />
          <Input
            placeholder="Корреспондентский счет"
            className="profile-form__field"
            {...fields.correspondentAccount}
            error={errors.correspondentAccount?.message}
          />
          <Input
            placeholder="Город банка"
            className="profile-form__field"
            {...fields.cityBank}
            error={errors.cityBank?.message}
          />
          <Input
            placeholder="Телефон"
            type="tel"
            className="profile-form__field"
            {...fields.phone}
            error={errors.phone?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="Юридический адрес организации"
            {...fields.legalAddress}
            error={errors.legalAddress?.message}
          />
          <Input
            placeholder="E-mail"
            type="email"
            className="profile-form__field"
            {...fields.email}
            error={errors.email?.message}
          />
          <Input
            placeholder="Фактический адрес нахождения организации"
            className="profile-form__field"
            {...fields.organizationAddress}
            error={errors.organizationAddress?.message}
          />
        </div>
      </ProfileSection>
      {/* TODO: rename class  */}
      <span className="contract__create">
        Заполните раздел, если Вы планируете поступать на платное обучение
      </span>
      <Button className="create-contract-form__button" theme="success">
        {terms.SUBMIT_CONTRACT_FOR_APPROVAL}
      </Button>
    </ProfileForm>
  )
}

export default JuridicalContractForm
