import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import classnames from 'classnames'

import profileImagePlaceholder from '@assets/images/profile-image-placeholder.png'
import { RUSSIA_NATIONAL_ID } from '@common/constants'
import terms from '@common/terms'
import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getModeratorStatementsPersonalData } from '@redux/action-types'
import { selectModeratorStatementsPersonalData } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import { formatDateYYYYMMDD } from '@utils/date'
import RadioButton from '@ui/RadioButton'

const ModeratorBasicDataPage = () => {
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id?: string }>()
  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsPersonalData(id))
    }
  }, [id])

  const statement = useAppSelector(selectModeratorStatementsPersonalData) || {}

  const image = statement?.users?.photo
    ? `data:image/jpeg;base64,${statement?.users?.photo}`
    : null

  const smallList: DataList = [
    {
      title: 'Гражданство:',
      label:
        statement?.users?.userNational[0]?.nationality?.nationalityTranslate[0]
          ?.name || '-',
    },
    {
      title: 'Иностранец:',
      label:
        statement?.users?.userNational[0]?.nationality.id === RUSSIA_NATIONAL_ID
          ? 'Нет'
          : 'Да',
    },
    {
      title: 'Нет гражданства',
      label:
        statement?.users?.userNational[0]?.nationality?.code === '0' ? 'Да' : 'Нет',
    },
    {
      title: 'Соотечественник:',
      label: statement?.users?.compatriot ? 'Да' : 'Нет',
    },
  ]
  const list: DataList = [
    {
      title: 'Сценарий поступления:',
      label: statement?.scenario?.scenarioTranslate[0]?.name || '-',
    },
    {
      title: 'ID в системе:',
      label: statement?.users?.id || '-',
    },
    {
      title: 'Email:',
      label: statement?.users?.email || '-',
    },
    {
      title: 'Фамилия:',
      label:
        statement?.users?.usersTranslate?.find((el) => el.localeId === 1)?.surname ||
        '-',
    },
    {
      title: 'Имя:',
      label:
        statement?.users?.usersTranslate?.find((el) => el.localeId === 1)?.name ||
        '-',
    },
    {
      title: 'Отчество:',
      label:
        statement?.users?.usersTranslate?.find((el) => el.localeId === 1)
          ?.patronymic || '-',
    },
    {
      title: 'Дата рождения:',
      label: statement?.users?.dateBirthday
        ? formatDateYYYYMMDD(statement?.users?.dateBirthday)
        : '-',
    },
    {
      title: 'Пол:',
      label: statement?.users?.gender?.name || '-',
    },
    {
      title: 'Семейное положение:',
      label: statement?.users?.maritalStatus?.name || '-',
    },
    {
      title: 'Количество детей:',
      label: String(statement?.users?.amountChild) || '-',
    },
    {
      title: 'Работа:',
      label: statement?.users?.work?.translate[0].name || '-',
    },
    {
      title: 'Код страны:',
      label: statement?.users?.mobile?.split(' ')[0] || '-',
    },
    {
      title: 'Мобильный телефон:',
      label: statement?.users?.mobile || '-',
    },
    {
      title: 'СНИЛС:',
      label: statement?.users?.snils || '-',
    },
    {
      title: 'Дата СНИЛС:',
      label: statement?.users?.dateSnils
        ? formatDateYYYYMMDD(statement?.users?.dateSnils)
        : '-',
    },
    {
      title: 'Партнерская организация:',
      label: statement?.users?.partner?.partnerTranslate[0]?.name || '-',
    },
    {
      title: 'Код страны партнерской организации:',
      label: statement?.users?.partnerOrganizationPhone?.split(' ')[0] || '-',
    },
    {
      title: 'Телефон партнерской организации:',
      label: statement?.users?.partnerOrganizationPhone || '-',
    },
    {
      title: 'Email партнерской организации:',
      label: statement?.users?.partnerOrganizationEmail || '-',
    },
    {
      title: 'Дополнительный email:',
      label: statement?.users?.dopEmail || '-',
    },
    {
      title: 'Код страны дополнительного телефона:',
      label: statement?.users?.additionalMobile?.split(' ')[0] || '-',
    },
    {
      title: 'Дополнительный телефон:',
      label: statement?.users?.additionalMobile || '-',
    },
  ]
  console.log(statement?.users?.userNational[0]?.nationality?.code, 'statement')
  const englishName = [
    {
      title: 'Фамилия латинскими буквами:',
      label:
        statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)
          ?.surname || '-',
    },
    {
      title: 'Имя латинскими буквами:',
      label:
        statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)?.name ||
        '-',
    },
    {
      title: 'Отчество латинскими буквами:',
      label:
        statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)
          ?.patronymic || '-',
    },
  ]

  if (
    statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)?.name ||
    statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)?.surname ||
    statement?.users?.usersTranslate?.find((el) => el?.localeId === 2)?.patronymic
  ) {
    list.splice(6, 0, ...englishName)
  }

  return (
    <div className="moderator-basic-data-page moderator-data-list">
      <div
        className={classnames(
          'moderator-basic-data-page__image',
          'profile-image-block'
        )}
      >
        <div className="profile-image-block__img-wrapper">
          <img
            src={image || profileImagePlaceholder}
            alt={terms.PHOTO}
            className="profile-image-block__img"
          />
        </div>
        <ModeratorDataList list={smallList} />
      </div>
      <ModeratorDataList list={list} />
      <RadioButton className="profile-form__checkbox">
        <div className="input-container__placeholder--required">
          <b>{terms.IN_RUSSIA}</b>
        </div>
        <RadioButton.Radio
          text={terms.YES}
          value="1"
          name="inRussia"
          checked={statement?.users?.inRussia}
        />
        <RadioButton.Radio
          text={terms.NO}
          value="0"
          name="inRussia"
          checked={!statement?.users?.inRussia}
        />
      </RadioButton>
      <Checkbox
        text={'Нет отчества'}
        checked={
          !statement?.users?.usersTranslate?.find((el) => el.localeId === 1)
            ?.patronymic || false
        }
        readOnly
      />
      <Checkbox
        text={'СНИЛС без даты'}
        checked={(statement?.users && statement?.users.noDateSnils) || false}
        readOnly
      />
      <Checkbox
        text={'Нет СНИЛС'}
        checked={(statement?.users && statement?.users.noSnils) || false}
        readOnly
      />
      <div className={'file-snils-block '}>
        <ModeratorDocumentsPage
          params={[{ typeDoc: 'snils', title: 'Копия снилс' }]}
        />
      </div>
    </div>
  )
}

export default ModeratorBasicDataPage
