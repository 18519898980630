import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import RequireAuth from 'containers/RequireAuth'
import ModeratorAchievementsPage from 'pages/moderator/ModeratorAchievementsPage'
import ModeratorConclusionBaseDepartmentPage from 'pages/moderator/ModeratorConclusionBaseDepartmentPage'

import AdminAuth from '../../containers/AdminAuth'
import ModeratorAuth from '../../containers/ModeratorAuth'
import UserIdAdminAuth from '../../containers/UserIdAdminAuth'
import {
  ASPERATNUTA_RF,
  BACHELOR_INO,
  BACHELOR_RF,
  BACHELOR_TASHKENT,
  MAGISTRA_INO,
  MAGISTRA_RF,
  MAGISTRA_TASHKENT,
  QUOTA_ID,
  SPO_ID,
  TRAINING_PREPARATION,
} from '@common/constants'
import Navbar from '@components/Navbar'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useAuthInitialization from '@hooks/useAuthInitialization'
import AdditionalFilesPage from '@pages/AdditionalFilesPage'
import AdministrationPage from '@pages/admin/AdministrationPage'
import DirectoryPage from '@pages/admin/DirectoryPage'
import LogPage from '@pages/admin/LogPage'
import SettingAuthPage from '@pages/admin/SettingAuthPage'
import SettingExchange1CPage from '@pages/admin/SettingExchange1CPage'
import SettingPage from '@pages/admin/SettingPage'
import UsersPage from '@pages/admin/UsersPage'
import ConfirmAccountPage from '@pages/ConfirmAccountPage'
import DirectionTrainings from '@pages/DirectionTrainings'
import DirectoryInfo from '@pages/Directories/DirectoryInfo'
import LegalRepresentativePage from '@pages/LegalRepresentativePage'
import ModeratorCertificatePage from '@pages/moderator/CertificatePage'
import DirectionTrainingsModerator from '@pages/moderator/DirectionTrainings'
import ModerationLegalRepresentative from '@pages/moderator/ModerationLegalRepresentative/view'
import ModeratorActualAddressPage from '@pages/moderator/ModeratorActualAddressPage'
import ModeratorAdditionalFormsPage from '@pages/moderator/ModeratorAdditionalFormsPage'
import ModeratorAdditionalInfoPage from '@pages/moderator/ModeratorAdditionalInfoPage'
import ModeratorBasicDataPage from '@pages/moderator/ModeratorBasicDataPage'
import ModeratorCompatriotDataPage from '@pages/moderator/ModeratorCompatriotDataPage'
import ModeratorContractIdPage from '@pages/moderator/ModeratorContractIdPage'
import ModeratorContractPage from '@pages/moderator/ModeratorContractPage'
import ModeratorDirectionsPage from '@pages/moderator/ModeratorDirectionsPage'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import ModeratorDownloadsPage from '@pages/moderator/ModeratorDownloadsPage'
import ModeratorEducationPage from '@pages/moderator/ModeratorEducationPage'
import ModeratorEntranceExamsPage from '@pages/moderator/ModeratorEntranceExamsPage'
import ModeratorExamResultPage from '@pages/moderator/ModeratorExamResultPage'
import ModeratorPassportDataPage from '@pages/moderator/ModeratorPassportDataPage'
import ModeratorPersonalInformationIdPage from '@pages/moderator/ModeratorPersonalInformationIdPage'
import ModeratorPersonalInformationPage from '@pages/moderator/ModeratorPersonalInformationPage'
import ModeratorPrivilegesPage from '@pages/moderator/ModeratorPrivilegesPage'
import ModeratorRegistrationAddressPage from '@pages/moderator/ModeratorRegistrationAddressPage'
import ModeratorStatementsIdPage from '@pages/moderator/ModeratorStatementsIdPage'
import ModeratorStatementsPage from '@pages/moderator/ModeratorStatementsPage'
import ModeratorTargetContractPage from '@pages/moderator/ModeratorTargetContractPage'
import SupportBasicDataPage from '@pages/moderator/SupportBasicDataPage'
import Visa from '@pages/moderator/VisaPage'
import ModeratorSignInPage from '@pages/ModeratorSignInPage'
import NotFound404 from '@pages/NotFound404'
import AdditionalInformationPage from '@pages/profile/AdditionalInformation'
import CompatriotDataPage from '@pages/profile/CompatriotDataPage'
import EducationDataPage from '@pages/profile/EducationDataPage'
import PassportDataPage from '@pages/profile/PassportDataPage'
import PersonalDataPage from '@pages/profile/PersonalDataPage'
import ProfilePage from '@pages/profile/ProfilePage'
import ResidencePage from '@pages/profile/ResidencePage'
import VisaPage from '@pages/profile/VisaPage'
import ResetPasswordPage from '@pages/ResetPasswordPage'
import SignInAspPage from '@pages/SignInAspPage'
import SignInInoPage from '@pages/SignInInoPage'
import SignInPage from '@pages/SignInPage'
import SignInRusPage from '@pages/SignInRusPage'
import SignUpAspPage from '@pages/SignUpAspPage'
import SignUpInoPage from '@pages/SignUpInoPage'
import SignUpPage from '@pages/SignUpPage'
import SignUpRusPage from '@pages/SignUpRusPage'
import AchievementsPage from '@pages/statements/AchievementsPage'
import CertificatePage from '@pages/statements/CertificatePage'
import ConclusionBaseDepartment from '@pages/statements/ConclusionBaseDepartment'
import ContractListPage from '@pages/statements/ContractListPage'
import ContractPage from '@pages/statements/ContractPage'
import CreateContractPage from '@pages/statements/CreateContractPage'
import DirectionsTrainingCreatePage from '@pages/statements/DirectionsTrainingCreatePage'
import DirectionsTrainingListPage from '@pages/statements/DirectionsTrainingListPage'
import DirectionsTrainingPriorityListPage from '@pages/statements/DirectionsTrainingListPriorityPage'
import DirectionsTrainingPage from '@pages/statements/DirectionsTrainingPage'
import DownloadsPage from '@pages/statements/DownloadsPage'
import EntranceExamsPage from '@pages/statements/EntranceExamsPage'
import FillingStatementsPage from '@pages/statements/FillingStatementsPage'
import LevelPage from '@pages/statements/LevelPage'
import LevelPaymentFormPage from '@pages/statements/LevelPaymentFormPage'
import PrivilegesAndSpecialConditionsPage from '@pages/statements/PrivilegesAndSpecialConditionsPage'
import PrivilegesPage from '@pages/statements/PrivilegesPage'
import ResultsExamPage from '@pages/statements/ResultsExamPage'
import StatementIdPage from '@pages/statements/StatementIdPage'
import StatementsListPage from '@pages/statements/StatementsListPage'
import StatementsPage from '@pages/statements/StatementsPage'
import TargetAgreementPage from '@pages/statements/TargetAgreementPage'
import TrajectoryPage from '@pages/statements/TrajectoryPage'
import IncomingStudents from '@pages/students/IncomingStudents'
import ListStudents from '@pages/students/ListStudents'
import ViewerLayout from '@pages/ViewerLayout'
import {
  getLevelEducationKeys,
  getPassportKeys,
  getScenarioKeys,
} from '@redux/action-types'
import { selectLevelEducKeysOptions } from '@redux/actions/selectors'

const Routing = () => {
  useAuthInitialization()
  const [keys, setKeys] = useState()
  const [passportkeys, setPassportKeys] = useState()
  const dispatch = useAppDispatch()

  let sKeys
  useEffect(() => {
    dispatch(getScenarioKeys('Scenario')).then((res) => setKeys(res.payload))
    dispatch(getLevelEducationKeys('levelEducation'))
  }, [])
  useEffect(() => {
    dispatch(
      getPassportKeys({
        model: 'TypeDocument',
        unique_keys: [49, 90, 128, 153, 152, 47, 89],
      })
    ).then((res) => setPassportKeys(res.payload))
  }, [])
  if (!keys || !passportkeys) {
    return <div></div>
  }

  sKeys = keys
  /**
   *  интеграция кодов сценариев с таблицы service_keys
   *  где хранятся соответствия между захардкжеными кодами на сайте и кодами сценариев с 1с
   */
  let scenarioKeys = {
    level_training_spo: sKeys.find((value) => value.uniqueKeyOnSite == SPO_ID),
    level_training_preparation: sKeys.find(
      (value) => value.uniqueKeyOnSite == TRAINING_PREPARATION
    ),
    level_training_bachelor: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_RF
    ),
    level_training_bachelor_ino: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_INO
    ),
    level_training_bachelor_tashkent: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_TASHKENT
    ),
    magistra_tashkent: sKeys.find(
      (value) => value.uniqueKeyOnSite == MAGISTRA_TASHKENT
    ),
    magistra_rf: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_RF),
    magistra_ino: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_INO),
    asperatnuta_rf: sKeys.find((value) => value.uniqueKeyOnSite == ASPERATNUTA_RF),
    quota: sKeys.find((value) => value.uniqueKeyOnSite == QUOTA_ID),
  }
  let docKeys
  let PassportKeys
  docKeys = passportkeys
  PassportKeys = {
    foreign_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 49
    ),
    identity_card_stateless_person_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 90
    ),
    other_document_code: docKeys.find((value) => value.uniqueKeyOnSite == 128),
    passport_non_citizen_code: docKeys.find((value) => value.uniqueKeyOnSite == 153),
    russia_certificate_temporary_asylum_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 152
    ),
    russia_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 47
    ),
    russia_foreign_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 89
    ),
  }
  return (
    <Routes>
      <Route path="/confirm-account/:code" element={<ConfirmAccountPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />

      {/*<Route path="/sign-in" element={<SignInPage />} />*/}
      <Route path="/sign-in" element={<SignInRusPage />} />
      <Route path="/rus-sign-in" element={<SignInRusPage />} />
      <Route path="/asp-sign-in" element={<SignInAspPage />} />
      <Route path="/ino-sign-in" element={<SignInInoPage />} />

      <Route path="/rus-sign-in/:code" element={<SignInRusPage />} />
      <Route path="/asp-sign-in/:code" element={<SignInAspPage />} />
      <Route path="/ino-sign-in/:code" element={<SignInInoPage />} />

      {/*<Route path="/sign-up" element={<SignUpPage />} />*/}
      <Route path="/sign-up" element={<SignUpRusPage />} />
      <Route path="/rus-sign-up" element={<SignUpRusPage />} />
      <Route path="/asp-sign-up" element={<SignUpAspPage />} />
      <Route path="/ino-sign-up" element={<SignUpInoPage />} />

      <Route
        path="/applicants"
        element={
          <>
            <Navbar moderator={false} />
            <IncomingStudents />
          </>
        }
      />
      <Route
        path="/applicants/:scenario"
        element={
          <>
            <Navbar moderator={false} />
            <ListStudents />
          </>
        }
      />

      <Route
        path="/applicants/competitive/:scenario"
        element={
          <>
            <Navbar moderator={false} />
            <ListStudents />
          </>
        }
      />

      <Route
        path="/"
        element={
          <RequireAuth>
            <ViewerLayout moderator={false} />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="personal-information" />} />
        <Route path="personal-information" element={<ProfilePage />}>
          <Route index element={<Navigate to="basic-data" />} />
          <Route path="basic-data" element={<PersonalDataPage />} />
          <Route path="compatriot-data" element={<CompatriotDataPage />} />
          <Route
            path="passport-data"
            element={<PassportDataPage {...PassportKeys} />}
          />
          <Route path="educational-background" element={<EducationDataPage />} />
          <Route path="visa" element={<VisaPage />} />
          <Route
            path="additional-information"
            element={<AdditionalInformationPage />}
          />
          <Route path="address" element={<ResidencePage />} />
          <Route path="legal-representative" element={<LegalRepresentativePage />} />
        </Route>

        <Route path="application" element={<StatementsPage />}>
          <Route index element={<Navigate to="create" />} />
          <Route path="create" element={<StatementsListPage {...scenarioKeys} />} />
          <Route path="trajectory" element={<TrajectoryPage />} />
          <Route path="training-level" element={<LevelPage {...scenarioKeys} />} />
          <Route path="payment-type-training" element={<LevelPaymentFormPage />} />
          <Route path=":statementId" element={<StatementIdPage />}>
            <Route index element={<Navigate to="filling-statements" />} />
            <Route
              path="filling-statements"
              element={<FillingStatementsPage {...scenarioKeys} />}
            >
              <Route path="directionTrainings" element={<DirectionTrainings />} />
              <Route index element={<Navigate to="exam-results" />} />
              <Route path="exam-results" element={<ResultsExamPage />} />
              <Route
                path="conclusion-base-department"
                element={<ConclusionBaseDepartment />}
              />
              <Route path="directions" element={<DirectionsTrainingPage />}>
                <Route
                  path=""
                  element={<DirectionsTrainingListPage {...scenarioKeys} />}
                />
                <Route
                  path="create"
                  element={<DirectionsTrainingCreatePage {...scenarioKeys} />}
                />
              </Route>
              <Route
                path="achievements"
                element={<AchievementsPage {...scenarioKeys} />}
              />
              <Route path="certificate" element={<CertificatePage />} />
              <Route
                path="privileges"
                element={<PrivilegesPage {...scenarioKeys} />}
              />
              <Route
                path="target"
                element={<TargetAgreementPage {...scenarioKeys} />}
              />
              <Route
                path="benefitsAndSpecialConditions"
                element={<PrivilegesAndSpecialConditionsPage {...scenarioKeys} />}
              />
              <Route
                path="directionsPriority"
                element={<DirectionsTrainingPriorityListPage {...scenarioKeys} />}
              />
              <Route path="contract" element={<ContractPage />}>
                <Route path="" element={<ContractListPage {...scenarioKeys} />} />
                <Route path="create" element={<CreateContractPage />} />
                <Route path="edit/:contractId" element={<CreateContractPage />} />
              </Route>
              <Route path="blank" element={<DownloadsPage {...scenarioKeys} />} />
              <Route path="test" element={<EntranceExamsPage {...scenarioKeys} />} />
            </Route>
          </Route>
        </Route>
        <Route path="additional-files" element={<AdditionalFilesPage />} />
        <Route path="applicants" element={<IncomingStudents />} />
        <Route path="applicants/:scenario" element={<ListStudents />} />
      </Route>

      <Route path="/admin/sign-in" element={<ModeratorSignInPage />} />
      <Route
        path="/admin"
        element={
          <ModeratorAuth>
            <ViewerLayout moderator={true} />
          </ModeratorAuth>
        }
      >
        <Route path="statements" element={<ModeratorStatementsPage />} />
        <Route path="statements/:id" element={<ModeratorStatementsIdPage />}>
          <Route index element={<Navigate to="basic-data" />} />
          <Route path="basic-data" element={<ModeratorBasicDataPage />} />
          <Route path="passport-data" element={<ModeratorPassportDataPage />} />
          <Route path="actual-address" element={<ModeratorActualAddressPage />} />
          <Route path="compatriot-data" element={<ModeratorCompatriotDataPage />} />
          <Route
            path="directionTrainings"
            element={<DirectionTrainingsModerator />}
          />
          <Route
            path="registration-address"
            element={<ModeratorRegistrationAddressPage />}
          />
          <Route
            path="educational-background"
            element={<ModeratorEducationPage />}
          />
          <Route
            path="achievements"
            element={<ModeratorAchievementsPage {...scenarioKeys} />}
          />
          <Route
            path="privileges"
            element={<ModeratorPrivilegesPage {...scenarioKeys} />}
          />
          <Route path="exam-results" element={<ModeratorExamResultPage />} />
          <Route path="test" element={<ModeratorEntranceExamsPage />} />
          <Route path="directions" element={<ModeratorDirectionsPage />} />
          <Route path="target-contract" element={<ModeratorTargetContractPage />} />
          <Route path="additional-info" element={<ModeratorAdditionalInfoPage />} />
          <Route
            path="additional-forms"
            element={<ModeratorAdditionalFormsPage />}
          />
          <Route path="downloads" element={<ModeratorDownloadsPage />} />
          <Route
            path="legal-representative"
            element={<ModerationLegalRepresentative />}
          />
          <Route path="visa" element={<Visa />} />
          <Route path="certificate" element={<ModeratorCertificatePage />} />
          <Route
            path="conclusion-base-department"
            element={<ModeratorConclusionBaseDepartmentPage />}
          />
        </Route>

        <Route
          path="personal-information"
          element={<ModeratorPersonalInformationPage />}
        />
        <Route
          path="personal-information/:userId"
          element={<ModeratorPersonalInformationIdPage />}
        >
          <Route index element={<Navigate to="basic-data" />} />
          <Route path="basic-data" element={<SupportBasicDataPage />} />
          <Route path="passport-data" element={<ModeratorPassportDataPage />} />
          <Route path="actual-address" element={<ModeratorActualAddressPage />} />
          <Route path="compatriot-data" element={<ModeratorCompatriotDataPage />} />
          <Route
            path="registration-address"
            element={<ModeratorRegistrationAddressPage />}
          />
          <Route
            path="educational-background"
            element={<ModeratorEducationPage />}
          />
        </Route>

        <Route path="contracts" element={<ModeratorContractPage />} />
        <Route path="contracts/:id" element={<ModeratorContractIdPage />} />

        <Route path="applicants" element={<IncomingStudents />} />
        <Route path="applicants/:scenario" element={<ListStudents />} />
        <Route
          path="administration"
          element={
            <AdminAuth>
              <AdministrationPage />
            </AdminAuth>
          }
        />
        <Route
          path="directory"
          element={
            <AdminAuth>
              <DirectoryPage />
            </AdminAuth>
          }
        />
        <Route
          path="settings"
          element={
            <AdminAuth>
              <SettingPage />
            </AdminAuth>
          }
        />
        <Route
          path="settings/auth"
          element={
            <AdminAuth>
              <SettingAuthPage />
            </AdminAuth>
          }
        />
        <Route
          path="settings/exchange"
          element={
            <AdminAuth>
              <SettingExchange1CPage />
            </AdminAuth>
          }
        />
        <Route
          path="directory/:directoryName-:directoryId"
          element={
            <AdminAuth>
              <DirectoryInfo />
            </AdminAuth>
          }
        />
        <Route
          path="log"
          element={
            <AdminAuth>
              <LogPage />
            </AdminAuth>
          }
        />
        <Route path="users" element={<UsersPage />} />
      </Route>

      <Route
        path="/admin/users/:userId"
        element={
          <UserIdAdminAuth>
            <ViewerLayout userIdAdmin={true} />
          </UserIdAdminAuth>
        }
      >
        <Route index element={<Navigate to="info/personal-information" />} />
        <Route path="info/personal-information" element={<ProfilePage />}>
          <Route index element={<Navigate to="basic-data" />} />
          <Route path="basic-data" element={<PersonalDataPage />} />
          <Route path="compatriot-data" element={<CompatriotDataPage />} />
          <Route
            path="passport-data"
            element={<PassportDataPage {...PassportKeys} />}
          />
          <Route path="educational-background" element={<EducationDataPage />} />
          <Route path="address" element={<ResidencePage />} />
          <Route path="legal-representative" element={<LegalRepresentativePage />} />
          <Route path="visa" element={<VisaPage />} />
          <Route
            path="additional-information"
            element={<AdditionalInformationPage />}
          />
          <Route path="certificate" element={<CertificatePage />} />
        </Route>
        <Route path="statement/:statementId" element={<StatementIdPage />}>
          <Route index element={<Navigate to="filling-statements" />} />
          <Route
            path="filling-statements"
            element={<FillingStatementsPage {...scenarioKeys} />}
          >
            <Route path="directionTrainings" element={<DirectionTrainings />} />
            <Route
              path="benefitsAndSpecialConditions"
              element={<PrivilegesAndSpecialConditionsPage {...scenarioKeys} />}
            />
            <Route
              path="conclusion-base-department"
              element={<ConclusionBaseDepartment />}
            />
            <Route
              path="directionsPriority"
              element={<DirectionsTrainingPriorityListPage {...scenarioKeys} />}
            />
            <Route index element={<Navigate to="exam-results" />} />
            <Route path="exam-results" element={<ResultsExamPage />} />
            <Route path="directions" element={<DirectionsTrainingPage />}>
              <Route
                path=""
                element={<DirectionsTrainingListPage {...scenarioKeys} />}
              />
              <Route
                path="create"
                element={<DirectionsTrainingCreatePage {...scenarioKeys} />}
              />
            </Route>
            <Route
              path="achievements"
              element={<AchievementsPage {...scenarioKeys} />}
            />
            <Route
              path="privileges"
              element={<PrivilegesPage {...scenarioKeys} />}
            />
            <Route
              path="target"
              element={<TargetAgreementPage {...scenarioKeys} />}
            />
            <Route path="contract" element={<ContractPage />}>
              <Route path="" element={<ContractListPage {...scenarioKeys} />} />
              <Route path="create" element={<CreateContractPage />} />
              <Route path="edit/:contractId" element={<CreateContractPage />} />
            </Route>
            <Route
              path="blank"
              element={<DownloadsPage {...scenarioKeys} isAdmin={true} />}
            />
            <Route path="test" element={<EntranceExamsPage {...scenarioKeys} />} />
            <Route path="certificate" element={<CertificatePage />} />
          </Route>
        </Route>
        <Route path="additional-files" element={<AdditionalFilesPage />} />
      </Route>

      <Route path="*" element={<NotFound404 />} />
    </Routes>
  )
}
export default Routing
