import React from 'react'

export default ({ contractJuridical }) => (
  <div className="moderator-contract-info__inline">
    <div className="moderator-contract-info__block">
      <h2>Основные данные плательщика:</h2>
      <div className="moderator-contract-info__block-row juridical">
        <p>Полное наименование:</p>
        <span>{contractJuridical.organization || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>ФИО руководителя:</p>
        <span>{contractJuridical.fullNameLeader || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>ИНН:</p>
        <span>{contractJuridical.iin || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>КПП:</p>
        <span>{contractJuridical.kpp || '-'}</span>
      </div>

      <div className="moderator-contract-info__block-row juridical">
        <p>Фактический адрес:</p>
        <span>{contractJuridical.organizationAddress || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Юридический адрес:</p>
        <span>{contractJuridical.legalAddress || '-'}</span>
      </div>
    </div>
    <div className="moderator-contract-info__block">
      <br />
      <br />
      <div className="moderator-contract-info__block-row juridical">
        <p>Руководитель действует на основании:</p>
        <span>{contractJuridical.managementBases || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Наименование банка:</p>
        <span>{contractJuridical.nameBank || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>БИК банка:</p>
        <span>{contractJuridical.bicBank || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Город банка:</p>
        <span>{contractJuridical.cityBank || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Расчетный счет:</p>
        <span>{contractJuridical.paymentAccount || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Корреспондентский счет:</p>
        <span>{contractJuridical.correspondentAccount || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Контактный телефон:</p>
        <span>{contractJuridical.phone || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>E-mail:</p>
        <span>{contractJuridical.email || '-'}</span>
      </div>
    </div>
  </div>
)
