import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AchivementsForm from '@components/AchivementsForm'
import FillingStatementsForm from '@components/FillingStatementsForm'
import OlympiadsForm from '@components/OlympiadsForm'
import useAchivements from '@hooks/useAchivements'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useOlympiads from '@hooks/useOlympiads'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import {
  selectLevelValue,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const AchievementsPage = (scenarioKeys) => {
  const { statementId } = useParams()
  const { readOnly } = useReadOnlyFillingStatements()
  const statementEl = useAppSelector(selectStatementsElement)
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const { achivements, achivementsAnketa } = useAchivements(String(statementId))
  const { olympiads } = useOlympiads(String(statementId))

  const [isHaveAchievements, setIsHaveAchievements] = useState(false)

  useEffect(() => {
    if (
      achivements.length === 0 &&
      olympiads.length === 0 &&
      achivementsAnketa.length === 0
    ) {
      setIsHaveAchievements(false)
    } else {
      setIsHaveAchievements(true)
    }
  }, [achivements, olympiads, achivementsAnketa])

  const isShowOlympiads =
    statementEl?.trajectoryId === 1 &&
    statementEl?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
    statementEl?.scenarioId !== scenarioKeys.level_training_spo.tdId &&
    statementEl?.scenarioId !== scenarioKeys.level_training_preparation.tdId

  return (
    <div>
      <FillingStatementsForm
        onSubmit={(e) => {
          e.preventDefault()
          onNextPageNavigate()
        }}
        className="achivements"
      >
        <Checkbox
          readOnly
          disabled={readOnly}
          text={
            statementEl?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
            statementEl?.scenarioId !== scenarioKeys.level_training_preparation.tdId
              ? 'У меня нет результатов олимпиад и индивидуальных достижений'
              : 'У меня нет индивидуальных достижений'
          }
          checked={!isHaveAchievements}
        />

        {isShowOlympiads && <OlympiadsForm {...scenarioKeys} />}

        <AchivementsForm {...scenarioKeys} />
      </FillingStatementsForm>
    </div>
  )
}

export default AchievementsPage
