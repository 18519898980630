import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DownloadIcon from '@assets/images/Download.svg'
import FileIcon from '@assets/images/File.svg'
import { mimeTypes } from '@common/manual'
import ModeratorDataList from '@components/ModeratorDataList'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadDocument from '@hooks/useDownloadDocument'
import { getModeratorStatementsAchievementsAndPrivilegesData } from '@redux/action-types'
import {
  selectModeratorStatementsAchievementsAndPrivilegesData,
  selectModeratorStatementsIdStatus,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import { formatDateYYYYMMDD } from '@utils/date'

const headers = [
  'Признак',
  'Тип признака',
  'Документ',
  'Реквизиты',
  'Количество',
  'Дополнительная информация',
]

const ModeratorPrivilegesPage = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const { downloadDocument } = useDownloadDocument(true)
  const [lgotaAndReasonFiles, setLgotaAndReasonFiles] = useState({})

  const { id } = useParams<{ id?: string }>()

  const statement = useAppSelector(selectModeratorStatementsIdStatus)

  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsAchievementsAndPrivilegesData(id))
    }
  }, [id])

  const hallmarks = useAppSelector(
    selectModeratorStatementsAchievementsAndPrivilegesData
  ) || {
    achievements: {
      achivment: [],
      achivmentAnketa: [],
    },
    lgota: [],
    olympiads: [],
    preemptiveRight: [],
    specialCondition: [],
  }

  useEffect(() => {
    hallmarks.specialCondition[0]?.statementDoc?.map((item) => {
      setLgotaAndReasonFiles((prev) => ({
        ...prev,
        [item.userDoc.getTypeDocs.name]: item.userDocId,
      }))
    })
  }, [hallmarks])
  const onDownload = (
    type:
      | 'LGOTA'
      | 'LGOTA_SPECIAL'
      | 'LGOTA_REASON'
      | 'PRESIDENT_DECREE'
      | 'PRESIDENT_DECREE_B'
      | 'REFERENCE_MSE',
    docId: number
  ) => {
    downloadDocument(type, mimeTypes.pdf, 'pdf', docId, '', Number(id))
  }

  return (
    <div className={'moderator-data-list'}>
      <table className="moderator-hallmarks-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hallmarks?.preemptiveRight?.map((item, id) => {
            return (
              <tr key={id}>
                <td>{'Преимущественное право'}</td>
                <td>{item.preemptiveRight?.preemptiveRightTranslate[0].name}</td>
                <td>
                  {item.preemptiveRightDoc?.preemptiveRightDocTranslate[0].name}
                </td>
                <td>
                  {item.number}
                  <br />
                  {item.dateIssue}
                </td>
                <td>{item.quantity || '-'}</td>
                <td>{item.comment || '-'}</td>
                <td>
                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() => onDownload('LGOTA', item.docId)}
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
          {hallmarks?.lgota?.map((item, id) => {
            return (
              <tr key={id}>
                <td>Льгота</td>
                <td>{item.lgota?.lgotaTranslate[0].name}</td>
                <td>{item.lgotaDoc?.lgotaDocTranslate[0].name}</td>
                <td>
                  {item.number}
                  <br />
                  {item.dateIssue}
                </td>
                <td>{item.quantity || '-'}</td>
                <td>{item.comment || '-'}</td>
                <td>
                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() => onDownload('LGOTA', item.docId)}
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {hallmarks.specialCondition &&
        hallmarks.specialCondition[0]?.haveSpecialConditions && (
          <>
            <Checkbox
              readOnly
              checked={hallmarks.specialCondition[0].haveSpecialConditions}
              text={
                'Нужны специальные условия для проведения вступительных испытаний'
              }
            />
            {hallmarks.specialCondition[0].commentSpecialConditions && (
              <ModeratorDataList
                list={[
                  {
                    title: 'Cпециальные условия:',
                    label: hallmarks.specialCondition[0].commentSpecialConditions,
                  },
                ]}
              />
            )}
            <ProfileSection title="">
              <li className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(
                        'LGOTA_SPECIAL',
                        lgotaAndReasonFiles['lgota_special_conditions']
                      )
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">Специальные условия</div>
                </div>
              </li>
            </ProfileSection>
          </>
        )}
      {hallmarks.specialCondition &&
        hallmarks.specialCondition[0]?.haveReasonConditions && (
          <>
            <Checkbox
              readOnly
              checked={hallmarks.specialCondition[0].haveReasonConditions}
              text={
                'Имею основание для сдачи вступительных испытаний в РГПУ им. А.И. Герцена на основании пункта 12 раздела II и пункта 2 раздела XVIII Правил приема в Университет'
              }
            />
            <ProfileSection title="">
              <li className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(
                        'LGOTA_REASON',
                        lgotaAndReasonFiles['lgota_reason_conditions']
                      )
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">Основание для сдачи</div>
                </div>
              </li>
            </ProfileSection>
          </>
        )}
      {hallmarks.specialCondition &&
        hallmarks.specialCondition[0]?.haveReferenceMse && (
          <>
            <Checkbox
              readOnly
              checked={hallmarks.specialCondition[0].haveReferenceMse}
              text={
                'Имею основание для поступления за счет бюджетных ассигнований федерального бюджета на основании пункта 8.1 статьи 79 Федерального закона от 29.12.2012 N 273-ФЗ «Об образовании в Российской Федерации»'
              }
            />
            <ProfileSection title="">
              <li className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(
                        'REFERENCE_MSE',
                        lgotaAndReasonFiles['reference_MSE']
                      )
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">Справка МСЭ</div>
                </div>
              </li>
            </ProfileSection>
          </>
        )}
      {hallmarks.specialCondition &&
        hallmarks.specialCondition[0]?.presidentialDecree268 && (
          <>
            <Checkbox
              readOnly
              checked={hallmarks.specialCondition[0].presidentialDecree268}
              text={
                'Поступаю на места в пределах отдельной квоты на основании подпункта 2 пункта 104.1 Правил приема в Университет (по результатам ЕГЭ или вступительных испытаний, проводимых Университетом)'
              }
            />
            <ProfileSection title="">
              <li className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(
                        'PRESIDENT_DECREE',
                        lgotaAndReasonFiles['presidential_decree_268']
                      )
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">
                    Подтверждение факта попадания под указ пункт А
                  </div>
                </div>
              </li>
            </ProfileSection>
          </>
        )}
      {hallmarks.specialCondition &&
        hallmarks.specialCondition[0]?.presidentialDecree268B && (
          <>
            <Checkbox
              readOnly
              checked={hallmarks.specialCondition[0].presidentialDecree268B}
              text={
                statement?.scenarioId ===
                  scenarioKeys.level_training_bachelor.tdId ||
                statement?.scenarioId ===
                  scenarioKeys.level_training_bachelor_ino.tdId
                  ? 'Поступаю на места в пределах отдельной квоты на основании подпункта 1 пункта 104.1 Правил приема в Университет (без вступительных испытаний (за исключением дополнительных вступительных испытаний творческой и (или) профессиональной направленности))'
                  : 'Поступаю на основании пункта «б» Указа Президента РФ от 9 мая 2022 года №268 "О дополнительных мерах поддержки семей военнослужащих и сотрудников некоторых федеральных государственных органов" (без вступительных испытаний (за исключением дополнительных вступительных испытаний творческой и (или) профессиональной направленности)'
              }
            />
            <ProfileSection title="">
              <li className="add-files-page__item additional-file">
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => {
                      onDownload(
                        'PRESIDENT_DECREE_B',
                        lgotaAndReasonFiles['presidential_decree_268_b']
                      )
                    }}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">
                    Подтверждение факта попадания под указ пункт Б
                  </div>
                </div>
              </li>
            </ProfileSection>
          </>
        )}
    </div>
  )
}

export default ModeratorPrivilegesPage
