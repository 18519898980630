import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import uploadDocsTypes, { UploadDocKey } from '@common/uploadDocsTypes'
import {
  Achivement,
  AchivementDocType,
  AchivementResponseFrom,
  AchivementType,
  BlankPriorityStatement,
  BlankStatement,
  ChosenTrials,
  ContractEntrants,
  ContractJuridical,
  ContractNotEntrants,
  ContractPayer,
  DisciplineEge,
  DisciplineResponse,
  FillingStatementsSectionKey,
  MoodleCoursesResponse,
  Olympiad,
  OlympiadResponse,
  SchoolSubjects,
  sendUserSchoolSubjects,
  StudentsFilterFormData,
  UserEstimate,
  UserSchoolSubjects,
  ViewerCreateStatements,
  ViewerDeleteResultEge,
  ViewerDirectionsListStatements,
  ViewerDirectionsTrainingSearch,
  ViewerDirectionTrainingAssignStatements,
  ViewerDirectionTrainingAssignStatementsNew,
  ViewerDirectionTrainingOrder,
  ViewerDirectionTrainingSwap,
  ViewerElementStatements,
  ViewerPaymentDirectionsTrainingSearch,
  ViewerResultExamForm,
  ViewerScenarioStatements,
  ViewerSubscribeBlank,
  ViewerTabEgeData,
  ViewerTargetContractForm,
  ViewerTargetContractFormData,
  ViewerTrainingLevelAssignStatements,
  ViewerTrainingLevelsResponse,
  ViewerTrajectoryAssignStatements,
  ViewerTrajectoryListStatements,
} from '@models'
import {
  ResponseNotAcceptable,
  ResponseOk,
  ResponseUnprocessableEntity,
} from '@services/api/types'
import { logDOM } from '@testing-library/react'

export default {
  /** @method GET */
  checkFillingAnketa: (statementId: number, checkboxes?) => {
    if (!checkboxes) {
      return axios.get<any>(`/statements/check-filling?statement_id=${statementId}`)
    }

    const query = new URLSearchParams()
    checkboxes.map((item) => query.append('checkboxses[]', item))
    return axios.get<any>(`/statements/check-filling?statement_id=${statementId}`, {
      params: query,
    })
  },

  getConclusionBaseDepartment: (statementId: number) => {
    return axios.get<any>(
      `/statements/conclusion-from-base-department?statement_id=${statementId}`
    )
  },

  /** @method GET */
  getUserAchivements: (statementId: number) =>
    axios.get<AchivementResponseFrom[]>(
      `/userAchivment?statement_id=${statementId}`
    ),

  postUserAchivementsSpecialConditionChange: (
    statementId: number,
    checked: boolean
  ) =>
    axios.post(`/nextTabUserAchivment`, {
      statement_id: statementId,
      have_right_1_13_7_71_law: checked,
    }),

  /** @method GET */
  getUserOlympiads: (statementId: number) =>
    axios.get<OlympiadResponse[]>(
      `/statements/olympiads?statement_id=${statementId}`
    ),

  /** @method GET */
  getTabEge: (id: number) =>
    axios.get<ViewerTabEgeData>(`/tabEge?statement_id=${id}`),

  /** @method GET */
  getDisciplineEge: (id: number) =>
    axios.get<DisciplineEge[]>(`/disciplineEge?statement_id=${id}`),

  /** @method GET */
  getDisciplineCentralExam: (id: number) =>
    axios.get<DisciplineEge[]>(`disciplineEge?statement_id=${id}&central_exam=1`),

  // disciplineEge?statement_id=100&central_exam=1
  /** @method GET */
  getBlankStatement: (id: number) =>
    axios.get<BlankStatement>(`/blankStatement?statement_id=${id}`),

  /** @method GET */
  getBlankPriorityStatement: (id: number) =>
    axios.get<BlankPriorityStatement>(`/blankStatementPriority?statement_id=${id}`),

  /** @method POST */
  statementReview: (id: number, checkboxses: number[]) =>
    axios.post<CheckStatementStatusResponse>(`/sendStatementReview`, {
      statement: id,
      checkboxses: checkboxses,
    }),

  /** @method GET */
  getStatementsList: (userId?: number) => {
    if (!!userId) {
      return axios.get<ViewerElementStatements[]>(`/statements?user_id${userId}`)
    } else {
      return axios.get<ViewerElementStatements[]>(`/statements`)
    }
  },

  /** @method GET */
  getDocsForStatement: (id: string | number) =>
    axios.get<any>(`/statements/forms?statement_id=${id}`),

  /** @method GET */
  getStatementsElement: (id: number) =>
    axios.get<ViewerElementStatements>(`/statements/id?statement=${id}`),

  /** @method GET */
  getAchivementDocTypes: (achivementId: number) =>
    axios.get<AchivementDocType[]>(`/achivmentsDoc?achivment_id=${achivementId}`),

  /** @method GET */
  getAchivements: (statementId: number) =>
    axios.get<AchivementType[]>(`/achivments?statement_id=${statementId}`),

  /** @method GET */
  getOlympiadsByYear: (data) => {
    const params = data ? snakecaseKeys(data) : {}
    return axios.get<AchivementType[]>('/olympiads', { params })
  },

  /** @method GET */
  getOlympiadsDocType: () => axios.get<AchivementType[]>(`/olympiad-docs`),

  /** @method GET */
  getTrajectoryList: () =>
    axios.get<ViewerTrajectoryListStatements[]>(`/statements/trajectory`),

  /** @method GET */
  getTrainingLevelList: (id = 1) =>
    axios.get<ViewerScenarioStatements[]>(
      `/statements/trainingLevel?trajectory_id=${id}`
    ),

  /** @method GET */
  getFormPayment: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }

    return axios.get<ViewerPaymentDirectionsTrainingSearch[]>(
      `/statements/formPayments`,
      { params: params }
    )
  },

  /** @method GET */
  getStatementDisciplines: (statementId: number) =>
    axios.get<DisciplineResponse[]>(
      `/statements/discipline/statement?statement=${statementId}`
    ),

  /** @method GET */
  checkEge: (statementId: number) =>
    axios.get<any>(`/check-ege?statement_id=${statementId}`),

  /** @method GET */
  getContractsList: (statementId: number | string) =>
    axios.get<any>(`/contract?statement_id=${statementId}`),

  /** @method GET */
  getContractById: (contractId: number) =>
    axios.get<any>(`/contract/show?contract_id=${contractId}`),

  /** @method GET */
  getDirectionsListStatements: (id = 1) =>
    axios.get<ViewerDirectionsListStatements[]>(
      `/statements/directionTrainings/statement?statement=${id}`
    ),

  /** @method GET */
  getDirectionsListSearch: (search: ViewerDirectionsTrainingSearch) => {
    const params = new URLSearchParams()
    params.append('statement', String(search.statementId))
    if (search.name) {
      params.append('name', search.name)
    }
    if (search.faculty) {
      search.faculty.forEach((facultyId) => {
        params.append('faculty_id' + '[]', `${facultyId}`)
      })
    }
    if (search.formEducation) {
      params.append('form_education_id', `${search.formEducation}`)
    }
    if (search.formPayment) {
      params.append('form_payments_id', `${search.formPayment}`)
    }
    return axios.get<ViewerDirectionsListStatements[]>(
      `/statements/directionTrainings`,
      { params: params }
    )
  },

  /** @method GET */
  getStudentsList: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key] || key === 'benefits') {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<any>(`/statements/lists-applicants`, { params: params })
  },

  /** @method GET */
  getCompetitiveGroups: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<any[]>(`/statements/lists-applicants/competitive-groups`, {
      params: params,
    })
  },

  /** @method GET */
  getTrainingLevels: () => {
    return axios.get<ViewerTrainingLevelsResponse>(`/training-levels`)
  },

  /** @method GET */
  getListGroups: () => axios.get('/incoming-list-groups'),

  /** @method GET */
  getIncomingList: (data) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          if (Array.isArray(snakeData[key])) {
            snakeData[key].map((item) => params.append(`${key}[]`, item))
          } else {
            params.append(`${key}[]`, snakeData[key])
          }
        }
      })
    }
    return axios.get('/incoming-lists', { params })
  },

  /** @method GET */
  getPlaceEmployments: () =>
    axios.get('/statements/target-contract/place-employments'),

  /** @method GET */
  getPayerTypes: () => axios.get<any>('/contract/payer-types'),

  /** @method GET */
  getPrintContract: (contractId, data) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<any>(`/contract/download/print?contract_id=${contractId}`, {
      params,
    })
  },

  /** @method GET */
  getDirectionTrainingsOptions: (statementId: number | string) => {
    return axios.get<any>(
      `/statements/directionTrainings/statement?statement=${statementId}`
    )
  },

  /** @method GET */
  getMoodleCourses: (data) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }
    return axios.get<MoodleCoursesResponse>(`/moodle/walk`, { params })
  },

  /** @method GET */
  getDisciplineTrials: (userId) =>
    axios.get<any>(`/statements/discipline-trials?user_id=${userId}`),

  /** @method POST */
  statementsCreate: (data: {
    trajectoryId: number
    trainingLevel: number
    paymentType: number | null
    scenarioId: number
  }) =>
    axios.post<ViewerCreateStatements, UpdateStatementsCreateResponse>(
      '/statements/create',
      snakecaseKeys(data)
    ),

  /** @method POST */
  statementsFormCreate: (data: { trajectoryId: number; trainingLevel: number }) =>
    axios.post<ViewerCreateStatements, UpdateStatementsCreateResponse>(
      '/statements/chosePaymentForm',
      snakecaseKeys(data)
    ),

  /** @method POST */
  statementTrials: (data: ChosenTrials[], statementId: string | undefined) =>
    axios.post<ChosenTrials>(
      `/statements/directionTrainings/assignTrials?statementId=${statementId}`,
      data
    ),

  /** @method POST */
  statementsTrajectoryAssign: (data: ViewerTrajectoryAssignStatements) =>
    axios.post<ViewerTrajectoryAssignStatements, UpdateTrajectoryAssignResponse>(
      '/statements/trajectory/assign',
      snakecaseKeys(data)
    ),

  /** @method POST */
  subscribeBlank: (data: ViewerSubscribeBlank) =>
    axios.post<ViewerSubscribeBlank, UpdateViewerSubscribeBlankResponse>(
      '/subscribeBlank',
      snakecaseKeys(data)
    ),

  /** @method POST */
  subscribeBlankPriority: (data: ViewerSubscribeBlank) =>
    axios.post<ViewerSubscribeBlank, UpdateViewerSubscribeBlankResponse>(
      '/subscribeBlankPriority',
      snakecaseKeys(data)
    ),

  /** @method POST */
  postStatementDisciplines: (data: { discipline: number }) =>
    axios.post<{ discipline: number }, UpdateViewerDisciplineDateResponse>(
      `/statements/discipline/statement`,
      snakecaseKeys(data)
    ),

  /** @method POST */
  statementsDirectionTrainingsAssign: (
    data: ViewerDirectionTrainingAssignStatementsNew
  ) =>
    axios.post<
      ViewerDirectionTrainingAssignStatementsNew,
      UpdateDirectionTrainingAssignResponseNew
    >('/statements/directionTrainings/assign', data),

  /** @method POST */
  statementsDirectionTrainingsAssent: (
    data: ViewerDirectionTrainingAssignStatements
  ) =>
    axios.post<
      ViewerDirectionTrainingAssignStatements,
      UpdateDirectionTrainingAssignResponse
    >('/statements/directionTrainings/assent', data),

  /** @method POST */
  statementsDirectionTrainingsRejection: (
    data: ViewerDirectionTrainingAssignStatements
  ) =>
    axios.post<
      ViewerDirectionTrainingAssignStatements,
      UpdateDirectionTrainingAssignResponse
    >('/statements/directionTrainings/rejection', data),

  /** @method POST */
  statementsDirectionTrainingsDetach: (
    data: ViewerDirectionTrainingAssignStatementsNew
  ) =>
    axios.post<
      ViewerDirectionTrainingAssignStatementsNew,
      UpdateDirectionTrainingAssignResponseNew
    >('/statements/directionTrainings/detach', data),

  /** @method POST */
  statementsDirectionTrainingsSwap: (data: ViewerDirectionTrainingSwap) =>
    axios.post<ViewerDirectionTrainingSwap, UpdateDirectionTrainingSwapResponse>(
      '/statements/CompetitiveGroup/swap',
      snakecaseKeys(data)
    ),

  /** @method POST */
  statementsDirectionTrainingsSwapPriority: (data: ViewerDirectionTrainingSwap) =>
    axios.post<ViewerDirectionTrainingSwap, UpdateDirectionTrainingSwapResponse>(
      '/statements/CompetitiveGroup/swapPriority',
      snakecaseKeys(data)
    ),

  /** @method POST */
  statementsDirectionTrainingsOrder: (data: ViewerDirectionTrainingOrder) =>
    axios.post<ViewerDirectionTrainingOrder, UpdateDirectionTrainingOrderResponse>(
      '/statements/CompetitiveGroup/order',
      snakecaseKeys(data)
    ),

  /** @method POST */
  addAchivement: (data: Achivement) => {
    const updData = { ...snakecaseKeys(data), typeDoc: data.typeDoc }
    const formData = new FormData()
    Object.keys(updData).forEach((key) => formData.append(key, updData[key]))
    formData.delete('documents')
    formData.delete('second_documents')
    formData.delete('doc_id')
    formData.delete('second_doc_id')
    for (const doc of data.documents) {
      formData.append('documents[]', doc)
    }
    for (const doc of data.secondDocuments) {
      formData.append('second_documents[]', doc)
    }
    return axios.post<
      ViewerTrajectoryAssignStatements,
      UpdateTrajectoryAssignResponse
    >('/userAchivment', formData)
  },

  /** @method PUT */
  editAchivement: (data: Achivement) => {
    const updData = {
      ...snakecaseKeys(data),
      typeDoc: data.typeDoc,
      documents: undefined,
      second_documents: undefined,
    }

    return axios.put<
      ViewerTrajectoryAssignStatements,
      UpdateTrajectoryAssignResponse
    >('/userAchivment', updData)
  },

  /** @method GET */
  getOlympiadYearOptions: (scenarioId: any) =>
    axios.get<any>(`/statements/olympiadsYear?scenario_id=${scenarioId}`),

  editOlympiad: (data: Olympiad) => {
    const updData = {
      ...snakecaseKeys(data),
      typeDoc: data.typeDoc,
      documents: data.documents,
    }
    return axios.put<
      ViewerTrajectoryAssignStatements,
      UpdateTrajectoryAssignResponse
    >(`/statements/olympiads?user_olympiad_id=${data.userOlympiadId}`, updData)
  },

  addOlympiad: (data: Olympiad) => {
    const updData = { ...snakecaseKeys(data) }
    const formData = new FormData()
    Object.keys(updData).forEach((key) => formData.append(key, updData[key]))
    formData.delete('documents')
    formData.delete('doc_id')
    for (const doc of data.documents) {
      formData.append('documents[]', doc)
    }
    return axios.post<
      ViewerTrajectoryAssignStatements,
      UpdateTrajectoryAssignResponse
    >('/statements/olympiads', formData)
  },

  /** @method POST */
  statementsTrainingLevelAssign: (data: ViewerTrainingLevelAssignStatements) =>
    axios.post<
      ViewerTrainingLevelAssignStatements,
      UpdateTrainingLevelAssignResponse
    >('/statements/trainingLevel/assign', snakecaseKeys(data)),

  /** @method POST */
  addContractEntrants: (data: ContractEntrants) =>
    axios.post<ContractEntrants, AddContractEntrantsResponse>(
      '/contract/enrollee',
      snakecaseKeys(data)
    ),

  /** @method POST */
  addContractNotEntrants: (data: ContractNotEntrants) => {
    const updData = { ...snakecaseKeys(data) }
    const formData = new FormData()
    Object.keys(updData).forEach(
      (key) => updData[key] && formData.append(key, updData[key])
    )
    formData.delete('document')

    for (const doc of data.document) {
      formData.append('document', doc)
    }
    return axios.post<ContractEntrants, AddContractNotEntrantsResponse>(
      '/contract/individual',
      formData
    )
  },

  /** @method POST */
  addContractJuridical: (data: ContractJuridical) =>
    axios.post<ContractEntrants, AddContractJuridicalResponse>(
      '/contract/juridical',
      snakecaseKeys(data)
    ),

  /** @method POST */
  addTabEge: (data: ViewerResultExamForm) =>
    axios.post<ViewerResultExamForm, UpdateEgeTabResponse>(
      '/tabEge',
      snakecaseKeys(data)
    ),
  addTabEgeV2: (data: FormData) => axios.post('/tabEge_v2', data),
  getTabEgeV2: (id: number) =>
    axios.get<ViewerTabEgeData>(`/tabEge_v2?statement_id=${id}`),

  /** @method POST */
  editContractFile: (
    document: File[],
    contractId: string,
    typeDoc: UploadDocKey
  ) => {
    const formData = new FormData()

    formData.append('contract_id', contractId)
    formData.append('typeDoc', uploadDocsTypes[typeDoc])

    for (const doc of document) {
      formData.append('documents[]', doc)
    }

    return axios.post<any>('/contract/upload', formData)
  },

  /** @method GET */
  getTargetContract: (id: number) =>
    axios.get<ViewerTargetContractFormData>(
      `/statements/target-contract?statement_id=${id}`
    ),

  /** @method POST */
  postTargetContract: (data: ViewerTargetContractForm) => {
    const updData = { ...snakecaseKeys(data) }
    const formData = new FormData()
    Object.keys(updData).forEach(
      (key) => updData[key] && formData.append(key, updData[key])
    )
    formData.delete('documents')
    for (const doc of data.documents) {
      formData.append('documents[]', doc)
    }
    return axios.post<ViewerTargetContractForm, UpdateViewerTargetContractForm>(
      '/statements/target-contract',
      formData
    )
  },
  /** @method PUT */
  putTargetContract: (data: ViewerTargetContractForm) => {
    const updData = {
      ...snakecaseKeys(data),
      documents: undefined,
    }
    Object.keys(updData).forEach(
      (key) => updData[key] === null && delete updData[key]
    )

    return axios.put<ViewerTargetContractForm, UpdateViewerTargetContractForm>(
      '/statements/target-contract',
      updData
    )
  },
  /** @method DELETE */
  deleteTargetContract: (id: number) =>
    axios.delete<{ id: number }, UpdateDeleteStatements>(
      `/statements/target-contract?statement_id=${id}`
    ),

  getEntranceExams: (statementId: number | string) =>
    axios.get<any>(`/moderator/statements/trials?statement_id=${statementId}`),

  /** @method DELETE */
  deleteContract: (id: string) =>
    axios.delete<{ id: string }, AddContractJuridicalResponse>(
      `/moderator/contracts/${id}`
    ),

  /** @method DELETE */
  deleteStatements: (id: number) =>
    axios.delete<{ id: number }, UpdateDeleteStatements>(`/statements?id=${id}`),

  /** @method PUT */
  putStatusStatement: (data: { id: number; code: number }) => {
    return axios.put<{ id: number; code: number }, UpdateStatementStatus>(
      '/statements/status',
      data
    )
  },

  /** @method PUT */
  editContractNotEntrants: (data: ContractNotEntrants) => {
    return axios.put<ContractNotEntrants, AddContractNotEntrantsResponse>(
      '/contract/individual',
      snakecaseKeys(data)
    )
  },

  /** @method POST */
  editContractModerator: (data: ContractPayer) => {
    return axios.post<ContractPayer, AddContractPayerResponse>(
      '/admin/statements/contract/individual',
      snakecaseKeys(data)
    )
  },

  /** @method PUT */
  editContractModeratorJuridical: (data: ContractPayer) => {
    return axios.put<ContractPayer, AddContractPayerResponse>(
      '/admin/statements/contract/juridical',
      snakecaseKeys(data)
    )
  },

  /** @method PUT */
  editContractEntrants: (data: ContractEntrants) =>
    axios.put<ContractEntrants, AddContractEntrantsResponse>(
      '/contract/enrollee',
      snakecaseKeys(data)
    ),

  /** @method GET */
  contractRevokeById: (id: number) =>
    axios.get<any>(`/contract/${id}/revoke?contract_id=${id}`),

  /** @method DELETE */
  contractDeleteById: (id: number) =>
    axios.delete<any>(`/contract/${id}/delete?contract_id=${id}`),

  /** @method PUT */
  editContractJuridical: (data: ContractJuridical) =>
    axios.put<ContractJuridical, AddContractJuridicalResponse>(
      '/contract/juridical',
      snakecaseKeys(data)
    ),

  /** @method PUT */
  changeModeratorContractStatus: (id: number, code: number) =>
    axios.put<{ id: number; code: number }>('/moderator/contracts/status', {
      id,
      code,
    }),

  /** @method GET */
  getGeneratedContract: (params) =>
    axios.get(`/moderator/contracts/printed/${params.contract_id}`),

  /** @method GET */
  getGeneratedReceived: (params) =>
    axios.get(`/contract/download/receipt?contract_id=${params.contract_id}`),

  /** @method POST */
  addContractModerator: (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (const doc of data[key]) {
          formData.append(`${key}[]`, doc)
        }
      } else {
        formData.append(key, data[key])
      }
    })
    return axios.post('/moderator/contracts/upload', formData)
  },

  addContractTo1C: (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (const doc of data[key]) {
          formData.append(`${key}[]`, doc)
        }
      } else {
        formData.append(key, data[key])
      }
    })
    return axios.post('/moderator/contracts/approve', formData)
  },

  /** @method DELETE */
  deleteTabEge: (data: ViewerDeleteResultEge) =>
    axios.delete<ViewerDeleteResultEge, UpdateDeleteEgeTabResponse>('/resultEge', {
      data: snakecaseKeys(data),
    }),

  /** @method DELETE */
  deleteUserAchivement: (data: { statementId: number; docId: number }) =>
    axios.delete('/userAchivment', {
      data: snakecaseKeys(data),
    }),

  deleteUserOlympiad: (data: { statementId: number; userOlympiadId: number }) =>
    axios.delete(`/statements/olympiads?user_olympiad_id=${data.userOlympiadId}`),

  /** @method GET */
  getSchoolSubjects: () => {
    return axios.get<SchoolSubjects[]>(`/attestat/school_subjects`)
  },

  /** @method GET */
  getUserSchoolSubjects: (userId: any) => {
    return axios.get<UserSchoolSubjects>(
      `/attestat/user_school_subjects?user_id=${userId}`
    )
  },

  /** @method GET */
  getEstimates: (userId: any) => {
    return axios.get<UserEstimate>(`/attestat/estimate?user_id=${userId}`)
  },

  /** @method PUT */
  editUserSchoolSubjects: (data) => {
    return axios.post<sendUserSchoolSubjects>(
      `/attestat/user_school_subjects`,
      snakecaseKeys(data)
    )
  },

  /** @method PUT */
  editEstimates: (data) => {
    return axios.post<UserEstimate>(`/attestat/estimate`, snakecaseKeys(data))
  },

  getExamDoc: (statementId: string | number) => {
    return axios.get(`/downloadBlank?blank_id=150&statement=${statementId}`)
  },

  sendConsentToEnrollment: (data: {
    directionTraining: string | number
    statement: string | number
  }) => {
    return axios.post('/statements/directionTrainings/assent', data)
  },

  revokeConsentToEnrollment: (data: {
    directionTraining: string | number
    statement: string | number
    id: string | number
  }) => {
    return axios.post('/statements/directionTrainings/rejection', data)
  },

  getConsentToEnrollmentHistory: (data: {
    id: number | string
    user?: number | string
  }) => {
    if (data.user) {
      return axios.get(
        `statement/blank/history?statement_id=${data.id}&user_id=${data.user}`
      )
    }

    return axios.get(`statement/blank/history?statement_id=${data.id}`)
  },

  sendEnroll: (id: any) => {
    return axios.get(
      `statements/directionTrainings/check-agreement?statement_id=${id}`
    )
  },
}
export type UpdateStatementsCreateResponse =
  | ResponseOk<{ id: number }>
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerCreateStatements>

export type UpdateStatementStatus =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number; code: number }>

//TrajectoryAssign
export type UpdateTrajectoryAssignResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerTrajectoryAssignStatements>

//SubscribeBlank
export type UpdateViewerSubscribeBlankResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerSubscribeBlank>

//SubscribeBlank
export type UpdateViewerDisciplineDateResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ discipline: number }>

//TrainingLevelAssign
export type UpdateTrainingLevelAssignResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerTrainingLevelAssignStatements>

//DirectionTrainingAssign
export type UpdateDirectionTrainingAssignResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerDirectionTrainingAssignStatements>

//DirectionTrainingAssignNew
export type UpdateDirectionTrainingAssignResponseNew =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerDirectionTrainingAssignStatementsNew>

//DirectionTrainingSwap
export type UpdateDirectionTrainingSwapResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerDirectionTrainingSwap>

//DirectionTrainingOrder
export type UpdateDirectionTrainingOrderResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerDirectionTrainingOrder>

//EgeTab
export type UpdateEgeTabResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerResultExamForm>

//TargetContract
export type UpdateViewerTargetContractForm =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerTargetContractForm>

//deleteEgeTab
export type UpdateDeleteEgeTabResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerDeleteResultEge>

//deleteStatements
export type UpdateDeleteStatements =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number }>

//AddAchivement
export type AddAchivementResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<Achivement>

//AddContractEntrants
export type AddContractEntrantsResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<ContractEntrants>

//AddContractNotEntrants
export type AddContractNotEntrantsResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<ContractNotEntrants>

export type AddContractPayerResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<ContractPayer>

//AddContractJuridical
export type AddContractJuridicalResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<ContractJuridical>

// check statements status
export type CheckStatementStatusUnprocessableEntity = {
  success: false
  message: { [key in FillingStatementsSectionKey]?: string }
}

export type CheckStatementStatusOk = {
  success: true
  message: { [key in FillingStatementsSectionKey]?: string }
}

export type CheckStatementStatusResponse =
  | CheckStatementStatusOk
  | CheckStatementStatusUnprocessableEntity

// export type EntranceExam = {
//   competitive_group: any,
//   competitive_group_id: number,
//   id: number,
//   trials: any[]
// }
