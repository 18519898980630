import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import EntranceExamsTable from './components/Table'
import { TRAJECTORY_RUSSIA_ID } from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  getStatementDisciplines,
  getMoodleCourses,
  getDisciplineTrials,
} from '@redux/action-types'
import {
  selectDisciplines,
  selectLocalizedViewerProfile,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'

const EntranceExamsForm = (scenarioKeys) => {
  const { statementId } = useParams()

  const dispatch = useAppDispatch()

  const disciplines = useAppSelector(selectDisciplines) || []
  const profile = useAppSelector(selectLocalizedViewerProfile)

  const statement = useAppSelector(selectStatementsElement)
  const trajectory = useAppSelector(selectTrajectoryValue)

  useEffect(() => {
    if (statementId) {
      dispatch(getStatementDisciplines(Number(statementId)))
    }
  }, [])

  useEffect(() => {
    profile?.externalId &&
      dispatch(getMoodleCourses({ externalId: profile.externalId }))

    profile?.id && dispatch(getDisciplineTrials(`${profile.id}`))
  }, [profile?.externalId, profile?.id])

  return (
    <>
      <div className="entr-exams__prompt">
        <p className="entr-exams__text entr-exams__prompt-text">
          Расписание вступительных испытаний будет установлено позже. Следите за
          обновлениями данного раздела.
        </p>
      </div>
      {disciplines.length > 0 && (
        <p className="entr-exams__text">
          В день экзамена Вам необходимо зайти в данный раздел и приступить к
          экзамену, нажав кнопку “Начать экзамен”
        </p>
      )}

      {statement?.scenarioId === scenarioKeys.asperatnuta_rf.tdId && (
        <>
          <p className="entr-exams__text">
            Для подготовки к вступительным экзаменам пользуйтесь программами{' '}
            <a
              className="link_to_information"
              href="https://www.herzen.spb.ru/about/struct-uni/contr/upravlenie-organizatsii-dissertatsionnykh-issledovaniy/otdel-aspirantury/abiturientam/raspisanie-vstupitelnykh-ispytaniy/"
              target="_blank"
            >
              вступительных испытаний
            </a>
          </p>

          {trajectory.id === TRAJECTORY_RUSSIA_ID && (
            <>
              <div className="entr-exams__prompt">
                <p className="entr-exams__text entr-exams__prompt-text">
                  Консультацию по философии для российских абитуриентов пройдет
                  13.09.2024 в 17:30 по адресу: ул. Малая Посадская, д. 26, аудитория
                  210.
                </p>
              </div>
              <div className="entr-exams__prompt">
                <p className="entr-exams__text entr-exams__prompt-text">
                  Консультация по английскому языку для российских абитуриентов
                  пройдет 18.09.2024 в 16:50 по адресу: набережная реки Мойки, д. 48,
                  учебный корпус 6, аудитория 42.
                </p>
              </div>
            </>
          )}
        </>
      )}

      <EntranceExamsTable {...scenarioKeys} />
    </>
  )
}

export default EntranceExamsForm
